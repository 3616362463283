.TileText {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #94a2b6;

  span {
    margin-bottom: 20px;
    display: block;
  }
}

.TileLink {
  list-style-type: "—";
  list-style-position: inside;
  overflow: hidden;

  a {
    position: relative;
    width: fit-content;
    text-decoration: none;
    white-space: nowrap;
    color: #2e2382;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #2e2382;
      transition: all 0.1s ease;
      opacity: 1;
    }

    &:hover {
      &::after {
        opacity: 0;
      }
    }
  }
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #94a2b6;
  text-decoration: none;
  display: block;
}

.TextAreaEdit {
  font-weight: 400;
  font-size: 16px;
  color: #94a2b6;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}
