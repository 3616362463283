.Wrap {
  display: flex;
  justify-content: center;

  height: 500px;
  width: 100%;
}

.Tooltip {
  background-color: #fff;
  box-shadow: 0px 10px 24px rgba(139, 134, 174, 0.3);
  padding: 4px 8px;
}

.TooltipDate {
  margin: 0;
  margin-bottom: 8px;
}

.TooltipItem {
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}
