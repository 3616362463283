.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 4px;
  top: 4px;
  width: 17px;
  height: 17px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(15px);
  background-color: #f40057;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #fb80ac;
}
