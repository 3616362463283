.Wrap {
}

.Btn {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #fff;
  // border-bottom: 1px solid #d2dcea;
  padding: 0 20px;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #e00c3c;
  }
}

.IconChevronDown {
  transform: rotate(-90deg);
  margin-left: auto;

  path {
    fill: #e00c3c;
  }
}
