.Item {
  &.Active {
    .RowBtn {
      svg {
        transform: rotate(180deg);

        path {
          fill: #e00c3c;
        }
      }
    }

    .TableRow {
      &::before {
        width: 8px;
        // background-color: tomato;
      }
    }
  }

  &:nth-child(even) {
    .TableRow {
      background-color: #fbfdff;
    }
  }
}

.TableHeader {
  display: flex;
  padding: 12px 10px;
  padding-right: 40px;
}

.TableRow {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #f2f5f9;
  cursor: pointer;
  padding: 16px 10px;
  padding-right: 40px;

  .TableCol {
    font-size: 12px;
    line-height: 14px;
    color: #505d6f;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    transition: all 0.3s ease;
  }

  &[data-tonality="positive"] {
    &::before {
      background-color: #119e00;
    }
  }

  &[data-tonality="negative"] {
    &::before {
      background-color: #e00c3c;
    }
  }

  &[data-tonality="neutral"] {
    &::before {
      background-color: #505d6f;
    }
  }

  &[data-tonality="pending"] {
    &::before {
      background-color: #ff6400;
    }
  }
}

.TableCol {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #94a2b6;

  &[data-type="url"] {
    width: 100%;
    max-width: 110px;
    color: #2e2382;
    margin-right: 10px;
  }

  &[data-type="type"] {
    min-width: 74px;
    flex: 1 1 auto;
    // max-width: 74px;
    margin-right: 10px;
    overflow: hidden;
  }

  &[data-type="quantity"] {
    min-width: 40px;
    max-width: 40px;
    margin-right: 10px;
  }

  &[data-type="rating"] {
    min-width: 28px;
    max-width: 28px;
  }
}

.RowUrl {
  color: #2e2382;
}

.RowType {
  margin: 0;
}

.RowQuantity {
}

.RowRating {
  &[data-color="positive"] {
    color: #119e00;
  }

  &[data-color="negative"] {
    color: #e00c3c;
  }

  &[data-color="neutral"] {
    color: #505d6f;
  }
}

.RowBtn {
  border: none;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  padding: 0;

  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: all 0.3s ease;
  }
}

.Average {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}

.AverageTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #505d6f;
  margin-right: 18px;
}

.AverageValue {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 6px;
  padding: 2px 8px;

  svg {
    margin-right: 8px;
  }

  &[data-position="up"] {
    svg {
      transform: rotate(180deg);

      path {
        fill: #119e00;
      }
    }
  }

  &[data-position="down"] {
  }
}
