.Wrap {
}

.List {
  margin: 0;
  list-style: none;
}

.Item {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #d2dcea;
  cursor: pointer;
  transition: all 0.2s ease;

  p {
    margin: 0;

    flex: 1 1 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #505d6f;
    padding-left: 20px;
    transition: all 0.2s ease;
  }

  span {
    height: 100%;
    width: 120px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    text-align: center;
    background-color: #becbdc;
    transition: all 0.2s ease;
  }

  &.Active {
    background-color: #e00c3c;

    p {
      color: #fff;
    }

    span {
      background-color: #c50631;
    }
  }

  &:hover {
    background-color: #e00c3c;

    p {
      color: #fff;
    }

    span {
      background-color: #c50631;
    }
  }
}

.Empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
