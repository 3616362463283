@import "../../../style/constants";
$path: "../../../images/icons/";

.button-escape {
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 60px;
  background: url($path + "escape-icon.svg") $blue no-repeat left 25px center;
  @include transition;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: $red;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .button-escape {
    background: url($path + "escape-icon-2.svg") #fff no-repeat left 17px center;
  }
}

@media only screen and (max-width: 767px) {
  .button-escape {
    background: url($path + "escape-icon-2.svg") #fff no-repeat left 17px center;
  }
}
