.DeleteReplic {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 72px);
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: #822381;
  z-index: 100;

  .Text {
    margin-left: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
  }

  .BtnBlockWrapper {
    display: flex;
    justify-content: flex-end;

    .BtnWrapper {
      width: 198px;
    }
  }
}

@media (min-width: 767px) and (max-width: 1599px) {
  .DeleteReplic {
    .Text {
      margin-left: 20px;
    }

    .BtnBlockWrapper {
      .BtnWrapper {
        width: 134px;
      }
    }
  }
}

@media (max-width: 767px) {
  .DeleteReplic {
    flex-direction: column;
    width: 100%;
    height: 94px;

    .Text {
      margin-left: 0;
      padding: 25px 28px;
    }

    .BtnBlockWrapper {
      .BtnWrapper {
        width: 100%;
      }
    }
  }
}
