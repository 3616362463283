@import "../../../style/constants.scss";

.Wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.Title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $gray;
  margin-right: 10px;
}

.SelectWrap {
  width: 100%;
  max-width: 78px;
  margin-left: auto;
}

.CustomOption {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  cursor: pointer;
  height: 60px;
  color: $darkGray;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  padding: 0 30px 0 30px;
  transition: all 0.2s ease;

  &.Active,
  &.Focus {
    background-color: #e00c3c;
    color: white;

    &::after {
      background-color: #e00c3c;
    }
  }

  &:hover {
    background-color: #e00c3c;
    color: white;

    &::after {
      background-color: #e00c3c;
    }
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 30px;
    right: 30px;
    height: 1px;
    width: auto;
    transition: all 0.2s ease;
    background-color: #f1f1f1;
  }
}

@media (max-width: 767px) {
  .SelectWrap {
    max-width: 102px;
  }
}
