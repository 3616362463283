.preloader {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
}

.preloader.enter-active {
  visibility: visible;
  opacity: 1;
}

.preloader.enter-done {
  visibility: visible;
  opacity: 1;
}

.loadingio-spinner-spinner-xs9p966h829 {
  width: 100px;
  height: 100px;
  display: block;
  overflow: hidden;
  background: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ldio-tp6fo6z44zh {
  background: #e00c3c;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
