.TitleBlock {
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  margin-top: 36px;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: #505d6f;
}

.DateBlock {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-bottom: 4px;
  }
}

.Date {
  display: flex;
  align-items: center;
}

.ButtonArrow {
  width: 25px;
  height: 11px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: url("../../../../images/icons/arrow-selcet-little.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

  &[data-hidden="true"] {
    &:before {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}

.Checkboks {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 108px;
}

.CheckboksField {
  height: 28px;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  padding-right: 20px;

  &:not(:last-child) {
    margin-right: 36px;
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid #becbdc;
  }

  input {
    display: none;
    appearance: none;

    &:checked {
      & + .CheckboksCheck {
        opacity: 1;
      }
    }
  }
}

.CheckboksIcon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.CheckboksCheck {
  position: absolute;
  right: 3px;
  top: 9px;
  opacity: 0;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .Title {
    font-size: 21px;
    line-height: 20px;
  }

  .TitleBlock {
    flex-wrap: wrap;
  }

  .Date {
    height: 60px;
  }

  .Checkboks {
    order: 3;
    width: 100%;
    border-top: 1px solid #d0ddef;
    padding-top: 26px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
}
