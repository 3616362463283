.listWrapper {
  margin-top: 24px;
}

.mediaFile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
  gap: 8px;
  max-width: 400px;

  @media only screen and (max-width: 767px) {
    max-width: 260px;
  }
}

.fileLink {
  color: blue;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
