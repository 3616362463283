.Tile {
  height: 210px;
  background: transparent;
  border: 1px solid #becbdc;
  transition: all 0.2s ease;

  .TileWrapper {
    padding: 30px 30px 40px 30px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .Icon {
    width: 33px;
    height: 33px;

    path {
      fill: #becbdc;
      transition: all 0.2s ease;
    }
  }

  .Text {
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    color: #94a2b6;
    transition: all 0.2s ease;
  }

  &:hover {
    background: #2e2382;

    .Icon {
      path {
        fill: #fff;
      }
    }

    .Text {
      color: #fff;
    }
  }
}

.Active {
  height: auto;
  padding: 0;
  background: #fff;
  border: none;

  &:hover {
    background: #fff;
  }

  .Header {
    padding: 0 30px;
    display: flex;
    align-items: center;
    height: 81px;
    width: 100%;
    background: #2e2382;

    .Icon {
      width: 33px;
      height: 33px;

      path {
        fill: #fff;
      }
    }

    .Text {
      color: #fff;
      margin-left: 48px;
      font-weight: 600;
      font-size: 21px;
      line-height: 26px;
    }
  }

  .Body {
    padding: 25px 17px;
    width: 100%;

    .FolderIcon {
      margin-bottom: 36px;
      width: 60px;
      height: 56px;
      background-image: url("../../../images/Desktop/SmallTileFolder.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .Footer {
    width: 100%;
    display: flex;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 50%;
      border: none;
      outline: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }

    .Create {
      background: #2e2382;
      color: #fff;
    }

    .Cancel {
      color: #2e2382;
      background: #e9eff5;
    }
  }
}
