.search-history {
  &-back-button {
    position: absolute;
    top: 0;
    right: 72px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding: 0 36px 0 18px;
    display: flex;
    align-items: center;
    background-color: #3e5069;
    border: none;
    outline: none;
    height: 60px;
    transition: all 0.2s ease;

    &:hover {
      background-color: #e00c3c;
    }
  }
}
