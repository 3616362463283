@import "../../../style/constants";

.SelectBlock {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.CompanySelect {
  width: 350px;
  margin-right: 57px;
}

.ProjectSelect {
  flex: 1;
}

@media (min-width: $tabletPortraitMin) and (max-width: $tabletLandscapeMax) {
  .CompanySelect {
    width: 325px;
    margin-right: 53px;
  }
}

@media only screen and (max-width: 767px) {
  .SelectBlock {
    flex-direction: column;
  }

  .CompanySelect,
  .ProjectSelect {
    width: 100%;
  }
}
