.Wrap {
  background-color: #fff;
  padding: 0 34px;
  padding-bottom: 30px;
  margin-top: 40px;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2e2382;
  margin-bottom: 28px;
}

.BtnToogle {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0;

  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2e2382;

  &[data-open="N"] {
    .IconWrap {
      transform: rotate(180deg);
    }
  }
}

.IconWrap {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #2e2382;
  margin-left: 10px;
  padding-bottom: 2px;
  transition: all 0.2s ease;
}

@media only screen and (max-width: 767px) {
  .Wrap {
    padding: 0 18px;
  }
}
