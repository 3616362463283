.FinishConfirmationAction {
  width: 100%;
  padding: 54px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .Text {
    display: flex;
    font-size: 36px;
    line-height: 20px;

    &[data-color="red"] {
      color: #e00c3c;
    }

    &[data-color="green"] {
      color: #119e00;
    }
  }
}

@media (max-width: 767px) {
  .FinishConfirmationAction {
    .Text {
      width: 250px;
      font-size: 24px;
      line-height: 30px;
    }
  }
}
