.AddRow {
  position: absolute;
  right: 59px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2e2382;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 2px;
    background-color: #fff;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
    width: 2px;
    background-color: #fff;
  }

  &:hover {
    background-color: #e00c3c;
  }

  &.Mini {
    right: 62px;
    width: 15px;
    height: 15px;

    &::before {
      width: 8px;
    }

    &::after {
      height: 8px;
    }
  }

  &.Disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
