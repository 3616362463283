@import "./src/style/constants.scss";

$path: "../../../../images/icons/";

.ReplicaList {
  width: 100%;
  padding-top: 34px;
  order: 3;

  .HeaderBar {
    display: flex;
    padding-left: 19px;
    margin-bottom: 46px;
    position: relative;

    .BarCell {
      display: flex;
      align-items: center;
      width: 19px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $gray;

      &.Status {
        display: none;
      }

      &:first-child {
        margin-right: 20px;
      }

      &[data-width="126"] {
        margin-right: 55px;
        width: 82px;
      }

      &[data-width="201"] {
        margin-right: 120px;
        width: 82px;
      }

      &[data-width="229"] {
        margin-left: 113px;
        width: 100px;
      }

      &[data-width="782"] {
        width: 650px;
      }

      .AddButton {
        width: 19px;
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e00c3c;
        position: relative;
        cursor: pointer;

        svg {
          opacity: 0;
          transition: all 0.2s ease;
        }

        &[data-active="Y"] {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }

  .SortBtnUp,
  .SortBtnDown {
    width: 17px;
    height: 17px;
    border: 1px solid $gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;

    path {
      transition: all 0.2s ease;
    }

    &[data-active="Y"] {
      border-color: #e00c3c;

      path {
        fill: #e00c3c;
      }
    }
  }

  .SortBtnUp {
    margin-left: 10px;
    margin-right: 5px;

    svg {
      transform: rotate(-180deg);
    }
  }
}

.NotificationStatus {
  position: relative;
  width: 100%;
  max-width: 292px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #667181;
  cursor: pointer;
  padding: 10px 32px;
  transition: all 0.2s ease;

  svg {
    path {
      transition: all 0.2s ease;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 16px;
    background-color: #e00c3c;
    transition: all 0.2s ease;
  }

  &:hover {
    background-color: #e00c3c;
    color: #fff;

    svg {
      path {
        fill: #fff;
      }
    }

    .NotificationStatusCount {
      &::after {
        background-color: #fff;
      }
    }
  }

  &.SubscribeNotification {
    max-width: 438px;
    padding-right: 18px;
    padding-left: 18px;
    padding-right: 18px;

    &::before {
      background-color: #0066ff;
      width: 5px;
    }

    .NotificationStatusCount {
      &::after {
        display: none;
      }
    }

    &:hover {
      background-color: #0066ff;
    }
  }
}

.NotificationStatusCount {
  position: relative;
  margin-right: 15px;

  &::after {
    content: "";
    position: absolute;
    right: -8px;
    top: -8px;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e00c3c;
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-left: 10px;
    transition: all 0.2s ease;
  }

  &[data-active="N"] {
    &::after {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .ReplicaList {
    .HeaderBar {
      .BarCell {
        width: 28px;
        height: 28px;

        &.Status {
          display: block;
        }

        .DateTitle {
          display: none;
        }

        &:first-child {
          margin-right: 64px;
        }

        &[data-width="126"] {
          // display: none;
        }

        &[data-width="201"] {
          display: none;
        }

        &[data-width="229"] {
          margin-left: 50px;
          width: 229px;
        }

        &[data-width="782"] {
          margin-left: 63px;
          width: 100%;
        }

        .AddButton {
          width: 30px;
          height: 30px;
        }
      }
    }

    .SortBtnUp,
    .SortBtnDown {
      width: 32px;
      height: 32px;
      flex-shrink: 0;

      svg {
        width: 12px;
        height: 18px;
      }
    }

    .SortBtnUp {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  .NotificationStatus {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .ReplicaList {
    padding-top: 24px;
    .HeaderBar {
      padding-left: 0px;
      padding-bottom: 23px;
      display: none;

      .BarCell {
        display: none;

        &:first-child {
          display: block;
        }

        .AddButton {
          width: 60px;
          height: 60px;
        }
      }
    }

    .SortBtnUp {
      margin-left: 0;
      margin-right: 12px;
    }
  }

  .NotificationStatus {
    display: none;
  }
}
