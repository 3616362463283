.ItemDelete {
  position: absolute;
  right: 90px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #becbdc;
  cursor: pointer;
  transition: all 0.2s ease;

  span {
    width: 10px;
    height: 2px;
    background-color: #becbdc;
    transition: all 0.2s ease;
  }

  &:hover {
    border-color: #e00c3c !important;

    span {
      background-color: #e00c3c !important;
    }
  }

  &.Mini {
    right: 93px;
    width: 15px;
    height: 15px;

    span {
      width: 8px;
      height: 2px;
    }
  }
}
