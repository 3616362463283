.admin-company__top-side {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  .admin-company__top-side-cell__allOpen {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2e2382;
    cursor: pointer;

    &:hover {
      span {
        background-color: #3b2da9;
      }
    }

    span {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #2e2382;
      padding-top: 2px;
      margin-left: 10px;
      transition: all 0.2s ease;
    }

    svg {
      transition: all 0.2s ease;
    }

    &.isOpen {
      span {
        transform: rotate(180deg);
      }
    }
  }
}
