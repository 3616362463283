.Circle {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .Circle {
    display: flex;
    justify-content: center;

    svg {
      max-width: 100%;
    }
  }
}
