.MenuDesktop {
}

.Overlay {
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: calc(100% + 133px);
  background-color: rgba(#000, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;

  &.Active {
    opacity: 1;
    pointer-events: visible;
  }
}

.FilterRowOne {
  display: flex;
  align-items: flex-end;
}

.CompanySelector {
  width: 100%;
  max-width: 280px;
  border-bottom: 1px solid #d2dcea;
  margin-right: 30px;
}

.ProjectSelector {
  flex: 1 1 auto;
  border-bottom: 1px solid #d2dcea;
}

.Unloading {
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: none;
  text-decoration: none;
  margin-left: 42px;
  opacity: 0.2;

  &.Active {
    opacity: 1;
    pointer-events: visible;
  }

  svg {
    margin-right: 16px;

    path {
      fill: #505d6f;
    }
  }

  span {
    position: relative;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #505d6f;

    &::after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #505d6f;
    }
  }
}

.FilterRowTwo {
  display: flex;
  align-items: center;
  margin-top: 28px;
  opacity: 0.2;
  pointer-events: none;

  &.Active {
    opacity: 1;
    pointer-events: visible;
  }
}

.DeepLevelSelector {
  width: 136px;
  height: 60px;
}

.TopSelector {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;

  .Text {
    margin-right: 12px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: #2e2382;

    &[data-select-option="top-text"] {
      display: none;
    }
  }

  .Num {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: #505d6f;
    padding-right: 3px;
  }

  &:hover {
    .Num {
      color: #ffffff;
    }
  }
}

.SelectorOption {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.SelectorOptionIcon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.AllSystemsSelector {
  height: 60px;
  width: 100%;
  max-width: 276px;
  border-left: 1px solid #f2f5f9;
}

.SelectPlaceholder {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #505d6f;
  padding-left: 20px;

  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
}

.AllSearchInquiryWrapper {
  position: relative;
  height: 60px;
  display: flex;
  flex: 1 1 auto;
  border-left: 1px solid #f2f5f9;
  // padding-right: 110px;
}

.AllSearchSelectPlaceholder {
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
}

.SelectStaleBlock {
  // display: flex;
  // width: 100%;
}

.SelectStaleBlock {
  display: flex;
  width: 100%;

  &[data-client="true"] {
    width: 100%;

    .Frequency {
      min-width: 147px;
    }

    .SelectorOptionSearchSelect {
      .Frequency {
        min-width: 147px;
      }
    }
  }

  &[data-client-tablete="true"] {
    justify-content: space-between;
    display: flex;
    width: 100%;

    // .Frequency {
    //     min-width: 127px;
    //     width: 127px;
    // }

    .SelectorOptionSearchSelect {
      // .Frequency {
      //     min-width: 127px;
      //     width: 127px;
      // }
    }
  }

  .SelectorOptionSearchSelect {
    width: 100%;
    height: 60px;
    // font-size: 18px;
    // line-height: 21px;
    // color: #505d6f;
    // font-weight: 500;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;

    .Frequency {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 110px;
      max-width: 110px;
      height: 60px;
      background: #becbdc;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
    }

    &:hover {
      color: #ffffff;

      .Frequency {
        background: #c50631;
      }
    }
  }

  .SelectorOptionSearch {
    width: 100%;
    height: 60px;
    // font-size: 18px;
    // line-height: 21px;
    // color: #505d6f;
    // font-weight: 500;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 66px;

    &:before {
      content: "";
      width: 26px;
      height: 26px;
      position: absolute;
      left: 20px;
      background: url("../../../../images/Analise/search-icon-blue.svg") no-repeat center center /
        contain;
    }
  }

  .AllSearchInquiry {
    padding-left: 66px;
    height: 60px;
    width: 382px;
  }

  .Frequency {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 149px;
    height: 60px;
    background: #becbdc;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}

.SelectorOptionSearchSelect {
  position: relative;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  height: 60px;
  padding-left: 20px;
  // font-size: 18px;
  // line-height: 21px;
  // font-weight: 500;
  // color: #505d6f;

  &:hover {
    color: #ffffff;
  }
}

.Frequency {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  min-width: 110px;
  max-width: 110px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  background: #becbdc;
  color: #fff;
}

.AllSearchInquiry {
  padding-left: 66px;
  height: 60px;
  width: 382px;
}

.ButtonShow {
  width: 60px;
  height: 60px;
  background: #2e2382;
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    width: 24px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: url("../../../../images/Analise/eye-show-icon-blue.svg") no-repeat center center /
      contain;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: #e00c3c;

    &:before {
      width: 24px;
      height: 20px;
      background: url("../../../../images/Analise/eye-show-icon-white.svg") no-repeat center
        center / contain;
    }
  }

  &[data-active="Y"] {
    background: #e00c3c;

    &:before {
      width: 24px;
      height: 20px;
      background: url("../../../../images/Analise/eye-show-icon-white.svg") no-repeat center
        center / contain;
    }
  }
}

.AddBtn {
  position: relative;
  // min-width: 242px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  background: #e00c3c;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    margin-right: 18px;
  }

  &:hover {
    background: #ff4763;
  }
}

.RegionBtn {
  width: 100%;
  max-width: 160px;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-left: 1px solid #f2f5f9;
  cursor: pointer;
  padding: 0 18px;
}

.IconGeo {
  flex-shrink: 0;
}

.RegionValue {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #505d6f;
  white-space: nowrap;
  margin: 0 10px;
  overflow: hidden;
}

.RegionDots {
  display: flex;
  align-items: center;

  span {
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background-color: #e00c3c;

    &:not(:last-child) {
      margin-right: 3px;
    }
  }
}

.RegionMenu {
  position: fixed;
  z-index: 200;
  top: 0;
  bottom: 0;
  right: -400px;
  height: 100%;
  width: 400px;
  background-color: #fff;
}

.RegionMenuHeader {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #2e2382;
  padding-left: 24px;
}

.RegionMenuIconGeo {
  margin-right: 24px;

  path {
    fill: #fff;
  }
}

.RegionMenuTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
}

.RegionMenuClose {
  border: none;
  outline: none;
  padding: 0;
  box-shadow: none;
  background-color: transparent;

  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.RegionMenuSearch {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #505d6f;

  input {
    border: none;

    width: 100%;
    height: 60px;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #2e2382;
    padding-left: 56px;

    &::placeholder {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #2e2382;
    }
  }

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.RegionMenuList {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-y: scroll;

  li {
    position: relative;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #505d6f;
    border-bottom: 1px solid #d2dcea;
    cursor: pointer;
    padding: 0 20px;
    transition: all 0.3s ease;

    svg {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.Active {
      background-color: #e00c3c;
      color: #fff;
    }

    &:hover {
      background-color: #e00c3c;
      color: #fff;
    }
  }
}

.NoOptionsMessage {
  height: 60px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #505d6f;
  padding-left: 10px;
}

// @media only screen and (min-width: 1600px) {
//     .AddBtn {
//         br {
//             display: none;
//         }
//     }
// }

// @media only screen and (min-width: 1024px) and (max-width: 1280px) {
// }

// @media only screen and (min-width: 768px) and (max-width: 1023px) {
// }

// @media only screen and (max-width: 767px) {
// }

@media only screen and (min-width: 1600px) {
  .AddBtn {
    br {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .FilterRowTwo {
    position: relative;
    width: 100%;
  }

  .Unloading {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #94a2b6;

    span {
      display: none;
    }

    svg {
      margin-right: 0;

      path {
        fill: #fff;
      }
    }
  }

  .CompanySelector {
    margin-right: 42px;
  }

  .ProjectSelector {
    margin-right: 38px;
  }

  .FilterRowTwo {
    flex-wrap: wrap;
    padding: 0;
    margin-top: 26px;
  }

  .TopSelector {
    // min-width: 136px;
    width: 25%;
    max-width: 100%;
  }

  .AllSystemsSelector {
    // min-width: 260px;
    width: 50%;
    max-width: 100%;
  }

  .RegionBtn {
    // min-width: 154px;
    width: 25%;
    max-width: 100%;
  }

  .AllSearchInquiryWrapper {
    min-width: auto;
    max-width: 100%;
  }

  .AddBtn {
    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
}
