.search-history {
  &-reset-button {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    align-self: center;
    background-color: #e00c3c;
    border: none;
    outline: none;
    transition: all 0.2s ease;

    &:hover {
      background-color: #3e5069;
    }
  }
}
