@import "../../../style/constants.scss";

.Wrapper {
  display: flex;
  align-items: center;
}

.GoBack,
.GoNext,
.FirstPage,
.LastPage {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;

  cursor: pointer;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $darkGray;
  border-radius: 50%;
  transition: all 0.2s ease;

  path {
    transition: all 0.2s ease;
  }

  &:hover {
    border-color: #e00c3c;

    path {
      fill: #e00c3c;
    }
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.FirstPage {
  margin-right: 5px;
}

.LastPage {
  margin-left: 5px;
}

.NumbersPage {
  display: flex;
  align-items: center;
  margin-top: 4px;

  a {
    text-decoration: none;
    margin: 0 5px;
    padding: 0;

    &:first-child {
      margin-left: 14px;
    }

    &:last-child {
      margin-right: 14px;
    }
  }

  button {
    padding: 0;
    margin: 0;
    position: relative;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
    color: $darkGray;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    transition: all 0.2s ease;

    &.Active {
      color: #e00c3c;
    }

    &:hover {
      color: #e00c3c;
    }
  }
}

.Separator {
  color: $darkGray;
}

@media only screen and (min-width: $desktopWide) {
}

@media only screen and (min-width: $tabletLandscapeMin) and (max-width: $tabletLandscapeMax) {
}

@media only screen and (min-width: $tabletPortraitMin) and (max-width: $tabletPortraitMax) {
  .GoBack,
  .GoNext,
  .FirstPage,
  .LastPage {
    width: 29px;
    height: 29px;
  }

  .NumbersPage {
    a {
      margin: 0 8px;

      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 20px;
      }
    }
  }

  .FirstPage {
    margin-right: 6px;
  }

  .LastPage {
    margin-left: 6px;
  }
}

@media only screen and (max-width: $mobile) {
  .GoBack,
  .GoNext,
  .FirstPage,
  .LastPage {
    width: 29px;
    height: 29px;
  }

  .NumbersPage {
    a {
      margin: 0 8px;

      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 20px;
      }
    }
  }

  .FirstPage {
    margin-right: 6px;
  }

  .LastPage {
    margin-left: 6px;
  }
}
