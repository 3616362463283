@import "../../../style/constants";

.DefaultTiles {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 15px));
  grid-column-gap: 30px;
  padding-bottom: 40px;
  border-bottom: 1px solid hsl(0, 0%, 80%);
  margin-bottom: 40px;
}

@media only screen and (min-width: $tabletPortraitMin) and (max-width: $tabletLandscapeMax) {
  .DefaultTiles {
    grid-template-columns: repeat(2, calc(50% - 10px));
    gap: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .DefaultTiles {
    grid-template-columns: 100%;
    gap: 20px;
  }
}
