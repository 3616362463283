.admin-company__user-manipulation-content_add-user {
  flex-direction: row !important;
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

@media only screen and (max-width: 767px) {
  .admin-company__user-manipulation-content_add-user {
    flex-direction: column !important;
  }

  .admin-company__user-manipulation-content_add-user .admin-company__user-manipulation-buttons {
    padding-left: 18px;
    padding-right: 18px;
  }
}
