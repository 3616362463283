@import "./constants.scss";
$path: "../images/icons/";

/*===============================================================
  Компонент "Админка компании" Основные стили
  ===============================================================*/

.admin-company {
  padding-top: 60px;
  position: relative;
}
.admin-company__wrap {
  max-width: 1480px;
  padding: 0 20px 0 92px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.admin-company__h1-mobile {
  display: none;
  position: absolute;
  left: 10px;
  top: 32px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: $blue;
}

.admin-company__top-side {
  width: 100%;
  display: flex;
  padding-left: 35px;
}
.admin-company__top-side-cell {
  font-size: 12px;
  line-height: 14px;
  color: #5e5e5e;
  padding: 11px 0 5px;
}

.admin-company__container {
  width: 100%;
}
.admin-company__company-item {
  margin-top: 4px;
  position: relative;
  width: 100%;
  @include transition;
  &:hover {
    box-shadow: 0px 10px 24px #f2f0ff;
  }
  &:first-child {
    margin-top: 0;
  }
}

.admin-company__company-item-bar {
  height: 60px;
  background: #fff;
  border-top: 1px solid #f1f1f1;
  padding-left: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    opacity: 0;
    position: absolute;
    display: block;
    left: 0;
    top: 0px;
    bottom: 0px;
    width: 7px;
    background: $green;
    z-index: 1;
    @include transition;
  }
  &:hover:before {
    opacity: 1;
  }
}

.admin-company__company-item-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: $darkGray;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.admin-company__company-item-edit {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 110px;
  top: 50%;
  margin-top: -12px;
  background: no-repeat center;
  cursor: pointer;
}
.admin-company__company-item-switcher {
  position: absolute;
  width: 48px;
  height: 48px;
  right: 12px;
  top: 50%;
  margin-top: -24px;
  background: no-repeat center / contain;
  cursor: pointer;
}
.admin-company__company-item-content {
  background: #fff;
  padding: 0 0 38px 0px;
}

.admin-company__projects {
  background: $lightGray;
  position: relative;
}
.admin-company__projects-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: $gray;
  background: $lightGray;
  padding: 0 0 0 38px;
  height: 60px;
  display: flex;
  align-items: center;
}
.admin-company__projects-button-add {
  position: absolute;
  width: 72px;
  height: 60px;
  top: 0;
  right: 0;
  background: url($path + "portfolio-icon.svg") $blue no-repeat center;
  cursor: pointer;
  @include transition;
  &:hover {
    background-color: $blueHover;
  }
}

.admin-company__projects-top-side {
  width: 100%;
  display: flex;
  padding-left: 38px;
}
.admin-company__projects-top-side-cell {
  font-size: 12px;
  line-height: 14px;
  color: $gray;
  padding: 5px 0 6px;
  width: 1193px;
  &:last-child {
    width: auto;
    position: absolute;
    right: 135px;
  }
}

.admin-company__project-item {
  position: relative;
  width: 100%;
  @include transition;
  border-top: 1px solid #e9eff5;
  &:hover {
    box-shadow: 0px 10px 24px #f2f0ff;
  }
}

.admin-company__project-item-bar {
  min-height: 60px;
  background: $lightGray;
  padding: 10px 0 10px 38px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  @include transition;
  &:hover {
    background: #fff;
  }
  &:hover .admin-company__project-item-edit {
    &:after {
      opacity: 1;
    }
  }
  &:before {
    content: "";
    opacity: 1;
    position: absolute;
    display: block;
    right: 0;
    top: 0px;
    bottom: 0px;
    width: 6px;
    background: $green;
    z-index: 1;
    @include transition;
  }
}

.admin-company__project-item-name {
  font-weight: 500;
  padding-right: 300px;
  font-size: 18px;
  line-height: 20px;
  color: $blue;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.admin-company__project-item-edit {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 230px;
  top: 50%;
  margin-top: -12px;
  background: no-repeat center;
  cursor: pointer;
  &:after {
    content: "";
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url($path + "edit-icon-2-blue.svg") no-repeat center;
    @include transition;
  }
}

.admin-company__project-item-switcher {
  position: absolute;
  width: 38px;
  height: 38px;
  right: 20px;
  top: 50%;
  margin-top: -19px;
  background: no-repeat center / contain;
  cursor: pointer;
}
.admin-company__project-item-status {
  position: absolute;
  width: 75px;
  top: 50%;
  margin-top: -10px;
  right: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.admin-company__project-item-content {
  padding: 0 0 32px;
}

.admin-company__project-manipulation-button {
  button {
    display: block;
    width: 142px;
    height: 60px;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    line-height: 58px;
    text-align: center;
    color: #ffffff;
    transition: all 0.2s ease-out;
    background: $blue;
    border: none;
    &:hover {
      background: $blueHover;
    }
  }
}

.admin-company__users {
  background: #e9eff5;
  position: relative;
}
.admin-company__users-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: $gray;
  background: #e9eff5;
  padding: 0 0 0 30px;
  height: 60px;
  display: flex;
  align-items: center;
}
.admin-company__users-button-add {
  position: absolute;
  width: 72px;
  height: 60px;
  top: 0;
  right: 0;
  background: url($path + "user-add-icon.svg") $blue no-repeat center;
  cursor: pointer;
  @include transition;
  &:hover {
    background-color: $blueHover;
  }
}

.admin-company__users-top-side {
  width: 100%;
  display: flex;
}
.admin-company__users-top-side-cell {
  font-size: 12px;
  line-height: 14px;
  color: $gray;
  padding: 5px 0 6px;
  width: auto;
}

.admin-company__users-top-side .admin-company__users-top-side-cell:last-child {
  position: absolute;
  right: 135px;
}

.admin-company__user-item {
  position: relative;
  width: 100%;
  @include transition;
  border-top: 1px solid #becbdc;
  &:last-child {
    border-bottom: 1px solid #becbdc;
  }
  &:hover {
    box-shadow: 0px 10px 24px #f2f0ff;
  }
}

.admin-company__user-item-bar {
  height: 60px;
  background: #e9eff5;
  padding-left: 66px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  @include transition;
  &:hover {
    background: #fff;
  }
  &:hover .admin-company__user-item-link {
    &:after {
      opacity: 1;
    }
  }
  &:hover .admin-company__user-item-remove {
    &:after {
      opacity: 1;
    }
  }
}

.admin-company__user-item-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: $blue;
  display: flex;
  align-items: center;
  width: 244px;
}
.admin-company__user-item-position {
  font-size: 14px;
  line-height: 17px;
  color: #110c32;
  font-weight: 300;
  padding-left: 10px;
}
.admin-company__user-item-link {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 230px;
  top: 50%;
  margin-top: -12px;
  background: no-repeat center;
  cursor: pointer;
  &:after {
    content: "";
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url($path + "link-icon-2-active.svg") no-repeat center;
    @include transition;
  }
}

.admin-company__user-item-switcher {
  position: absolute;
  width: 38px;
  height: 38px;
  right: 20px;
  top: 50%;
  margin-top: -19px;
  background: no-repeat center / contain;
  cursor: pointer;
}
.admin-company__user-item-remove {
  position: absolute;
  width: 38px;
  height: 38px;
  right: 18px;
  top: 50%;
  margin-top: -19px;
  background: no-repeat center;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -7px;
    width: 14px;
    height: 20px;
    display: block;
    background: url($path + "trash-icon-gray.svg") no-repeat center / contain;
  }
  &:after {
    opacity: 0;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -7px;
    width: 14px;
    height: 20px;
    display: block;
    background: url($path + "trash-icon-red.svg") no-repeat center / contain;
    @include transition;
  }
}
.admin-company__user-item-status {
  position: absolute;
  width: 75px;
  top: 50%;
  margin-top: -10px;
  right: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.admin-company__company-manipulation {
  box-shadow: 0px 10px 24px #f2f0ff;
  margin-bottom: 4px;
}
.admin-company__company-manipulation-top-side {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 65px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    display: block;
    left: 18px;
    top: 50%;
    margin-top: -16.5px;
    width: 33px;
    height: 33px;
    background: no-repeat center / contain;
  }
}

.admin-company__company-manipulation-top-side-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}
.admin-company__company-manipulation-top-side-button {
  position: absolute;
  right: 0px;
  top: 0px;
  background: url($path + "close-icon.svg") $darkGray no-repeat center;
  width: 72px;
  height: 60px;
  cursor: pointer;
  @include transition;
  &:hover {
    background-color: $darkGrayHover;
  }
}
.admin-company__company-manipulation-content {
  height: 120px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  form {
    padding-left: 19px;
    display: flex;
    width: 100%;
  }
}

.admin-company__company-manipulation-reason-content {
  background: #fff;
  padding: 44px 31px 35px 34px;
}
.admin-company__company-manipulation-reason-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  span {
    font-size: 18px;
    line-height: 20px;
    color: $red;
  }
}
.admin-company__company-manipulation-reason-field {
  display: flex;
  margin-top: 28px;
}
.admin-company__company-manipulation-reason-input {
  width: 100%;
  padding-left: 22px;
  margin-right: 24px;
  background: #ffffff;
  border: 2px solid #ffb4c5;
  box-shadow: inset 0px 1px 11px #f2f0ff;
  border-radius: 0px 10px 10px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $darkGray;
  &:focus {
    border-color: #a295ff;
  }
}
.admin-company__company-manipulation-reason-button {
  width: 302px;
  height: 60px;
  flex-shrink: 0;
  border: none;
  background: $red;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  @include transition;
  &:hover {
    background: $redHover;
  }
}

.admin-company__company-manipulation-input-container {
  width: 100%;
  position: relative;
  padding-left: 19px;
  label {
    font-size: 12px;
    line-height: 14px;
    color: #5e5e5e;
    position: absolute;
    left: 35px;
    top: -19px;
  }
  input {
    background: #fff;
    border: 1px solid #e0dcff;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 58px;
    color: $darkGray;
    height: 60px;
    padding-left: 20px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 0px 0px 10px;
    &:focus {
      border-color: #a295ff;
    }
  }
}

.admin-company__company-manipulation-buttons {
  display: flex;
}
.admin-company__company-manipulation-button {
}
.admin-company__company-manipulation-button button {
  display: block;
  width: 142px;
  height: 60px;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
  @include transition;
  background: $blue;
  border: none;
}
.admin-company__company-manipulation-button button:hover {
  background: $blueHover;
}

.admin-company__company-manipulation-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: $red;
  padding-left: 34px;
  padding-right: 10px;
}

.admin-company__user-manipulation-reason-content {
  background: #fff;
  padding: 44px 31px 35px 68px;
}
.admin-company__user-manipulation-reason-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  span {
    font-size: 18px;
    line-height: 20px;
    color: $red;
  }
}
.admin-company__user-manipulation-reason-field {
  display: flex;
  margin-top: 28px;
}
.admin-company__user-manipulation-reason-input {
  width: 100%;
  padding-left: 22px;
  margin-right: 24px;
  background: #ffffff;
  border: 2px solid #ffb4c5;
  box-shadow: inset 0px 1px 11px #f2f0ff;
  border-radius: 0px 10px 10px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $darkGray;
  &:focus {
    border-color: #a295ff;
  }
}
.admin-company__user-manipulation-reason-button {
  width: 302px;
  height: 60px;
  flex-shrink: 0;
  border: none;
  background: $red;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  @include transition;
  &:hover {
    background: $redHover;
  }
}

.admin-company__project-manipulation {
  background: #ffffff;
  box-shadow: 0px 10px 24px #f2f0ff;
  margin-bottom: 4px;
  position: absolute;
  padding-left: 34px;
  left: -34px;
  right: 0px;
  top: 60px;
  z-index: 2;
}

.admin-company__project-manipulation-reason-content {
  background: #fff;
  padding: 44px 31px 35px 34px;
}
.admin-company__project-manipulation-reason-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  span {
    font-size: 18px;
    line-height: 20px;
    color: $red;
  }
}
.admin-company__project-manipulation-reason-field {
  display: flex;
  margin-top: 28px;
}
.admin-company__project-manipulation-reason-input {
  width: 100%;
  padding-left: 22px;
  margin-right: 24px;
  background: #ffffff;
  border: 2px solid #ffb4c5;
  box-shadow: inset 0px 1px 11px #f2f0ff;
  border-radius: 0px 10px 10px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $darkGray;
  &:focus {
    border-color: #a295ff;
  }
}
.admin-company__project-manipulation-reason-button {
  width: 302px;
  height: 60px;
  flex-shrink: 0;
  border: none;
  background: $red;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  @include transition;
  &:hover {
    background: $redHover;
  }
}

.admin-company__project-manipulation-top-side {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 65px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    display: block;
    left: 16px;
    top: 50%;
    margin-top: -18.5px;
    width: 33px;
    height: 33px;
    background: no-repeat center / contain;
  }
}
.admin-company__project-manipulation-top-side-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}
.admin-company__project-manipulation-top-side-button {
  position: absolute;
  right: 0px;
  top: -60px;
  background: url($path + "close-icon.svg") $gray no-repeat center;
  width: 72px;
  height: 60px;
  cursor: pointer;
  @include transition;
  &:hover {
    background-color: $grayHover;
  }
}
.admin-company__project-manipulation-content {
  height: 120px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  form {
    display: flex;
    width: 100%;
  }
}

.admin-company__project-manipulation-input-container {
  width: 100%;
  position: relative;
  label {
    font-size: 12px;
    line-height: 14px;
    color: #5e5e5e;
    position: absolute;
    left: 37px;
    top: -19px;
  }
  input {
    background: #fff;
    border: 1px solid #e0dcff;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 58px;
    color: $blue;
    height: 60px;
    padding: 0 20px 0 37px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 0px 0px 10px;
    &:focus {
      border-color: #a295ff;
    }
  }
}

.admin-company__project-manipulation-buttons {
  display: flex;
}
.admin-company__project-manipulation-button {
}
.admin-company__project-manipulation-button button {
  display: block;
  width: 142px;
  height: 60px;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
  @include transition;
}
.admin-company__project-manipulation-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: $red;
  padding-left: 34px;
}

.admin-company__user-manipulation {
  box-shadow: 0px 10px 24px #f2f0ff;
  margin-bottom: 4px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 60px;
  z-index: 2;
}
.admin-company__user-manipulation-top-side {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 85px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    display: block;
    left: 29px;
    top: 50%;
    margin-top: -16px;
    width: 39px;
    height: 32px;
    background: no-repeat center / contain;
  }
}

.admin-company__user-manipulation-top-side-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}
.admin-company__user-manipulation-top-side-button {
  position: absolute;
  right: 0px;
  top: -60px;
  background: url($path + "close-icon.svg") $gray no-repeat center;
  width: 72px;
  height: 60px;
  cursor: pointer;
  @include transition;
  &:hover {
    background-color: $grayHover;
  }
}
.admin-company__user-manipulation-content {
  height: 120px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  display: flex;
  width: 100%;
  padding-left: 29px;
}

.admin-company__user-manipulation-input-container {
  width: 100%;
  position: relative;
  label {
    font-size: 12px;
    line-height: 14px;
    color: #5e5e5e;
    position: absolute;
    left: 37px;
    top: -19px;
  }
  input {
    background: #fff;
    border: 1px solid #e0dcff;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 58px;
    color: $blue;
    height: 60px;
    padding-left: 37px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 0px 0px 10px;
  }
}

.admin-company__user-manipulation-buttons {
  display: flex;
}
.admin-company__user-manipulation-button {
}
.admin-company__user-manipulation-button button {
  display: block;
  width: 142px;
  height: 60px;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
  @include transition;
}
.admin-company__user-manipulation-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: $red;
  padding-left: 39px;
  padding-right: 10px;
  span {
    color: $blue;
  }
}

.admin-company__user-manipulation-selects-container {
  display: flex;
  width: 100%;
  padding-left: 29px;
}
.admin-company__user-manipulation-selects-unit {
  width: 50%;
  position: relative;
  label {
    position: absolute;
    left: 0px;
    top: -19px;
    font-size: 12px;
    line-height: 14px;
    color: $gray;
  }
  select {
    height: 60px;
    width: 100%;
    padding-left: 24px;
    border: 1px solid #e0dcff;
    border-right: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2e2382;
    @include appearance;
    background: url("../images/icons/arrow-selcet-micro.svg") #fff no-repeat right 41px center;
  }
}

.admin-company__user-manipulation-input-unit {
  width: 50%;
  position: relative;
  margin-right: -1px;
  label {
    position: absolute;
    left: 38px;
    top: -19px;
    font-size: 12px;
    line-height: 14px;
    color: $gray;
  }
  input {
    height: 60px;
    width: 100%;
    padding: 0 30px 0 38px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border: 1px solid #e0dcff;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: $blue;
    @include appearance;
    background: #fff;
    position: relative;
    &:focus {
      border-color: #a295ff;
      z-index: 1;
    }
  }
  &:last-child {
    width: 337px;
    flex-shrink: 0;
    position: relative;
    input {
      padding-right: 60px;
    }
    /*&:after {content:''; position:absolute; display:block; width:31px; height:20px; right:18px; top:50%; margin-top:-10px; background:url($path + "eye-password-icon.svg") no-repeat center / contain; cursor:pointer;}*/
  }
  &:first-child {
    input {
      border-radius: 0 0 0 10px;
    }
  }
}

.select-pages {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #fff;
  position: relative;
  margin-bottom: 25px;
  &:before {
    content: "";
    display: block;
    height: 1px;
    background: #f2f5f9;
    position: absolute;
    top: -1px;
    left: 0px;
    right: 524px;
  }
}
.select-pages__links-block {
  display: flex;
  height: 60px;
}
.select-pages__links-block-button {
  border-right: 1px solid #f2f5f9;
  a {
    text-decoration: none;
    display: block;
    width: 175px;
    height: 60px;
    line-height: 58px;
    text-align: center;
    background: #fff;
    font-weight: 500;
    font-size: 18px;
    color: $blue;
    @include transition;
    &:hover {
      background: $blueHover;
      color: #fff;
    }
  }
  &.active {
    a {
      background: #f2f5f9;
      color: $red;
      &:hover {
        background: $redHover;
        color: #fff;
      }
    }
  }
}
.select-pages__select-block {
  display: flex;
}
.select-pages__select-unit {
  padding: 0px 33px 0;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    display: block;
    left: 33px;
    right: 33px;
    bottom: 0;
    height: 1px;
    background: $red;
  }
  select {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $gray;
    border: none;
    width: 100%;
    @include appearance;
  }
}

/*===============================================================
  Компонент "Админка компании" Модификаторы
  ===============================================================*/

.admin-company__h1-mobile_users-mode {
  top: 0px;
  width: auto;
  background: #fff;
  height: 70px;
  right: 0px;
  left: 0px;
  padding-left: 10px;
  padding-top: 21px;
  // &:after {content:''; background:#fff; position:absolute; right:0px; left:0px; top:-20px; bottom:-20px; z-index:-1;}
}

.admin-company__company-item_not-active {
  .admin-company__company-item-bar {
    cursor: default;
  }
  .admin-company__company-item-name {
    color: #e9eff5;
  }
  .admin-company__company-item-edit {
    background-image: url($path + "edit-icon-2-no-active.svg");
    cursor: default;
  }
  .admin-company__company-item-switcher {
    background-image: url($path + "switcher-off.svg");
  }
}

.admin-company__company-item_active {
  .admin-company__company-item-switcher {
    background-image: url($path + "switcher-on.svg");
  }
  .admin-company__company-item-edit {
    background-image: url($path + "edit-icon-2.svg");
  }
}

.admin-company__project-item_not-active {
  .admin-company__project-item-bar {
    cursor: default;
    &:before {
      background: $gray;
    }
  }
  .admin-company__project-item-name {
    color: $gray;
  }
  .admin-company__project-item-status {
    color: $gray;
  }
  .admin-company__project-item-edit {
    background-image: url($path + "edit-icon-2-no-active.svg");
    cursor: default;
  }
  .admin-company__project-item-switcher {
    background-image: url($path + "switcher-off-gray.svg");
  }
}

.admin-company__project-item_active {
  .admin-company__project-item-switcher {
    background-image: url($path + "switcher-on.svg");
  }
  .admin-company__project-item-edit {
    background-image: url($path + "edit-icon-2.svg");
  }
  .admin-company__project-item-status {
    color: $green;
  }
}

.admin-company__user-item_not-active {
  .admin-company__user-item-bar {
    cursor: default;
    &:before {
      background: $gray;
    }
  }
  .admin-company__user-item-name {
    color: $gray;
  }
  .admin-company__user-item-status {
    color: $gray;
  }
  .admin-company__user-item-link {
    background-image: url($path + "link-icon-2-not-active.svg");
    cursor: pointer;
  }
  .admin-company__user-item-switcher {
    background-image: url($path + "switcher-off-gray.svg");
  }
}

.admin-company__user-item_active {
  .admin-company__user-item-switcher {
    background-image: url($path + "switcher-on.svg");
  }
  .admin-company__user-item-link {
    background-image: url($path + "link-icon-2-not-active.svg");
  }
  .admin-company__user-item-status {
    color: $green;
  }
}

.admin-company__user-item_user-unlinked {
  .admin-company__user-item-link {
    background-image: url($path + "link-icon-2-active-red.svg");
  }
}

.admin-company__users-top-side-cell_280px {
  width: 285px;
  padding-left: 67px;
}
.admin-company__users-top-side-cell_311px {
  width: 26%;
  padding-left: 66px;
}
.admin-company__users-top-side-cell_886px {
  width: 62%;
  padding-left: 10px;
}
.admin-company__users-top-side-cell_925px {
  /*width:925px;*/
  position: absolute;
  left: 320px;
}

// .admin-company__company-item-switcher_on {background-image:url($path + "switcher-on.svg");}
// .admin-company__company-item-switcher_off {background-image:url($path + "switcher-off.svg");}

// .admin-company__company-item-edit_active {background-image:url($path + "edit-icon-2.svg");}
// .admin-company__company-item-edit_not-active {background-image:url($path + "edit-icon-2-no-active.svg");}

.admin-company__company-manipulation_creating {
  .admin-company__company-manipulation-top-side {
    background: $gray;
    &:before {
      background-image: url($path + "plus-icon-white.svg");
    }
  }
}

.admin-company__company-manipulation_editing {
  .admin-company__company-manipulation-top-side {
    background: $gray;
    &:before {
      background-image: url($path + "edit-icon-3.svg");
    }
  }
}

.admin-company__company-manipulation_removing {
  .admin-company__company-manipulation-top-side {
    background: $red;
    &:before {
      background-image: url($path + "plus-icon-white.svg");
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  .admin-company__company-manipulation-top-side-button {
    &:hover {
      background-color: $blue;
    }
  }
}

.admin-company__company-manipulation-button_red {
  button {
    background: $red;
    &:hover {
      background: $redHover;
    }
  }
}

.admin-company__company-manipulation-button_blue {
  button {
    background: $blue;
    &:hover {
      background: $blueHover;
    }
  }
}

.admin-company__project-manipulation_creating {
  .admin-company__project-manipulation-top-side {
    background: $gray;
    &:before {
      background-image: url($path + "portfolio-icon.svg");
    }
  }
}

.admin-company__project-manipulation_editing {
  .admin-company__project-manipulation-top-side {
    background: $gray;
    &:before {
      background-image: url($path + "portfolio-edit-icon.svg");
      width: 38px;
      height: 37px;
    }
  }
}

.admin-company__project-manipulation_removing {
  top: 0px;
  .admin-company__project-manipulation-top-side-button {
    top: 0px;
  }
  .admin-company__project-manipulation-top-side {
    background: $red;
    &:before {
      background-image: url($path + "portfolio-remove-icon.svg");
      width: 41px;
      height: 38px;
    }
  }
  .admin-company__project-manipulation-top-side-button {
    &:hover {
      background-color: $blue;
    }
  }
}

.admin-company__project-manipulation-button_red {
  button {
    display: block;
    width: 142px;
    height: 60px;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    line-height: 58px;
    text-align: center;
    color: #ffffff;
    transition: all 0.2s ease-out;
    background: $red;
    border: none;
    &:hover {
      background: $redHover;
    }
  }
}

.admin-company__user-manipulation_attaching {
  .admin-company__user-manipulation-top-side {
    background: $gray;
    &:before {
      background-image: url($path + "user-add-icon.svg");
    }
  }
}

.admin-company__user-manipulation_removing {
  .admin-company__user-manipulation-top-side {
    background: $red;
    &:before {
      background-image: url($path + "user-remove-icon.svg");
      width: 39px;
      height: 39px;
    }
  }
  .admin-company__user-manipulation-top-side-button {
    background-color: $red;
  }
  .admin-company__user-manipulation-top-side-button {
    &:hover {
      background-color: $redHover;
    }
  }
}

.admin-company__user-manipulation_deactivating {
  .admin-company__user-manipulation-top-side {
    background: $red;
    padding-right: 10px;
    &:before {
      background-image: url($path + "user-deactivate-icon.svg");
      width: 22px;
      height: 32px;
    }
  }
  .admin-company__user-manipulation-top-side-button {
    background-color: $red;
  }
  .admin-company__user-manipulation-top-side-button {
    &:hover {
      background-color: $redHover;
    }
  }
}

.admin-company__user-manipulation-button_red {
  button {
    display: block;
    width: 142px;
    height: 60px;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    line-height: 58px;
    text-align: center;
    color: #ffffff;
    transition: all 0.2s ease-out;
    background: $red;
    border: none;
    &:hover {
      background: $redHover;
    }
  }
}

.admin-company__user-manipulation-button_blue {
  button {
    display: block;
    width: 142px;
    height: 60px;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    line-height: 58px;
    text-align: center;
    color: #ffffff;
    transition: all 0.2s ease-out;
    background: $blue;
    border: none;
    &:hover {
      background: $blueHover;
    }
  }
}

.admin-company__user-manipulation-button_password {
  button {
    width: 197px;
  }
}

.select-pages__select-unit_width-306px {
  width: 306px;
}
.select-pages__select-unit_width-219px {
  width: 219px;
}
.select-pages__select-unit_big-arrow {
  select {
    background: url($path + "arrow-selcet-little.svg") no-repeat right center;
  }
}
.select-pages__select-unit_small-arrow {
  select {
    background: url($path + "arrow-selcet-little.svg") no-repeat right center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .admin-company__user-manipulation-input-unit {
    width: 33.333333%;
    input {
      padding: 0 20px;
    }
    label {
      left: 20px;
    }
  }
  .admin-company__user-manipulation-input-unit:last-child {
    width: 33.333333%;
    input {
      padding: 0 20px;
    }
  }
  .admin-company__project-item-name {
    padding-right: 270px;
  }
  .admin-company__users-top-side-cell_925px {
    left: 285px;
  }
  .admin-company__user-item-bar {
    padding-left: 30px;
  }
  .admin-company__users-top-side-cell_280px {
    width: 175px;
    padding-left: 30px;
  }
  .admin-company__users-top-side-cell_925px {
    position: absolute;
    left: 284px;
  }
  .admin-company__user-manipulation-text {
    padding-left: 0;
  }
  .admin-company__user-manipulation-selects-container {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .admin-company__wrap {
    padding: 0 12px 0 11px;
  }
  .admin-company__company-item-name {
    font-size: 18px;
    line-height: 20px;
  }
  .admin-company__company-item-edit {
    right: 98px;
  }
  .admin-company__company-item-switcher {
    right: 15px;
  }
  .admin-company__company-manipulation-top-side-button {
    width: 60px;
  }
  .admin-company__company-manipulation-content {
    padding-right: 21px;
  }
  .admin-company__company-manipulation-text {
    font-size: 18px;
    line-height: 20px;
  }
  .admin-company__project-item-bar {
    min-height: 58px;
  }
  .admin-company__project-item-name {
    font-size: 16px;
    line-height: 20px;
    padding-right: 135px;
  }
  .admin-company__project-item-status {
    display: none;
  }
  .admin-company__project-item-edit {
    right: 98px;
  }
  .admin-company__project-item-switcher {
    right: 21px;
  }
  .admin-company__project-item-content {
    padding-left: 39px;
  }
  .admin-company__projects-top-side {
    position: relative;
  }
  .admin-company__projects-button-add {
    width: 60px;
  }
  .admin-company__project-manipulation {
    top: 58px;
  }
  .admin-company__project-manipulation-top-side-button {
    width: 60px;
    top: -58px;
  }
  .admin-company__project-manipulation-content {
    padding-right: 22px;
  }
  .admin-company__projects-top-side-cell {
    width: 537px;
    &:last-child {
      position: absolute;
      right: 18px;
      top: 2px;
    }
  }
  .admin-company__users-button-add {
    width: 60px;
  }
  .admin-company__users {
    padding-bottom: 32px;
  }
  .admin-company__users-top-side {
    position: relative;
  }
  .admin-company__users-top-side-cell {
    &:last-child {
      position: absolute;
      top: 0px;
      right: 18px;
    }
  }
  .admin-company__user-item-bar {
    padding-left: 30px;
  }
  .admin-company__user-item-status {
    display: none;
  }
  .admin-company__user-item-switcher {
    right: 22px;
  }
  .admin-company__user-item-link {
    right: 105px;
  }
  .admin-company__user-item-name {
    width: 210px;
  }
  .admin-company__user-manipulation {
  }
  .admin-company__project-item-content {
    padding-bottom: 40px;
  }
  .admin-company__user-manipulation-top-side-button {
    width: 60px;
  }
  .admin-company__user-manipulation-content {
    /*flex-wrap:wrap;*/
    padding: 29px 0 23px 29px;
    justify-content: flex-end;
    .admin-company__user-manipulation-selects-container {
      padding-left: 0px;
      flex-shrink: 0;
      flex-direction: column;
    }
    .admin-company__user-manipulation-buttons {
      margin-top: 0px;
    }
  }

  .admin-company__user-manipulation-selects-unit {
    margin-bottom: 43px;
    select {
      border: 1px solid #e0dcff;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .admin-company__user-manipulation-content {
    min-height: 120px;
    height: auto;
    padding-right: 21px;
    align-items: center;
  }
  .admin-company__user-manipulation-content_add-user {
    flex-direction: column;
    align-items: flex-end;
    .admin-company__user-manipulation-buttons {
      margin-top: 24px;
    }
  }
  .admin-company__user-manipulation-selects-unit {
    width: 100%;
  }
  .admin-company__user-manipulation-text {
    font-size: 16px;
    line-height: 20px;
    padding-left: 0px;
  }

  .select-pages {
    margin-bottom: 29px;
    &:before {
      right: 0px;
    }
  }
  .select-pages__select-unit {
    padding: 0px 0px 0px 21px;
    &:after {
      right: 0px;
      left: 25px;
    }
  }
  .select-pages__links-block-button {
    a {
      width: 139px;
    }
  }

  .select-pages__select-block {
    padding-right: 25px;
  }

  .admin-company__user-manipulation-reason-content {
    padding-left: 59px;
  }
  .admin-company__user-manipulation-reason-field {
    flex-wrap: wrap;
  }
  .admin-company__user-manipulation-reason-input {
    flex-shrink: 0;
    height: 60px;
    margin: 0px;
  }
  .admin-company__user-manipulation-reason-button {
    margin-top: 30px;
  }

  //.admin-company__project-manipulation-reason-content {padding-left: 59px;}
  .admin-company__project-manipulation-reason-field {
    flex-wrap: wrap;
  }
  .admin-company__project-manipulation-reason-input {
    flex-shrink: 0;
    height: 60px;
    margin: 0px;
  }
  .admin-company__project-manipulation-reason-button {
    margin-top: 30px;
  }

  .admin-company__company-manipulation-reason-content {
    padding-left: 59px;
  }
  .admin-company__company-manipulation-reason-field {
    flex-wrap: wrap;
  }
  .admin-company__company-manipulation-reason-input {
    flex-shrink: 0;
    height: 60px;
    margin: 0px;
  }
  .admin-company__company-manipulation-reason-button {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .admin-company__project-item-name {
    padding-right: 0px;
  }
  .admin-company__project-manipulation-content form {
    flex-direction: column;
  }

  .admin-company__wrap {
    padding: 0 10px;
  }
  .admin-company__h1-mobile {
    display: block;
  }
  .admin-company__company-item-bar {
    padding-left: 12px;
  }
  .admin-company__company-item-name {
    font-size: 18px;
    line-height: 20px;
  }
  .admin-company__company-item-edit {
    right: 93px;
  }
  .admin-company__company-item-content {
    padding-left: 0px;
    padding-bottom: 13px;
  }
  .admin-company__company-item-switcher {
    right: 12px;
  }
  .admin-company__company-manipulation-top-side {
    padding-left: 55px;
  }
  .admin-company__company-manipulation-top-side-name {
    font-size: 16px;
  }
  .admin-company__company-manipulation-top-side:before {
    left: 12px;
  }
  .admin-company__company-manipulation-top-side-button {
    width: 60px;
  }
  .admin-company__company-manipulation-text {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    padding: 25px 0 20px 14px;
  }
  .admin-company__company-manipulation-buttons {
    margin: 0 -12px;
    flex-shrink: 0;
    flex-direction: column-reverse;
    a {
      font-size: 16px;
    }
  }
  .admin-company__company-manipulation-content {
    padding-right: 11px;
    padding-left: 12px;
    height: auto;
    min-height: 120px;
    display: block;
  }
  .admin-company__company-manipulation-content form {
    padding-left: 0px;
    padding-top: 30px;
    flex-direction: column;
  }
  .admin-company__company-manipulation-buttons {
    margin-top: 14px;
    flex-direction: row;
  }
  .admin-company__company-manipulation-buttons button {
    width: 100%;
    font-size: 16px;
  }

  .admin-company__company-manipulation-input-container {
    width: 100%;
    flex-shrink: 0;
    padding-top: 30px;
    padding-left: 0;
    label {
      left: 0;
      top: 12px;
    }
  }

  .admin-company__project-manipulation-input-container {
    width: 100%;
    flex-shrink: 0;
    padding-top: 30px;
    padding-left: 0;
    label {
      left: 0;
      top: 12px;
    }
    input {
      padding-left: 14px;
    }
  }

  .admin-company__project-item-bar {
    min-height: 60px;
    padding-left: 14px;
  }
  .admin-company__project-item-name {
    font-size: 16px;
    line-height: 20px;
    width: 60%;
  }
  .admin-company__project-item-status {
    display: none;
  }
  .admin-company__project-item-edit {
    right: 93px;
  }
  .admin-company__project-item-content {
    padding-left: 0px;
    padding-bottom: 13px;
  }
  .admin-company__project-item-switcher {
    right: 21px;
  }
  .admin-company__projects-button-add {
    width: 60px;
  }
  .admin-company__project-manipulation {
    top: 60px;
    left: -12px;
    padding-left: 12px;
  }
  .admin-company__project-manipulation-top-side-button {
    width: 60px;
    top: -60px;
  }
  .admin-company__project-manipulation-text {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    padding: 20px 0 31px 14px;
  }
  .admin-company__project-manipulation-buttons {
    margin: 12px -12px 0 -12px;
    flex-shrink: 0;
    flex-wrap: wrap;
    button {
      font-size: 16px;
      width: 100%;
    }
  }
  .admin-company__project-manipulation-content {
    padding-right: 11px;
    padding-left: 0px;
    height: auto;
    min-height: 120px;
    display: block;

    padding-left: 0px;
    padding-top: 0;
    flex-direction: column;
    .admin-company__project-manipulation-buttons {
      margin: 12px 0 0 0;
    }
  }
  .admin-company__projects-title {
    padding-left: 14px;
  }
  .admin-company__projects-top-side {
    position: relative;
    padding-left: 13px;
  }
  .admin-company__projects-top-side-cell {
    width: 537px;
    &:last-child {
      position: absolute;
      right: 18px;
      top: 2px;
    }
  }
  .admin-company__users-button-add {
    width: 60px;
  }
  .admin-company__users {
    padding: 0 18px 0 12px;
  }
  .admin-company__users-title {
    padding-left: 0px;
  }
  .admin-company__users-top-side {
    position: relative;
    padding-left: 0px;
  }
  .admin-company__users-top-side-cell {
    &:nth-child(2) {
      display: none;
    }
    &:last-child {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
  .admin-company__user-item {
    &:last-child {
      border-bottom: none;
    }
  }
  .admin-company__user-item-bar {
    padding-left: 0px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .admin-company__user-item-bar:hover {
    background: #e9eff5;
  }
  .admin-company__user-item-link {
    right: 69px;
  }
  .admin-company__user-item-status {
    display: none;
  }
  .admin-company__user-item-switcher {
    right: 4px;
  }
  .admin-company__user-item-name {
    width: 143px;
    font-size: 16px;
  }
  .admin-company__user-manipulation {
  }
  .admin-company__user-manipulation-top-side {
    padding-left: 65px;
    &:before {
      left: 18px;
    }
  }
  .admin-company__user-manipulation-top-side-name {
    font-size: 16px;
    line-height: 16px;
  }
  .admin-company__user-manipulation-top-side-button {
    width: 60px;
  }
  .admin-company__user-manipulation-content {
    padding-right: 0;
    min-height: 120px;
    height: auto;
    flex-direction: column;
    align-items: normal;

    flex-wrap: wrap;
    padding: 31px 0 23px 0;
    justify-content: flex-end;
    flex-direction: column;
    .admin-company__user-manipulation-selects-container {
      flex-shrink: 0;
      flex-direction: column;
    }
    .admin-company__user-manipulation-buttons {
      width: auto;
      margin: 0;
    }
  }

  .admin-company__user-manipulation-selects-unit {
    margin-bottom: 43px;
    select {
      border: 1px solid #e0dcff;
      padding-left: 15px;
      background: url("../images/icons/arrow-selcet-nano.svg") #fff no-repeat right 19px center;
    }
    &:last-child {
      margin-bottom: 18px;
    }
  }

  .admin-company__top-side {
    padding-left: 0px;
  }
  .admin-company__user-manipulation-selects-unit {
    width: 100%;
  }
  .admin-company__user-manipulation-text {
    font-size: 16px;
    line-height: 20px;
    padding: 20px 20px 33px 30px;
  }
  .select-pages {
    margin: 0 -10px 25px;
    width: calc(100% + 20px);
    height: auto;
    flex-direction: row-reverse;
    height: auto;
    flex-wrap: wrap;
  }
  .select-pages__select-unit {
    padding: 23px 25px 0 30px;
    margin-bottom: 11px;
    &:last-child {
      margin-bottom: 0px;
    }
    &:after {
      display: none;
    }
    select {
      border-bottom: 1px solid #d2dcea;
      padding-bottom: 12px;
      background: url($path + "arrow-selcet-little.svg") no-repeat right top 5px;
    }
  }
  .select-pages__links-block {
    width: 100%;
  }
  .select-pages__select-block {
    flex-direction: column;
    width: 100%;
    padding-bottom: 40px;
    display: none;
  }
  .select-pages__links-block-button {
    width: 50%;
    a {
      width: 100%;
      font-size: 16px;
    }
  }
  .admin-company__user-manipulation-reason-content {
    padding-left: 48px;
  }
  .admin-company__user-manipulation-reason-field {
    flex-wrap: wrap;
  }
  .admin-company__user-manipulation-reason-input {
    flex-shrink: 0;
    height: 60px;
    margin: 0px;
  }
  .admin-company__user-manipulation-reason-button {
    margin-top: 30px;
  }

  .admin-company__project-manipulation-reason-content {
    padding-left: 14px;
    padding-right: 26px;
  }
  .admin-company__project-manipulation-reason-field {
    flex-wrap: wrap;
  }
  .admin-company__project-manipulation-reason-input {
    flex-shrink: 0;
    height: 60px;
    margin: 0px;
  }
  .admin-company__project-manipulation-reason-button {
    margin-top: 30px;
  }

  .admin-company__company-manipulation-reason-content {
    padding-left: 48px;
  }
  .admin-company__company-manipulation-reason-field {
    flex-wrap: wrap;
  }
  .admin-company__company-manipulation-reason-input {
    flex-shrink: 0;
    height: 60px;
    margin: 0px;
  }
  .admin-company__company-manipulation-reason-button {
    margin-top: 30px;
  }
}

/*================================================================================
  Компонент "Админка компании" Модификаторы для страницы "Пользователи. Клиенты"
  ================================================================================*/

.admin-company__company-item_clients-mode {
  .admin-company__company-item-bar {
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 31px;
      top: 50%;
      margin-top: -4px;
      width: 12px;
      height: 8px;
      background: url($path + "arrow-selcet-micro.svg") no-repeat center / contain;
    }
  }
  .admin-company__project-item-content {
    background: #fff;
  }
  .admin-company__users {
    background: $lightGray;
  }
  .admin-company__users-title {
    background: $lightGray;
    font-size: 24px;
    line-height: 30px;
    padding-left: 38px;
  }
  .admin-company__users-top-side {
  }
  .admin-company__users-top-side-cell {
    background: $lightGray;
  }
  .admin-company__user-item {
  }
  .admin-company__user-item-link {
    background-image: url($path + "edit-icon-clients.svg");
    right: 240px;
    &:after {
      background-image: url($path + "edit-icon-clients-blue.svg");
    }
  }
  .admin-company__user-item-bar {
    background: $lightGray;
    &:hover {
      background: $lightGrayHover;
    }
    &:after {
      content: "";
      opacity: 1;
      position: absolute;
      display: block;
      right: 0;
      top: 0px;
      bottom: 0px;
      width: 6px;
      background: $green;
      z-index: 1;
      @include transition;
    }
  }

  .admin-company__user-item_active {
    //.admin-company__user-item-remove { background-image:url($path + "trash-icon-red.svg");}
  }

  .admin-company__user-item_not-active {
    //.admin-company__user-item-remove { background-image:url($path + "trash-icon-gray.svg");}
    .admin-company__user-item-bar {
      &:after {
        background: $gray;
      }
    }
  }

  .admin-company__users-top-side-cell_mobile {
    display: none;
  }

  .admin-company__user-item-bar {
    padding-left: 38px;
  }
  .admin-company__user-item-name {
    font-size: 16px;
    width: 17%;
    padding-left: 28px;
  }
  .admin-company__user-manipulation {
    /*left:-30px; */
    padding-left: 30px;
  }
  .admin-company__user-manipulation-content {
    /*padding-left:0;*/
  }
  .admin-company__user-manipulation-top-side {
    padding-left: 64px;
    &:before {
      background-image: url($path + "user-add-icon-3.svg");
      left: 14px;
    }
  }

  .admin-company__users-button-add {
    background-image: url($path + "user-add-icon-2.svg");
  }

  .admin-company__user-manipulation_editing {
    .admin-company__user-manipulation-top-side {
      background: $gray;
      &:before {
        background-image: url($path + "user-edit-icon.svg");
      }
    }
    .admin-company__user-manipulation-top-side-button {
      right: 214px;
    }
  }

  .admin-company__user-manipulation_removing {
    .admin-company__user-manipulation-top-side {
      &:before {
        background-image: url($path + "user-remove-icon-2.svg");
        width: 29px;
        height: 32px;
        left: 18px;
      }
    }
  }

  .admin-company__user-manipulation_deactivating {
    .admin-company__user-manipulation-top-side {
      &:before {
        background-image: url($path + "user-deactivate-icon.svg");
        width: 29px;
        height: 32px;
        left: 15px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .admin-company__company-item_clients-mode {
    .admin-company__company-item-bar {
      &:after {
        right: 26px;
      }
    }

    .admin-company__user-item-bar {
      padding-left: 33px;
    }
    .admin-company__users-title {
      padding-left: 33px;
    }
    .admin-company__users-top-side {
      padding-left: 33px;
    }
    .admin-company__user-item-name {
      width: 210px;
    }
    .admin-company__user-item-link {
      right: 187px;
    }
    .admin-company__users-top-side-cell_311px {
      width: 211px;
      padding-left: 28px;
    }
    .admin-company__user-item-remove {
      right: 29px;
    }
    .admin-company__users-top-side-cell {
      &:last-child {
        right: 101px;
      }
    }
    .admin-company__users-top-side-cell_mobile {
      display: block;
      position: absolute;
      right: 161px;
    }
    .admin-company__user-item-status {
      display: block;
      width: 38px;
      height: 18px;
      background: no-repeat center / contain;
      span {
        display: none;
      }
    }

    .admin-company__user-item_active {
      .admin-company__user-item-status {
        background-image: url($path + "switcher-on.svg");
      }
    }

    .admin-company__user-item_not-active {
      .admin-company__user-item-status {
        background-image: url($path + "switcher-off.svg");
      }
    }

    .admin-company__users-title {
      padding-left: 38px;
    }
    .admin-company__project-item-content {
      padding-left: 33px; /*padding-left:0;*/
    }
    .admin-company__user-manipulation-content {
      padding-top: 41px;
      padding-bottom: 37px;
      position: relative;
      padding-right: 37px;
      padding-left: 37px;
      .admin-company__user-manipulation-selects-container {
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
      }
      .admin-company__user-manipulation-buttons {
        position: absolute;
        right: 0;
        bottom: 37px;
        width: 45.7%;
        padding-right: 36px;
        margin-left: 10px;
        .admin-company__user-manipulation-button {
          width: 100%;
          button {
            width: 100%;
          }
        }
        .admin-company__user-manipulation-button_password {
          display: block !important;
          button {
            width: 100%;
            font-size: 14px;
            padding: 0;
          }
        }
        .admin-company__user-manipulation-button_blue {
          button {
            width: 100%;
            font-size: 14px;
          }
        }
      }
    }

    .admin-company__user-manipulation-input-unit {
      width: 51.25%;
      margin-bottom: 42px;
      label {
        left: 0px;
      }
      input {
        border: 1px solid #e0dcff;
        border-radius: 0 0 0 10px;
        width: 100%;
      }
      &:last-child {
        width: 45.4%;
        position: absolute;
        top: 0px;
        right: 0px;
        input {
        }
      }
    }

    .admin-company__user-manipulation_editing {
      .admin-company__user-manipulation-top-side-button {
        right: 165px;
      }
    }

    .admin-company__user-manipulation_removing {
      .admin-company__user-manipulation-top-side-button {
        right: 0px;
      }
    }

    .admin-company__user-manipulation_deactivating {
      .admin-company__user-manipulation-top-side-button {
        right: 89px;
      }
      .admin-company__user-manipulation-text {
        padding-left: 0; /* padding-left:19px;*/
      }
      .admin-company__user-manipulation-buttons {
        position: relative;
        right: 0;
        bottom: 0;
        padding-right: 0;
      }
      .admin-company__user-manipulation-content {
        padding-right: 30px;
      }
    }

    .admin-company__user-manipulation_removing {
      .admin-company__user-manipulation-content {
        padding-right: 30px;
      }
      .admin-company__user-manipulation-text {
        padding-left: 19px;
      }
    }
  }

  .admin-company__users-top-side-cell_280px {
    width: 175px;
  }
  .admin-company__users-top-side-cell_925px {
    position: absolute;
    left: 250px;
  }
  .admin-company__user-item_active {
    .admin-company__user-item-link:after {
      opacity: 1;
    }
  }
  .admin-company__project-item_active {
    .admin-company__project-item-edit:after {
      opacity: 1;
    }
  }

  .admin-company__company-manipulation-button_mobile-width-71px {
    a {
      width: 71px;
    }
  }
  .admin-company__company-manipulation-button_mobile-width-124px {
    a {
      width: 124px;
    }
  }

  .admin-company__project-manipulation_editing {
    .admin-company__project-manipulation-top-side-button {
      right: 98px;
    }
  }

  .admin-company__project-manipulation-button_mobile-width-71px {
    a {
      width: 71px;
    }
  }

  .admin-company__project-manipulation-button_mobile-width-124px {
    a {
      width: 124px;
    }
  }

  .admin-company__user-manipulation_removing {
    .admin-company__user-manipulation-top-side-button {
      right: 81px;
    }
  }

  .admin-company__user-manipulation_deactivating {
    .admin-company__user-manipulation-top-side-button {
      right: 81px;
    }
  }

  .select-pages__select-unit_width-219px {
    width: 180px;
  }
  .select-pages__select-unit_width-306px {
    width: 185px;
  }

  .admin-company__user-manipulation-input-unit_margin-bottom-0-mobile {
    margin-bottom: 0px !important;
  }
  .admin-company__user-manipulation-button_mobile-width-71px {
    button {
      width: 88px;
    }
  }

  .admin-company__users-top-side .admin-company__users-top-side-cell {
    &:last-child {
      right: 18px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .admin-company__company-item_clients-mode {
    .admin-company__users-title {
      padding-left: 0px;
    }
    .admin-company__users-top-side {
      padding-left: 0px;
    }
    .admin-company__user-item-bar {
      padding-left: 0px;
    }
    .admin-company__users {
      padding-right: 0px;
    }
    .admin-company__user-item-link {
      right: 142px;
    }
    .admin-company__user-item-remove {
      right: 11px;
    }
    .admin-company__user-item-position span {
      display: none;
    }
    .admin-company__user-item-status {
      display: block;
      width: 38px;
      height: 18px;
      background: no-repeat center / contain;
      right: 72px;
      span {
        display: none;
      }
    }

    .admin-company__user-item_active {
      .admin-company__user-item-status {
        background-image: url($path + "switcher-on.svg");
      }
    }

    .admin-company__user-item_not-active {
      .admin-company__user-item-status {
        background-image: url($path + "switcher-off.svg");
      }
    }

    .admin-company__users-top-side-cell {
      &:last-child {
        right: 69px;
      }
    }

    .admin-company__users-top-side-cell_mobile {
      display: block;
      position: absolute;
      right: 125px;
    }

    .admin-company__user-manipulation {
      left: -13px;
      padding-left: 13px;
    }

    .admin-company__user-manipulation-input-unit {
      width: 100%;
      margin-bottom: 42px;
      &:last-child {
        margin-bottom: 0;
      }
      input {
        border: 1px solid #e0dcff;
      }
      label {
        left: 0px;
      }
    }

    .admin-company__user-manipulation-content {
      padding-top: 10px;
      .admin-company__user-manipulation-buttons {
        margin: 19px -13px 0 -13px;
        margin: 19px 0px 0 0px;
      }
    }

    .admin-company__user-manipulation-button {
      width: 100%;
      button {
        width: 100%;
        font-size: 16px;
      }
    }

    .admin-company__user-manipulation_editing {
      left: -25px;
      .admin-company__user-manipulation-top-side-button {
        right: 121px;
      }
    }

    .admin-company__user-manipulation_removing {
      left: -12px;
      right: 0px;
      padding-left: 0px;
      .admin-company__user-manipulation-buttons {
        margin: 0 -13px 0 0;
      }
      .admin-company__user-manipulation-top-side-button {
        right: 0px;
      }
    }

    .admin-company__user-manipulation_deactivating {
      left: -12px;
      right: 0px;
      padding-left: 0px;
      .admin-company__user-manipulation-buttons {
        margin: 0;
      }
      .admin-company__user-manipulation-top-side-button {
        right: 61px;
      }
    }

    .admin-company__user-manipulation-buttons {
      margin-top: 18px;
    }
    .admin-company__user-manipulation-buttons button {
      width: 100%;
    }

    .admin-company__user-item-bar:hover {
      background: #f7f9fe;
    }

    .admin-company__user-manipulation-input-unit input {
      padding: 0 19px;
    }
  }

  .admin-company__users-top-side-cell_280px {
    width: auto;
    padding-left: 0;
  }
  .admin-company__users-top-side .admin-company__users-top-side-cell:last-child {
    right: 70px;
  }

  .admin-company__company-item_clients-mode .admin-company__user-item-name {
    width: auto;
    padding-left: 0;
    font-size: 14px;
  }

  .admin-company__user-item-position {
    padding-left: 0;
  }

  .admin-company__user-manipulation-selects-container {
    padding: 30px 18px 0 18px;
    display: flex;
    flex-direction: column;
  }

  .admin-company__user-item_active {
    .admin-company__user-item-link:after {
      opacity: 1;
    }
  }
  .admin-company__project-item_active {
    .admin-company__project-item-edit:after {
      opacity: 1;
    }
  }

  .admin-company__company-manipulation-button_mobile-width-71px {
    width: 100%;
    a {
      width: 100%;
    }
  }
  .admin-company__company-manipulation-button_mobile-width-124px {
    width: 50%;
    a {
      width: 100%;
    }
  }

  .admin-company__project-manipulation_editing {
    .admin-company__project-manipulation-top-side-button {
      right: 73px;
    }
  }

  .admin-company__project-manipulation-button_mobile-width-71px {
    width: 50%;
    a {
      width: 100%;
    }
  }

  .admin-company__project-manipulation-button_mobile-width-124px {
    width: 100%;
    a {
      width: 100%;
    }
  }

  .admin-company__company-manipulation-button_width-100 {
    width: 100%;
    a {
      width: 100%;
    }
  }

  .admin-company__project-manipulation-button_width-100 {
    width: 100%;
    a {
      width: 100%;
    }
  }

  .admin-company__project-manipulation_removing {
    .admin-company__project-manipulation-top-side {
      margin-left: -12px;
      width: auto;
    }
  }

  .admin-company__user-manipulation_attaching {
  }

  .admin-company__users-top-side-cell_311px {
    width: auto;
    padding-left: 0;
  }

  .admin-company__user-manipulation-button_width-100 {
    width: 100%;
    button {
      width: 100%;
    }
  }
  .admin-company__user-manipulation_removing {
    left: -12px;
    right: -18px;
    .admin-company__user-manipulation-top-side {
      padding-left: 60px;
      &:before {
        left: 12px;
      }
    }
    .admin-company__user-manipulation-top-side-button {
      right: 72px;
    }
    .admin-company__user-manipulation-buttons {
      margin: 0 0px 0 -18px;
      width: auto;
    }
  }

  .admin-company__user-manipulation_deactivating {
    left: -12px;
    right: -18px;
    .admin-company__user-manipulation-top-side {
      padding-left: 60px;
      &:before {
        left: 12px;
      }
    }
    .admin-company__user-manipulation-top-side-button {
      right: 72px;
    }
    .admin-company__user-manipulation-buttons {
      margin: 0 -18px 0 0px;
      width: auto;
    }
  }

  .admin-company__user-manipulation-button_mobile-width-71px {
    width: 50%;
    button {
      width: 100%;
    }
  }

  .select-pages__select-unit_width-219px {
    width: 100%;
  }
  .select-pages__select-unit_width-306px {
    width: 100%;
  }

  .admin-company__project-manipulation-reason-field {
    flex-wrap: wrap;
  }
  .admin-company__project-manipulation-reason-input {
    width: 100%;
  }
  .admin-company__project-manipulation-reason-button {
  }
}
