.admin-company__users-top-side {
  padding-left: 38px;

  .admin-company__users-top-side-cell {
    &:nth-child(1) {
      width: 17%;
      padding-left: 0;
      padding-left: 28px;
    }

    &:nth-child(2) {
      min-width: 100px;
      margin-right: 100px;
    }

    &:nth-child(3) {
      min-width: 130px;
      margin-right: 80px;
    }

    &:nth-child(4) {
      min-width: 55px;
      margin-right: 40px;
    }
    &:nth-child(5) {
      min-width: 55px;
      margin-right: 40px;
    }
    &:nth-child(6) {
      min-width: 55px;
      margin-right: 40px;
    }
    &:nth-child(7) {
      min-width: 55px;
      margin-right: 40px;
    }
  }
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .admin-company__users-top-side {
    .admin-company__users-top-side-cell {
      &:nth-child(1) {
        padding-left: 0;
      }

      &:not(:first-child) {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .admin-company__users-top-side {
    .admin-company__users-top-side-cell {
      &:nth-child(1) {
        padding-left: 0;
      }

      &:not(:first-child) {
        display: none;
      }
    }
  }
}
