.Wrapper {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: all 0.2s ease;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
}

.Inner {
  height: calc(100% - 60px);
  width: 100%;
  overflow-y: auto;
}

.Header {
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #2e2382;
  padding-left: 20px;
}

.Title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fff;

  &.Small {
    font-size: 16px;
    line-height: 20px;
  }
}

.Icon {
  margin-right: 20px;

  svg path {
    fill: #fff;
  }
}

.Close {
  position: absolute;
  z-index: 99;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  transition: all 0.2s ease;

  svg path {
    fill: #fff;
  }
}
