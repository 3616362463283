.Wrap {
}

.Inner {
  height: 60px;
  display: flex;
  background-color: #f2f5f9;
}

.BtnFilter {
  height: 100%;
  width: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #becbdc;
}

.BtnQuery {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #505d6f;
  border: 1px solid #becbdc;
  border-left: none;
  padding: 16px;
  overflow: hidden;

  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  svg {
    margin-right: 10px;
  }
}

.BtnAdd {
  height: 100%;
  width: 60px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: #e00c3c;
}

.BtnDownload {
  height: 100%;
  width: 60px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: #94a2b6;
  text-decoration: none;

  &[data-disabled="Y"] {
    opacity: 0.2;
    pointer-events: none;
  }
}
