.Select {
  width: 100%;
  height: 100%;
  position: relative;
}

.select {
  display: none;
}
.selectContainer {
  display: block;
  background: #fff;
  position: relative;
  width: 100%;
  z-index: 5;
}

.selectFilterReplic {
  background: #f2f5f9;
}
.selectTuningProject {
  background: #fff;
}
.selectAddReplic {
  background: #fff;
}
.selectFieldType {
  background: #fff;
}
.selectAdminUsers {
  background: #fff;
}
.selectDisabled {
  .selectBarText {
    opacity: 0.5;
  }
  .selectBar {
    &:after {
      opacity: 0.5;
    }
  }
}

.selectContainerOpen {
  z-index: 10;
}

.selectContainerOpen {
}
.selectBar {
  display: flex;
  height: 61px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -4.5px;
    right: 20px;
    width: 13px;
    height: 7px;
    background: transparent url(../../../images/icons/arrow-select-correct.svg) no-repeat right
      center;
  }
}

.selectBarFilterReplic {
  height: 47px;
  padding-left: 0;
  &:after {
    right: 0;
  }
}

.selectBarTuningProject {
  border: 1px solid #e0dcff;
  width: 100%;
  padding-left: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2e2382;
  height: 60px;
  box-shadow: inset 0px 1px 11px #f2f0ff;
  border-radius: 0px 10px 10px 10px;
  &:after {
    background-image: url(../../../images/icons/arrow-select-correct-blue.svg);
  }
}

.selectBarAddReplic {
  border: 1px solid #e0dcff;
  border-left: none;
  height: 60px;
  div {
    color: #2e2382;
  }
  &:after {
    background-image: url(../../../images/icons/arrow-select-correct-blue.svg);
  }
}

.selectBarFieldType {
  border: 1px solid #e0dcff;
  height: 63px;
  div {
    color: #2e2382;
  }
  &:after {
    background-image: url(../../../images/icons/arrow-select-correct-blue.svg);
  }
}

.selectBarAdminUsers {
  height: 60px;
  padding: 0px;
  border-bottom: 1px solid #fc6386;
  &:after {
    right: 0;
  }
}

.selectBarOpen {
  &:after {
    background-image: url(../../../images/icons/arrow-select-correct-blue.svg);
    transform: rotate(180deg);
  }
}
.selectBarText {
  font-size: 16px;
  line-height: 19px;
  color: #94a2b6;
  font-weight: 500;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.selectList {
  margin: 0;
  width: 100%;
  border-top: 1px solid #f1f1f1;
  box-shadow: 0px 8px 10px rgba(46, 35, 130, 0.13);
}
.selectListContainer {
  position: absolute;
  width: 100%;
  display: none;
}
.selectListContainerOpen {
  display: block;
}
.listItem {
  list-style-type: none;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #94a2b6;
  padding-left: 20px;
  height: 53px;
  background: #fff;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  &:hover {
    background: #e00c3c;
    color: #fff;
  }
  &:last-child {
    border: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .listItem {
    padding-left: 10px;
  }
  .selectBar {
    padding-left: 10px;
    &:after {
      right: 8px;
    }
  }
  .selectBarFilterReplic {
    height: 47px;
    padding-left: 0;
    &:after {
      right: 0;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (max-width: 767px) {
  .selectFilterReplic {
    background: #fff;
  }
  .selectListContainer {
    position: static;
  }

  .selectBarAddReplic {
    border-left: 1px solid #e0dcff;
    border-bottom: none;
  }
}
