.TopReplicSelector {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 72px);
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: #505d6f;
  z-index: 100;

  .Text {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;

    .Num {
      margin-right: 12px;
      font-size: 24px;
      line-height: 20px;
    }
  }

  .BtnBlockWrapper {
    display: flex;
    justify-content: flex-end;

    .BtnWrapper {
      width: 198px;
    }
  }
}

@media (max-width: 767px) {
  .TopReplicSelector {
    width: calc(100%);
    z-index: 10;
    flex-wrap: wrap;

    &[data-delete="true"] {
      flex-direction: row;
      height: 60px;
      width: 100%;

      .Text {
        margin-top: 0;
      }
    }

    .Text {
      height: 60px;
      width: 100%;
    }

    .BtnBlockWrapper {
      flex-wrap: wrap;
      width: 100%;
      display: flex;

      .BtnWrapper {
        width: 100%;
      }

      .BtnWrapperMobile1 {
        order: 2;
        flex: 1;
      }

      .BtnWrapperMobile2 {
        order: 1;
        flex: 1;
      }

      .BtnWrapperMobile3 {
        order: 3;
        flex: 1;
      }
    }
  }
}
