@import "../../style/constants";

.Desktop {
  padding-left: 72px;
  display: flex;
  justify-content: center;
}

.DesktopWrapper {
  padding-left: 30px;
  padding-right: 100px;
  max-width: 1510px;
  width: 100%;
}

.Title {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #2e2382;
  margin-bottom: 16px;
}

@media (min-width: $tabletPortraitMin) and (max-width: $tabletLandscapeMax) {
  .Desktop {
    padding-top: 21px;
    padding-left: 73px;
    padding-right: 0;
    margin-right: 0;
    margin-left: 0;
  }

  .DesktopWrapper {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }

  .Title {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 62px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 978px) {
  .Desktop {
    padding-left: 73px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .Desktop {
    padding-top: 94px;
    padding-left: 0;
  }

  .DesktopWrapper {
    padding: 0 20px;
    padding-top: 60px;
  }

  .Title {
    margin-bottom: 24px;
  }
}
