/* ==========================================================================
   Основные стили сайта
   ========================================================================== */

html,
body {
  margin: 0px;
  padding: 0px;
  font-family: "Gilroy";
  font-size: 14px;
  line-height: 18px;
  color: #000;
  background: #fff;
  height: 100%;
}
html.fancybox-lock {
  overflow: visible !important;
}
html.fancybox-lock body {
  position: relative;
}
a {
  color: #000000;
  outline: none;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
a img {
  border: none;
}
img {
  vertical-align: middle;
}
hr {
  display: block;
  height: 1px;
  border: 0 none;
  border-top: 1px solid #ccc;
  margin: 0px;
  padding: 0px;
}
p {
  margin: 15px 0px;
  padding: 0px;
}
h1 {
  font-size: 36px;
  line-height: 42px;
  color: #fff;
  font-weight: bold;
  font-family: "Gilroy";
  margin: 0px;
  padding: 0px;
}
h2 {
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: bold;
  font-family: "Gilroy";
  margin: 0px;
  padding: 0px;
}
h3 {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  font-weight: bold;
  font-family: "Gilroy";
  margin: 0px;
  padding: 0px;
}
menu {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
ul {
  margin: 25px 0px 25px 30px;
  padding: 0px;
  list-style-type: disc;
}
ol {
  margin: 25px 0px 25px 30px;
  padding: 0px;
  list-style-type: decimal;
}
ol li,
ul li {
  margin: 0px;
  padding: 0px;
}
ol ol,
ul ul {
  margin: 6px 0px 6px 30px;
}
form {
  margin: 0px;
  padding: 0px;
}
button,
input,
select,
textarea {
  outline: none;
  font-family: "Gilroy";
}
textarea {
  overflow: auto;
  resize: vertical;
}
table {
  margin: 0px;
  padding: 0px;
  border: none;
  border-collapse: collapse;
}
table td,
table th {
  margin: 0px;
  padding: 0px;
  border: none;
  border-collapse: collapse;
  text-align: left;
  vertical-align: top;
  font-weight: normal;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.transition,
.transition * {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

/* ==========================================================================
   Основное
   ========================================================================== */

main {
  min-height: 100%;
  min-width: 990px;
  position: relative;
}
.clear.clear-footer {
}
.site-wrap {
  width: 100%;
  min-width: 990px;
  max-width: 1240px;
  margin: 0px auto;
  padding: 0px 20px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}

.analise-select-top__single-value [data-select-option="top-text"] {
  display: block !important;
}

.enter-active {
  visibility: visible !important;
  opacity: 1 !important;
}

.enter-done {
  visibility: visible !important;
  opacity: 1 !important;
}

.no-scroll {
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  main {
    min-width: auto;
  }
}

@media only screen and (max-width: 768px) {
  main {
    min-width: auto;
  }
}

/* ==========================================================================
   Шапка сайта
   ========================================================================== */

.header {
  background: #efefef;
  width: 100%;
  height: 100px;
}

/* ==========================================================================
   Подвал сайта
   ========================================================================== */

.footer {
}

/* ==========================================================================
   Вспомогательные стили
   ========================================================================== */

.show {
  display: block;
}
.hide {
  display: none;
}
.invisible {
  visibility: hidden;
}
.clear:after {
  content: "";
  display: table;
  height: 0px;
  clear: both;
}
.clearfix {
  display: inline-block;
  display: inline;
  zoom: 1;
  width: 100%;
}
::-ms-reveal {
  display: none;
}

.PaginationSettings .select__indicator.select__dropdown-indicator {
  padding: 0;
  padding-left: 8px;
  padding-bottom: 6px;
}

.DownloadDropDown .select__indicator.select__dropdown-indicator {
  padding-right: 14px;
}

/* ==========================================================================
   Стили для печати
   ========================================================================== */

@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
