.Field {
  flex: 1;
  overflow: hidden;
}

.Input {
  width: 100%;
  height: 62px;
  overflow-x: hidden;
  padding: 20px 15px 20px 21px;
  display: flex;
  justify-content: center;
  color: #505d6f;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  background: #ffffff;
  border: 1px solid #e0dcff;
  box-shadow: inset 0 1px 11px #f2f0ff;
  position: relative;

  span {
    display: block;
    overflow: hidden;
    flex: 1;
    white-space: nowrap;
    max-width: 100%;
    width: 100%;
  }

  &.Disabled {
    cursor: default;
  }

  &.FieldInputBorderAll {
    border-radius: 0 10px 10px 10px;
  }

  &.FieldInputBorderRight {
    border-radius: 0 10px 10px 0;
  }

  &.FieldInputBorderLeft {
    border-radius: 0 0 0 10px;
  }
}

.FieldTitle {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #94a2b6;
  display: block;
}

.FieldInput {
  padding: 0 15px;
  background: #2e2382;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #e00c3c;
  }

  span {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
  }

  &.DisabledInput {
    &:hover {
      background: #2e2382;
    }
  }
}

.TrashIcon {
  width: 18px;
  height: 20px;
}

@media only screen and (max-width: 767px) {
  .Field {
    width: 100%;
    margin-top: -1px;
    z-index: 1;
  }

  .Input {
    &.FieldInputBorderRight {
      border-radius: 0 0 10px 0;
    }
  }

  .FieldInput {
    border-radius: 10px 0 10px 10px;
  }
}
