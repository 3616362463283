/*===============================================================
  Компонент "Авторизация" Основные стили
  ===============================================================*/

.rights-reserved {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #becbdc;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (max-width: 767px) {
  .rights-reserved {
    text-align: center;
    line-height: 20px;
  }
}

/*===============================================================
  Компонент "Авторизация" Модификаторы
  ===============================================================*/

.rights-reserved_margin_top-70px {
  margin-top: 72px;
}
.rights-reserved_width-453px {
  width: 453px;
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .rights-reserved_margin_top-70px {
    margin-top: 53px;
  }
}

@media only screen and (max-width: 767px) {
}
