.Wrap {
  padding: 0 20px;
}

.BtnHidden {
  position: relative;
  height: 58px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  background-color: #2e2382;
  margin: 20px auto;
  transition: all 0.2s ease;

  svg {
    position: absolute;
    top: 50%;
    left: 22px;
    transform: translateY(-50%);
  }

  &:hover {
    background-color: #3b2da9;
  }
}

.AddBtn {
  position: relative;
  height: 60px;
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  background-color: #e00c3c;
  cursor: pointer;
  margin: 20px 0;
  transition: all 0.2s ease;

  svg {
    margin-right: 20px;
  }

  &:hover {
    background-color: #ff4763;
  }
}

.AddInput {
  border: none;
  outline: none;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;

  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 26px;
  color: #021913;
  border-radius: 8px;
  padding-left: 24px;
  transition: all 0.2s ease;
}

.AddNewInput {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 20px;
}

.AddLabel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BtnDelete {
  position: relative;
  height: 62px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  background-color: #e00c3c;
  margin: 20px auto;
  transition: all 0.2s ease;

  svg {
    margin-right: 20px;
  }

  &:hover {
    background-color: #ff4763;
  }
}

.Btns {
  width: 100%;
  display: flex;
}

.BtnSave {
  width: 50%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  background-color: #2e2382;
  transition: all 0.2s ease;

  &:hover {
    background-color: #3b2da9;
  }
}

.BtnCancel {
  width: 50%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  background-color: #94a2b6;
  transition: all 0.2s ease;

  &:hover {
    background-color: lighten(#94a2b6, 5);
  }
}
