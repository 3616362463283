.Desktop {
  padding-left: 72px;
  margin-bottom: 120px;
}

.DesktopWrapper {
  padding-left: 30px;
  padding-right: 100px;
}

.Title {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #2e2382;
  margin-bottom: 16px;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 200px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.TableWrapper {
  padding-bottom: 36px;
  border: 2px solid black;
}

.TableWrapper::-webkit-scrollbar {
  display: none;
}

.TableRow {
}

.TableCell {
}

.TableCell:nth-child(2) {
  color: orange;
  text-wrap: nowrap;
}

.TableCell:nth-child(4) {
  text-align: center;
}

.TableHead {
  position: sticky;
  top: 0;
  background: #f2f5f9;
  z-index: 100;
}

.TableHead::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: black;
}

.Create {
  background: #2e2382;
  color: #fff;
}

.Save {
}

.arrowOpen {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.myClass {
  border-radius: 4px;
  // width: fit-content;
}

.controlClass {
  // position: relative;
  // overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  // box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 5px;
  transition: all 200ms ease;
}

.menuClass {
  border: 1px solid gray;
}

.loadingTitle {
  color: #2e2382;
}

.Dropdown {
  border: 1px solid white;
  position: relative;
  z-index: -9999;
  max-width: 50px;
}

.buttonsWrapper {
  display: flex;
  gap: 20px;
  margin-top: 20px;

  & > button {
    background-color: #e00c3c;
    color: #fff;
  }
}

.SearchButtons {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 72px;
}

@media screen and (max-width: 895px) {
  .Title {
    margin-top: 82px;
  }
}


@media screen and (max-width: 530px) {
  .Title {
    margin-top: 130px;
  }

  .SearchButtons {
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px - 72px);

    button {
      width: 100%;
      padding: 0;
    }
  }
}
