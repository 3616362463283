.Wrap {
  width: 100%;
}

.TableHeader {
  display: flex;
  border-top: 1px solid #d0ddef;
  padding: 14px 20px;
}

.TableList {
  min-height: 176px;
  background-color: #fff;
}

.TableRow {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f2f5f9;
  padding: 26px 20px;

  .TableCol {
    font-size: 16px;
    line-height: 19px;
    color: #2e2382;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background-color: #505d6f;
  }

  &[data-type="positive"] {
    &::before {
      background-color: #119e00;
    }
  }

  &[data-type="neutral"] {
    &::before {
      background-color: #505d6f;
    }
  }

  &[data-type="negative"] {
    &::before {
      background-color: #e00c3c;
    }
  }

  &[data-type="pending"] {
    &::before {
      background-color: #ff6400;
    }
  }
}

.TableCol {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #94a2b6;

  &[data-type="position"] {
    min-width: 48px;
    max-width: 48px;
    margin-right: 14px;
  }

  &[data-type="change"] {
    min-width: 62px;
    max-width: 62px;
    margin-right: 20px;
  }

  &[data-type="tonality"] {
    min-width: 72px;
    max-width: 72px;
    margin-right: 44px;
  }

  &[data-type="url"] {
    min-width: 254px;
    max-width: 254px;
    margin-right: 38px;
  }

  &[data-type="info"] {
    flex: 1 1 auto;
    margin-right: 38px;
  }

  &[data-type="type"] {
    min-width: 250px;
    max-width: 250px;
  }
}

.RowPosition {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #94a2b6;
}

.RowChange {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #505d6f;

  svg {
    margin-right: 8px;
  }

  &[data-type="negative"] {
    color: #e00c3c;
  }

  &[data-type="positive"] {
    color: #119e00;
  }

  &[data-position="up"] {
    svg {
      transform: rotate(180deg);

      path {
        fill: #119e00;
      }
    }
  }

  &[data-position="down"] {
    svg path {
      fill: #e00c3c;
    }
  }
}

.RowUrl {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2e2382;
  cursor: pointer;
}

.RowInfoTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #505d6f;
  margin-bottom: 16px;
}

.RowInfoText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #505d6f;
  margin-bottom: 16px;
}

.RowInfoRating {
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #505d6f;
  background: #f7f9fe;
  border-radius: 0px 10px 10px 10px;
  padding: 4px 12px;

  span {
    color: #119e00;
    margin-left: 4px;
  }
}

.SelectorOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  svg {
    width: 28px;
    height: 28px;
  }
}

.EmptyText {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #505d6f;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 76px 0;
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .TableHeader {
    padding: 14px 0;

    display: none;
  }

  .TableList {
  }

  .TableRow {
    flex-wrap: wrap;
    padding: 26px 20px;

    .TableCol {
      font-size: 16px;
      line-height: 19px;
    }

    &::before {
      top: 0;
      right: 0;
      width: 4px;
    }
  }

  .TableCol {
    font-size: 12px;
    line-height: 14px;

    &[data-type="position"] {
      order: 1;
      min-width: 48px;
      max-width: 48px;
      margin-right: 14px;
    }

    &[data-type="change"] {
      order: 2;

      min-width: 50px;
      max-width: 50px;
      margin-right: 20px;
    }

    &[data-type="tonality"] {
      order: 3;

      min-width: 62px;
      max-width: 62px;
      margin-right: 20px;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &[data-type="url"] {
      order: 5;

      width: 100%;
      max-width: 100%;
      margin-right: 38px;
      margin-top: 18px;
      margin-bottom: 18px;
    }

    &[data-type="info"] {
      order: 6;

      width: 100%;
      margin-right: 66px;
    }

    &[data-type="type"] {
      order: 4;
      flex: 1 1 auto;

      min-width: 200px;
      max-width: 100%;
    }
  }

  .RowPosition {
    font-size: 14px;
    line-height: 16px;
  }

  .RowChange {
    font-size: 14px;
    line-height: 24px;

    svg {
      margin-right: 8px;
    }

    &[data-type="negative"] {
    }

    &[data-type="positive"] {
    }
  }

  .RowUrl {
    font-size: 16px;
    line-height: 19px;
  }

  .RowInfoTitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .RowInfoText {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .RowInfoRating {
    width: fit-content;
    font-size: 14px;
    line-height: 20px;
    border-radius: 0px 10px 10px 10px;
    padding: 4px 12px;

    span {
      margin-left: 4px;
    }
  }
}

@media only screen and (max-width: 767px) {
}
