@import "./constants.scss";

$path: "../images/icons/";
$path1: "../images/";

/*===============================================================
  Компонент "Реплики" Основные стили
  ===============================================================*/

.replic {
  padding-top: 57px;
}

.replic__wrap {
  max-width: 1480px;
  padding: 0 20px 0 92px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.replic__top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}

.replic__h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: $blue;
}

.replic__top_col-rev {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.replic__counts {
  margin-left: 32px;
  display: flex;
}

.replic__count-total {
  display: flex;
  align-items: center;
  background: $blue;
  height: 37px;
  padding: 0 18px 0 18px;
  flex-shrink: 0;

  &[data-width="Y"] {
    position: absolute;
    top: 0;
    right: 72px;
    width: 458px;
  }

  &[data-mobile="Y"] {
    width: 100%;
  }
}

.replic__count-refused {
  display: flex;
  align-items: center;
  background: $red;
  height: 37px;
  padding: 0px 18px 0px 18px;
  flex-shrink: 0;
}

.replic__counts__text {
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.replic__counts__number {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 13px;
}

.replic__filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 29px;
  position: relative;
  z-index: 3;
}

.replic__filter-unit {
  border-bottom: 1px solid #d2dcea;
  display: flex;
  position: relative;
  width: 100%;
  margin-right: 40px;

  select {
    padding-right: 20px;
  }

  &:last-child {
    margin-right: 0;
  }

  select {
    font-weight: 500;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    color: $gray;
    background-color: transparent;
    border: none;
    @include appearance;
  }

  input {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-top: 5px;
    color: $gray;
    width: 100%;
    background: transparent;
    border: none;
  }
}

.replic__replic-unit.replic__replic-unit_open {
  .replic__replic-unit-status-botton-agreement {
    background-color: red;
    padding-left: 0;
  }
}

//.replic__filter-unit:before {content: ''; position: absolute; top: 7px; right: 0; width: 13px; height: 7px; background:transparent url($path + "arrow-select-correct.svg") no-repeat right center;}
.replic__filter-unit.big:nth-child(5) {
  min-width: 256px;
}

.replic__filter-unit:nth-child(5):before {
  display: none;
}

.replic__filter-unit:nth-child(6) {
  margin-right: 0;
}

.replic__filter-unit-separator {
  position: relative;
  top: 30px;
  right: 20px;
  display: inline-block;
  width: 10px;
  height: 1px;
  flex-shrink: 0;
  background: $gray;
}

.replic__filter-unit-reset-date {
  position: relative;
  z-index: 1;
  margin-left: 10px;
  cursor: pointer;
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0px;
    top: 20px;
    width: 16px;
    height: 16px;
    background: url($path + "close-icon-s.svg") no-repeat center / contain;
    z-index: -1;
  }
}

.replic__filter-unit-input-container {
  position: relative;
  padding-right: 0;
  padding-top: 15px;
  z-index: 1;
}

.icon_date_for_input {
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 20px;
    width: 16px;
    height: 16px;
    background: url($path + "calendar-icon.svg") no-repeat center / contain;
    z-index: -1;
  }
}

.replic__filter-buttons-mobile {
  display: none;
  position: absolute;
  top: -27px;
  right: 0px;
  width: 120px;
}

.replic__filter-button {
  width: 60px;
  height: 60px;
}

.replic__filter-button_prev {
  background: url($path + "arrow-slider-filter-left.svg") #d0ddef no-repeat center;
}

.replic__filter-button_next {
  background: url($path + "arrow-slider-filter-right.svg") #becbdc no-repeat center;
}

.replic__filter-dafault {
  flex-shrink: 0;
  width: 60px;
  height: 47px;
  background: url($path + "cross-red.svg") no-repeat center;
  cursor: pointer;

  span {
    display: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $blue;
  }
}

/*===============================
flatpickr (календарик в репликах)
===============================*/

.flatpickr-calendar {
  box-shadow: 0px 10px 24px #f2f0ff;
}

.dayContainer {
  margin: 29px 0 42px 0;
  padding: 0 30px;
}

.flatpickr-weekdays {
  display: none;
}

.flatpickr-day {
  font-size: 18px;
  height: 35px;
  line-height: 35px;
  color: #2e2382;
  font-weight: 500;
}

.flatpickr-day.today {
  border-color: #e00c3c;
}

.flatpickr-day.today:hover {
  background: #e00c3c;
  border: 1px solid transparent;
}

.flatpickr-day.today.selected {
  background: #e00c3c;
  border: 1px solid transparent;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #e00c3c;
  border: 1px solid transparent;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: #e00c3c;
  border: 1px solid transparent;
  color: #ffffff;
}

.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
  pointer-events: none;
  font-size: 0px;
}

.flatpickr-day.weekday {
  color: #d0ddef;
}

.flatpickr-day.weekday:hover {
  color: #ffffff;
}

.flatpickr-day.selected.weekday {
  color: #ffffff;
}

.flatpickr-months {
  margin-top: 22px;
  position: relative;
}

.flatpickr-months:before {
  content: "";
  position: absolute;
  top: 50px;
  width: 82%;
  max-width: 100%;
  height: 1px;
  background-color: #becbdc;
  margin: 0 26px;
}

.flatpickr-months .flatpickr-month {
  margin-left: 63px;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  border: 1px solid #94a2b6;
  border-radius: 50%;
  box-sizing: border-box;
  transition: ease 0.2s linear;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  border: 1px solid #d0ddef;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  padding-bottom: 6px;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  transition: ease 0.2s linear;
  fill: #d0ddef;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 24px;
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  left: 62px;
  right: auto;
}

.flatpickr-current-month {
  font-size: 18px;
  line-height: 22px;
  color: #505d6f;
  //margin-left: 17px;
  margin-left: 12px;

  // display: flex;
}

.flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  font-weight: bold !important;
}

.search-and-settings {
  width: 100%;
  margin-bottom: 0px;
}

.search-and-settings__search {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1;

  input {
    &[type="text"] {
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      color: $blue;
      line-height: 22px;
      background: #fff;
      border: none;
      height: 60px;
      padding: 0 30px 0 64px;
      border: 1px solid #fff;

      &::-webkit-input-placeholder {
        color: $gray;
      }

      &:-ms-input-placeholder {
        color: $gray;
      }

      &::-moz-placeholder {
        color: $gray;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: $gray;
        opacity: 1;
      }
    }

    &[type="submit"] {
      position: absolute;
      border: none;
      left: 1px;
      top: 1px;
      width: 64px;
      height: 58px;
      background: url($path + "magnifier-icon.svg") #fff no-repeat center;
    }

    &:focus {
      border-color: #e0dcff;
    }
  }
}

.search-and-settings__tune-mobile {
  position: relative;
  width: 60px;
  height: 60px;
  background: #fff;
  flex-shrink: 0;
  display: none;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -11px;
    margin-left: -13.5px;
    width: 27px;
    height: 22px;
    background: url($path + "menu-replic-tune-blue.svg") no-repeat center / contain;
  }

  &[data-active="Y"] {
    z-index: 100;
  }
}

.search-and-settings__search-mobile {
  background: #becbdc;
  height: 60px;
  width: inherit;
  position: relative;
  display: none;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 17px;
    top: 50%;
    margin-top: -13.5px;
    width: 27px;
    height: 27px;
    background: url($path + "magnifier-icon-white.svg") no-repeat center / contain;
  }
}

.search-and-settings__settings {
  display: flex;
}

.search-and-settings__upload {
  width: 233px;
  height: 60px;
  background: $darkGray;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 60px;
  color: #ffffff;
  padding-left: 61px;
  cursor: pointer;
  @include transition;

  &:before {
    content: "";
    width: 29px;
    height: 28px;
    position: absolute;
    left: 16px;
    top: 50%;
    margin-top: -14px;
    background: url($path + "upload-icon.svg") no-repeat center;
  }

  &:hover {
    background: $darkGrayHover !important;
  }
}

a.search-and-settings__upload-text {
  color: #fff;
  text-decoration: none;
  display: block;
  background: $blue;
  @include transition;

  &.active {
    background: $red;

    &:hover {
      background: $redHover;
    }
  }

  &:hover {
    background: $blueHover;
  }
}

.search-and-settings__download {
  width: 233px;
  height: 60px;
  background: $gray;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 60px;
  color: #ffffff;
  padding-left: 61px;
  cursor: pointer;
  @include transition;

  &:before {
    content: "";
    width: 29px;
    height: 28px;
    position: absolute;
    left: 16px;
    top: 50%;
    margin-top: -14px;
    background: url($path + "cloud-icon.svg") no-repeat center;
  }

  &:hover {
    background: $grayHover !important;
  }
}

a.search-and-settings__download-text {
  color: #fff;
  text-decoration: none;
  display: block;
  background: $blue;
  @include transition;

  &.active {
    background: $red;

    &:hover {
      background: $redHover;
    }
  }

  &:hover {
    background: $blueHover;
  }
}

.search-and-settings__top-side {
  display: flex;
  position: relative;
}

.search-and-settings__add-edit-replic {
  white-space: nowrap;
  width: auto;
  height: 60px;
  background: $red;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 60px;
  color: #ffffff;
  padding-left: 61px;
  padding-right: 24px;
  cursor: pointer;
  @include transition;

  &.padding-right-none {
    padding-right: 0;
  }

  &:before {
    content: "";
    width: 29px;
    height: 28px;
    position: absolute;
    left: 16px;
    top: 50%;
    margin-top: -14px;
    background: url($path + "add-icon.svg") no-repeat center;
  }

  &:hover {
    background: $redHover !important;
  }
}

.search-and-settings__add-edit-replic_padding {
  .search-and-settings__add-edit-replic {
    padding-right: 0px;
  }
}

a.search-and-settings__add-edit-replic-text {
  display: block;
  color: #fff;
  text-decoration: none;
  background: $blue;
  @include transition;

  &.active {
    background: $red;

    &:hover {
      background: $redHover;
    }
  }

  &:hover {
    background: $blueHover;
  }
}

.search-and-settings__settings-button,
.search-and-settings__settings-button_back {
  width: 60px;
  height: 60px;
  background: $blue;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 60px;
  color: #ffffff;
  padding-left: 61px;
  cursor: pointer;
  @include transition;

  &:before {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -18px 0 0 -18px;
    background: url($path + "setting-icon.svg") no-repeat center / contain;
  }

  &:after {
    content: "";
    opacity: 0;
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    background: url($path + "close-icon.svg") no-repeat center;
  }

  &[data-active="Y"] {
    z-index: 200;

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }

  &.open:after {
    opacity: 1;
  }

  &.open:before {
    opacity: 0;
  }

  &:hover {
    background: $blueHover;
  }
}

.search-and-settings__settings-button_back {
  display: none;
  position: absolute;
  z-index: 100;
  background: url($path + "cross-red.svg") no-repeat center;
  top: 0;
  right: 0;

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }
}

.search-and-settings__contain-block {
  margin-top: -60px;
  position: relative;
  z-index: 100;
}

.search-and-settings__title-bar {
  width: 100%;
  height: 60px;
  background: $blue;
  padding-left: 67px;
  line-height: 58px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  position: relative;
  z-index: 1100;

  .search-and-settings__settings-button &:before {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50%;
    left: 18px;
    margin: -18px 0 0 0px;
    background: url($path + "setting-icon.svg") no-repeat center;
  }
}

.search-and-settings__contain-block-container {
  background: #fff;
  padding: 34px 35px 32px 30px;
}

.search-and-settings__top-select {
  margin-bottom: 26px;

  select {
    border: 1px solid #e0dcff;
    width: 100%;
    background: url($path + "arrow-select-correct-purple.svg") #fff no-repeat right 24px center;
    padding-left: 32px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $blue;
    height: 60px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;
    @include appearance;
  }
}

.search-and-settings__fields {
  background: $lightGray;
  padding-bottom: 47px;
}

.search-and-settings__fields-header-bar {
  display: flex;
  padding-left: 37px;
  border-bottom: 1px solid #d0ddef;
  height: 45px;
  align-items: center;
  margin-bottom: 36px;
}

.search-and-settings__fields-cell {
  margin-right: 33px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $gray;

  &:last-child {
    margin-right: 0;
  }
}

.search-and-settings__fields-header-bar-mobile {
  display: none;
  padding-left: 42px;
  border-bottom: 1px solid #d0ddef;
  height: 45px;
  align-items: center;
  margin-bottom: 36px;
}

.search-and-settings__fields-cell-mobile {
  margin-right: 33px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $gray;

  &:last-child {
    margin-right: 0;
  }
}

.search-and-settings__fields-header-bar-mobile-arrow {
  width: 8px;
  height: 13px;
  background: url($path + "arrow-bar-mobile.svg") no-repeat center / contain;
}

.search-and-settings__fields-unit {
  display: flex;
  margin-bottom: 29px;
  position: relative;
}

.search-and-settings__field-checkbox {
  padding-left: 37px;
  margin-right: 69px;
  padding-top: 20px;

  label {
    width: 19px;
    height: 19px;
    border: 1px solid $gray;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      opacity: 0;
      position: absolute;
      width: 17px;
      height: 17px;
      background: url($path + "tick.svg") no-repeat center;
      @include transition;
    }
  }

  input {
    display: none;

    &:checked + label:before {
      opacity: 1;
    }
  }
}

.search-and-settings__field-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $blue;
  width: 212px;
  flex-shrink: 0;
  padding: 23px 0 0 3px;
}

.search-and-settings__field-value {
  padding-right: 95px;
  width: 100%;
  position: relative;

  .search-and-settings__field-value_wrapper-btn {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0px 10px 0px;
    position: relative;

    button {
      position: absolute;
      top: 0px;
      right: -37px;
      width: 21px;
      height: 21px;
      border: none;
      background: url($path + "plus-icon-mini-gray.svg") no-repeat center;
      cursor: pointer;

      &:after {
        content: "";
        opacity: 0;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url($path + "plus-icon-mini-red.svg") no-repeat center;
        @include transition;
      }
    }

    &:hover:after {
      opacity: 1;
    }
  }
}

.search-and-settings__field-value-type-mobile {
  display: none;
  position: absolute;
  left: 0;
  top: -21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $blue;
}

.search-and-settings__field-button-add {
  position: absolute;
  top: 50%;
  margin-top: -10.5px;
  right: -38px;
  width: 21px;
  height: 21px;
  background: url($path + "plus-icon-mini-gray.svg") no-repeat center;
  cursor: pointer;

  &:after {
    content: "";
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url($path + "plus-icon-mini-red.svg") no-repeat center;
    @include transition;
  }

  &:hover:after {
    opacity: 1;
  }
}

.search-and-settings__field-button-remove {
  position: absolute;
  top: 50%;
  margin-top: -10.5px;
  right: 14px;
  width: 21px;
  height: 21px;
  background: url($path + "minus-icon.svg") no-repeat center;
  cursor: pointer;

  &:after {
    content: "";
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url($path + "minus-icon-red.svg") no-repeat center;
    @include transition;
  }

  &:hover:after {
    opacity: 1;
  }
}

.search-and-settings__field-input-text {
  position: relative;

  input {
    background: #ffffff;
    border: 1px solid #e0dcff;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    height: 60px;
    padding-left: 20px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;

    &::-webkit-input-placeholder {
      color: $gray;
    }

    &:-ms-input-placeholder {
      color: $gray;
    }

    &::-moz-placeholder {
      color: $gray;
      opacity: 1;
    }

    &:-moz-placeholder {
      color: $gray;
      opacity: 1;
    }

    &:focus {
      border-color: #f6b5c3;
    }
  }
}

.search-and-settings__field-list-item {
  padding-left: 20px;
  margin-top: 29px;
  position: relative;

  &:nth-child(2) {
    margin-top: 24px;
  }

  input {
    background: #ffffff;
    border: 1px solid #e0dcff;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    height: 60px;
    padding-left: 31px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;

    &::-webkit-input-placeholder {
      color: $gray;
    }

    &:-ms-input-placeholder {
      color: $gray;
    }

    &::-moz-placeholder {
      color: $gray;
      opacity: 1;
    }

    &:-moz-placeholder {
      color: $gray;
      opacity: 1;
    }
  }
}

.search-and-settings__field-date {
  position: relative;
  margin-left: 21px;
  padding-top: 3px;

  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 20px;
    height: 20px;
    background: url($path + "calendar-icon.svg") no-repeat center;
  }

  input {
    width: 100px;
    border: none;
    background: transparent;
    border-bottom: 1px solid #e0dcff;
    padding-bottom: 20px;
    padding-left: 29px;
  }
}

.search-and-settings__fields-bottom-side {
  margin-top: 38px;
  padding-left: 37px;
  padding-right: 93px;
  display: flex;
}

.search-and-settings__fields-bottom-side_additional-top-padding {
  padding-top: 38px;
}

.search-and-settings__fields-manage-fields {
  display: flex;
  width: 100%;

  select {
    height: 63px;
    width: 100%;
    padding-left: 24px;
    border: 1px solid #e0dcff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $blue;
    @include appearance;
    background: url($path + "arrow-select-correct-purple.svg") #ffffff no-repeat right 45px center;
  }
}

.search-and-settings__fields-manage-fields .search-and-settings__fields-select-container {
  width: 100%;
}

.search-and-settings__fields-add-button {
  width: 63px;
  height: 63px;
  border: 1px solid #e0dcff;
  border-left: none;
  background: url($path + "plus-icon.svg") #fff no-repeat center;
  cursor: pointer;
}

.search-and-settings__fields-buttons {
  margin-left: 84px;
  display: flex;
  width: 100%;
}

.search-and-settings__fields-button {
  margin-right: 17px;
  width: 100%;

  &:last-child {
    margin-right: 0px;
  }

  button {
    width: 100%;
    border: none;
    display: inline-block;
    height: 63px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    line-height: 63px;
    text-align: center;
    color: #ffffff;
    background: $gray;
    @include transition;

    &:hover {
      background: $grayHover;
    }
  }
}

.search-and-settings__fields-trigger {
  display: none;
  width: 63px;
  height: 63px;
  border: 1px solid #e0dcff;
  flex-shrink: 0;
  background: url($path + "plus-icon.svg") #fff no-repeat center;
  cursor: pointer;
}

.replic__replic-container {
  width: 100%;
  padding-top: 34px;
}

.replic__replic-header-bar {
  display: flex;
  padding-left: 19px;
  padding-bottom: 9px;
  position: relative;
}

.replic__replic-header-bar-cell {
  display: flex;
  align-items: center;
  width: 19px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $gray;

  &:first-child {
    margin-right: 20px;
  }
}

.replic__replic-header-bar-add-button {
  width: 19px;
  height: 19px;
  background: url($path + "red-squire-icon_v2.svg") no-repeat center / contain;
  position: relative;
  top: 0px;
}

.replic__replic-unit {
  background: #fff;
  padding: 26px 33px 26px 19px;
  margin-bottom: 15px; /*min-height:150px;*/
  position: relative;

  &[data-status="posted"] {
    .replic__replic-unit-status-text {
      opacity: 1;
      //pointer-events: none;

      &[data-posted="Y"] {
        span {
          opacity: 0;
        }
      }
    }

    &:hover {
      .replic__replic-unit-status-botton-agreement {
        opacity: 1;
        visibility: visible;

        &[data-posted="Y"] {
          background-color: #822381;
        }
      }
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 7px;
  }

  &:hover {
    .replic__replic-unit-status-botton-agreement {
      opacity: 1;
      visibility: visible;
    }
  }
}

.replic__replic-unit_open {
  &:hover {
    .replic__replic-unit-status-botton-agreement {
      opacity: 0;
      visibility: visible;
    }
  }
}

.replic__replic-unit-status-botton-agreement {
  &[data-mobile="Y"] {
    background: url($path + "icon-cross-white.svg") #822381 no-repeat center;
  }

  &:hover {
    opacity: 1 !important;
  }
}

.replic__replic-unit-wrap {
  display: flex;
  position: relative;
  min-height: 98px;
  word-break: break-all;
}

.replic__replic-unit-button-close-mobile {
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  background: url($path + "arrow-button-close-replic.svg") $gray no-repeat center;
  z-index: 3;
  cursor: pointer;

  &[data-posted="Y"] {
    background: url($path + "icon-cross-white.svg") #822381 no-repeat center !important;
  }
}

.replic__replic-unit-checkbox {
  width: 19px;
  flex-shrink: 0;
  padding-top: 4px;
  margin-right: 20px;

  label {
    width: 19px;
    height: 19px;
    border: 1px solid $gray;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      opacity: 0;
      position: absolute;
      width: 17px;
      height: 17px;
      background: url($path + "tick.svg") no-repeat center;
      @include transition;
    }
  }

  input {
    display: none;

    &:checked + label:before {
      opacity: 1;
    }
  }
}

.replic__replic-unit-date {
  padding-top: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $gray;
  width: 82px;
  margin-right: 45px;
  flex-shrink: 0;
}

.replic__replic-unit-area {
  width: 7%;
  margin-right: 120px;
  flex-shrink: 0;
  padding-top: 6px;

  a {
    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    line-height: 19px;
    color: $blue;
  }
}

.replic__replic-unit-replic-content {
  width: 100%;
  margin-right: 40px;
}

.replic__replic-unit-replic-text {
  height: 30px;
  width: 96%;
  font-weight: 500;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 24px;
  color: $darkGray;
  overflow: hidden;
}

.replic__replic-unit-status {
  width: 19%;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.replic__replic-unit-status-text {
  span {
    margin: 60px 0 0 84px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    position: relative;

    b {
      display: block;
      font-weight: 500;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -36px;
      width: 24px;
      height: 24px;
      background: no-repeat center;
    }
  }
}

.replic__replic-unit-replic-note {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.replic__replic-unit-replic-note-text {
  background: $lightGray;
  //margin-top: 24px;
  border-radius: 0px 10px 10px 10px;
  padding: 12px 22px 13px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $darkGray;

  a {
    color: $blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.replic__replic-unit-replic-note-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -200px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $gray;
  width: 130px;
}

.replic__replic-unit-status-botton-agreement {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -26px;
  right: -32px;
  height: calc(100% + 52px);
  left: 0;
  background: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include transition;
}

.replic__replic-unit-status-botton-agreement-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  position: relative;
  transform: translate(-12px, 4px);

  &.up {
    transform: translate(-16px, 4px);

    &::before {
      background: url($path + "arrow-plain-up.svg") no-repeat center;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: -32px;
    left: 0px;
    display: block;
    width: 11px;
    height: 18px;
    background: url($path + "arrow-plain-down.svg") no-repeat center;
  }
}

.replic__replic-unit-replic-link {
  margin-top: 20px;
  padding-top: 3px;
  border-top: 1px solid #d0ddef;
  display: inline-block;
  padding-right: 5px;

  a {
    display: inline-block;
    padding-left: 25px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #2e2382;

    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 0px;
      top: 8px;
      width: 18px;
      height: 18px;
      background: url($path + "link-icon.svg") no-repeat center / contain;
    }
  }
}

.replic__replic-unit-status-text {
  br {
    display: none;
  }

  span {
    display: inline-block;
  }
}

.replic__replic-unit[data-status="no-status"]:after {
  background: $red;
}

.replic__replic-unit[data-status="created"]:after {
  background: #ff7400;
}

.replic__replic-unit[data-status="waiting-agreement"]:after {
  background: $blue;
}

.replic__replic-unit[data-status="refused"]:after {
  background: $red;
}

.replic__replic-unit[data-status="for-posting"]:after {
  background: $green;
}

.replic__replic-unit[data-status="under-moderation"]:after {
  background: $lightBlue;
}

.replic__replic-unit[data-status="posted"]:after {
  background: $gray;
}

.replic__replic-unit[data-status="no-status"] .replic__replic-unit-status-text {
  span {
    color: $red;

    &:before {
      background-image: url($path + "created-icon.svg");
    }
  }
}

.replic__replic-unit[data-status="created"] .replic__replic-unit-status-text {
  span {
    color: #ff7400;

    &:before {
      background-image: url($path + "created-icon.svg");
    }
  }
}

.replic__replic-unit[data-status="waiting-agreement"] .replic__replic-unit-status-text {
  span {
    color: $blue;

    &:before {
      background-image: url($path + "waiting-agreement-icon.svg");
    }
  }
}

.replic__replic-unit[data-status="refused"] .replic__replic-unit-status-text {
  span {
    color: $red;

    &:before {
      background-image: url($path + "refused-icon.svg");
    }
  }
}

.replic__replic-unit[data-status="for-posting"] .replic__replic-unit-status-text {
  span {
    color: $green;

    &:before {
      background-image: url($path + "for-posting-icon.svg");
    }
  }
}

.replic__replic-unit[data-status="under-moderation"] .replic__replic-unit-status-text {
  span {
    color: $lightBlue;

    &:before {
      background-image: url($path + "under-moderation.svg");
    }
  }
}

.replic__replic-unit[data-status="posted"] .replic__replic-unit-status-text {
  span {
    color: $gray;

    &:before {
      background-image: url($path + "posted-icon.svg");
    }
  }
}

// .replic__replic-unit[data-status="no-status"] .replic__replic-unit-status-botton-agreement {display:flex;}

.replic__replic-unit-ticket {
  background: $lightGray;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 28px 15px 11px;
  padding: 0 0px 33px;
  word-break: break-all;
}

.replic__replic-unit-ticket-buttons {
  display: flex;
  width: 100%;
}

.replic__replic-unit-ticket-button {
  width: 100%;

  button {
    display: block;
    width: 100%;
    height: 63px;
    border: none;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 63px;
    text-align: center;
    color: #ffffff;
    @include transition;
  }
}

.replic__replic-unit-ticket-button_green {
}

.replic__replic-unit-ticket-screen-shot-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding-left: 159px;
  padding-bottom: 34px;
}

.replic__replic-unit-ticket-screen-shot-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $blue;
  white-space: nowrap;
}

.replic__replic-unit-ticket-screen-shot-input {
  width: 81.5%;

  input {
    background: #fff;
    border: 1px solid #e0dcff;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 58px;
    color: $blue;
    height: 60px;
    padding-left: 37px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 10px;

    &:focus {
      border-color: #a295ff;
    }
  }
}

.replic__replic-unit-ticket-item {
  display: flex;
  margin-top: 40px;
}

.replic__replic-unit-ticket-item-left-side {
  width: 25%;
  flex-shrink: 0;
  padding-top: 39px;
  padding-left: 125px;
}

.replic__replic-unit-ticket-item-left-side-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $blue;
}

.replic__replic-unit-ticket-item-right-side {
  padding-right: 97px;
  width: 75%;
}

.replic__replic-unit-ticket-client-comment {
  background: #ffffff;
  border-radius: 0px 10px 10px 10px;
  padding: 32px 21px 21px 76px;
  position: relative;
}

.replic__replic-unit-ticket-client-comment-date {
  position: absolute;
  top: -26px;
  left: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $gray;
}

.replic__replic-unit-ticket-client-comment-type-mobile {
  display: none;
  position: absolute;
  top: -25px;
  left: 72px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $blue;
}

.replic__replic-unit-ticket-client-comment-replic {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    background: no-repeat center;
    left: -56px;
    top: 1px;
  }
}

.replic__replic-unit-ticket-item[data-status="no-status"]
  .replic__replic-unit-ticket-client-comment-replic {
  color: $red;

  &:before {
    background-image: url($path + "created-icon.svg");
  }
}

.replic__replic-unit-ticket-item[data-status="created"]
  .replic__replic-unit-ticket-client-comment-replic {
  color: #ff7400;

  &:before {
    background-image: url($path + "created-icon.svg");
  }
}

.replic__replic-unit-ticket-item[data-status="waiting-agreement"]
  .replic__replic-unit-ticket-client-comment-replic {
  color: $blue;

  &:before {
    background-image: url($path + "waiting-agreement-icon.svg");
  }
}

.replic__replic-unit-ticket-item[data-status="refused"]
  .replic__replic-unit-ticket-client-comment-replic {
  color: $red;

  &:before {
    background-image: url($path + "refused-icon.svg");
  }
}

.replic__replic-unit-ticket-item[data-status="for-posting"]
  .replic__replic-unit-ticket-client-comment-replic {
  color: $green;

  &:before {
    background-image: url($path + "for-posting-icon.svg");
  }
}

.replic__replic-unit-ticket-item[data-status="under-moderation"]
  .replic__replic-unit-ticket-client-comment-replic {
  color: $lightBlue;

  &:before {
    background-image: url($path + "under-moderation.svg");
  }
}

.replic__replic-unit-ticket-item[data-status="posted"]
  .replic__replic-unit-ticket-client-comment-replic {
  color: $gray;

  &:before {
    background-image: url($path + "posted-icon.svg");
  }
}

.replic__replic-unit-ticket-item[data-status="no-status"]
  .replic__replic-unit-ticket-item-left-side-text {
  color: $red;
}

.replic__replic-unit-ticket-item[data-status="created"]
  .replic__replic-unit-ticket-item-left-side-text {
  color: #ff7400;
}

.replic__replic-unit-ticket-item[data-status="waiting-agreement"]
  .replic__replic-unit-ticket-item-left-side-text {
  color: $blue;
}

.replic__replic-unit-ticket-item[data-status="refused"]
  .replic__replic-unit-ticket-item-left-side-text {
  color: $red;
}

.replic__replic-unit-ticket-item[data-status="for-posting"]
  .replic__replic-unit-ticket-item-left-side-text {
  color: $green;
}

.replic__replic-unit-ticket-item[data-status="under-moderation"]
  .replic__replic-unit-ticket-item-left-side-text {
  color: $lightBlue;
}

.replic__replic-unit-ticket-item[data-status="posted"]
  .replic__replic-unit-ticket-item-left-side-text {
  color: $gray;
}

.replic__replic-unit-ticket-client-comment-comment {
  background: $lightGray;
  border-radius: 0px 10px;
  padding: 29px 37px 24px 24px;
  margin-top: 46px;
  position: relative;
}

.replic__replic-unit-ticket-client-comment-comment-target {
  display: flex;
  position: absolute;
  left: 0;
  top: -25px;
}

.replic__replic-unit-ticket-client-comment-comment-target-text {
  font-size: 12px;
  line-height: 20px;
  color: $gray;
  margin-right: 12px;
}

.replic__replic-unit-ticket-client-comment-comment-target-date {
  font-size: 12px;
  line-height: 20px;
  color: $blue;
}

.replic__replic-unit-ticket-client-comment-comment-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $darkGray;
}

.replic__replic-unit-ticket-item-info {
  border: 1px solid #d0ddef;
  border-radius: 0px 10px 10px 10px;
  padding: 20px 20px 20px 20px;
  position: relative;
}

.replic__replic-unit-ticket-item-info-date {
  position: absolute;
  top: -26px;
  left: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $gray;
}

.replic__replic-unit-ticket-item-info-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $darkGray;
}

.replic__replic-unit-ticket-item-info-text a {
  color: $darkGray;
  text-decoration: none;
}

.replic__replic-unit-ticket-item-replic {
  box-shadow: 0px 2px 8px #e0e5ec;
  background: #fff;
  border-radius: 0px 10px 10px 10px;
  padding: 20px 20px 20px 20px;
  position: relative;
}

.replic__replic-unit-ticket-item-replic-date {
  position: absolute;
  top: -26px;
  left: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $gray;
}

.replic__replic-unit-ticket-item-replic-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $darkGray;
}

.replic__replic-unit-ticket-write-client-comment {
  padding-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.replic__replic-unit-ticket-write-client-comment textarea {
  background: #ffffff;
  padding: 26px 18px 20px 18px;
  resize: none;
  width: 100%;
  height: 144px;
  border: 2px solid #ffb4c5;
  box-shadow: inset 0px 1px 11px #f2f0ff;
  border-radius: 0px 10px 10px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $darkGray;
}

.replic__replic-unit-ticket-write-client-comment.green textarea {
  border: 2px solid $green;
}

.replic__replic-unit-ticket-write-client-comment-type-mobile {
  display: none;
  position: absolute;
  top: -31px;
  left: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $red;
}

.replic__replic-unit-ticket-write-client-comment-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 27px;
}

.replic__replic-unit-ticket-write-client-comment-button {
  margin-right: 15px;

  &:last-child {
    margin-right: 0px;
  }

  &.replic__replic-unit-ticket-write-client-comment-button_cancel button {
    width: 180px;
    height: 63px;
    display: block;
    text-decoration: none;
    text-align: center;
    border: none;
    background: $gray;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 63px;
    @include transition;

    &:hover {
      background: $grayHover;
    }
  }

  &.replic__replic-unit-ticket-write-client-comment-button_public button {
    width: 333px;
    height: 63px;
    border: none;
    background: $red;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    @include transition;

    &:hover {
      background: $redHover;
    }
  }

  &.replic__replic-unit-ticket-write-client-comment-button_public_2 button {
    width: 333px;
    height: 63px;
    border: none;
    background: $green;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    @include transition;

    &:hover {
      background: $greenHover;
    }
  }
}

.replic__replic-unit-manage-button {
  width: 100%;
}

.replic__replic-unit-manage-button button {
  width: 100%;
  height: 63px;
  display: block;
  text-decoration: none;
  text-align: center;
  border: none;
  background: $gray;
  color: #fff;
  font-weight: 600;
  font-size: 18px; /*line-height: 63px;*/
  @include transition;
}

.replic__replic-unit-manage-buttons {
  display: flex;
  padding: 0 130px 0 26.55%;
  margin-top: 29px;
}

.add-edit-replic {
  width: 100%;
  margin-top: -60px;
  background: #fff;
  position: relative; /*border-bottom:1px solid $gray;*/
  z-index: 300;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 60px;
    bottom: 0;
    width: 7px;
    background: $blue;
  }

  .add-edit-replic-horizont-line {
    top: 187px;
  }

  .add-edit-replic-vertical-line {
    display: none;
    height: calc(100% - 198px + 50px);

    &[data-line="Y"] {
      display: block;
      top: 188px;
    }
  }
}

.replic__top_col-rev .add-edit-replic {
  margin-top: -15px;
}

.add-edit-replic-vertical-line {
  width: 3px;
  display: none;
  height: calc(50% + 50px);
  background-color: #f7f9fe;
  position: absolute;
  left: -42px;
  z-index: 100;

  &[data-line="Y"] {
    display: block;
    top: calc(50% + 1px);
  }
}

.add-edit-replic-horizont-line {
  width: 40px;
  height: 1px;
  background-color: #94a2b6;
  position: absolute;
  top: 50%;
  left: -40px;
}

.add-edit-replic-top-side {
  display: flex;
  width: 100%;
}

.add-edit-replic-top-side-name {
  width: auto;
  height: 60px;
  background: $gray;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 0 20px 0 64px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &:before {
    content: "";
    width: 29px;
    height: 28px;
    position: absolute;
    left: 16px;
    top: 50%;
    margin-top: -14px;
    background: url($path + "add-icon-gray.svg") no-repeat center;
  }
}

.add-edit-replic-top-side-select-unit {
  width: 100%;

  select {
    height: 60px;
    width: 100%;
    padding: 0 38px 0 30px;
    border: 1px solid #e0dcff;
    border-left: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $blue;
    @include appearance;
    background: url($path + "arrow-select-correct-purple.svg") #ffffff no-repeat right 18px center;

    &[name="STATUS"] {
      width: 284px;
      flex-shrink: 0;
    }
  }
}

.add-edit-replic-top-side-select-unit-text {
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px 0 19px;
  border: 1px solid #e0dcff;
  border-left: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  &:before {
    content: "";
    position: relative;
    margin-right: 10px;
    top: 0px;
    width: 24px;
    height: 24px;
    background: no-repeat center;
  }

  &.created {
    color: #ff7400;

    &:before {
      background-image: url($path + "created-icon.svg");
    }
  }

  &.refused {
    color: $red;

    &:before {
      background-image: url($path + "refused-icon.svg");
    }
  }

  &.waiting-agreement {
    color: $blue;

    &:before {
      background-image: url($path + "waiting-agreement-icon.svg");
    }
  }

  &.for-posting {
    color: $green;

    &:before {
      background-image: url($path + "for-posting-icon.svg");
    }
  }

  &.under-moderation {
    color: #00afff;

    &:before {
      background-image: url($path + "under-moderation.svg");
    }
  }

  &.posted {
    color: $gray;

    &:before {
      background-image: url($path + "posted-icon.svg");
    }
  }
}

.add-edit-replic-top-side-bitton-close {
  width: 72px;
  height: 60px;
  flex-shrink: 0;
  background: url($path + "close-icon.svg") $red no-repeat center;
  cursor: pointer;
  @include transition;

  &:hover {
    background-color: $redHover;
  }
}

.add-edit-replic-top-side-select-container {
  display: flex;
  width: 100%;
}

.add-edit-replic__main-data {
  padding: 36px 30px 33px 30px;
}

.add-edit-replic__main-data-header-bar {
  display: flex;
  margin-bottom: 18px;
}

.add-edit-replic__main-data-header-bar-cell {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $gray; /*padding-left:29px;*/
  width: 15%;
}

.add-edit-replic__main-data-unit {
  display: flex;
  margin-bottom: 29px;

  &:last-child {
    margin-bottom: 0;
  }
}

.add-edit-replic__main-data-unit-date {
  padding-top: 20px;
  width: 14%;
}

.add-edit-replic__main-data-unit-field-date {
  position: relative;
  width: 100px;
  border: none;
  background: transparent;
  border-bottom: 1px solid #e0dcff;
  padding-bottom: 20px;
  padding-left: 29px;

  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 20px;
    height: 20px;
    background: url($path + "calendar-icon.svg") no-repeat center;
  }

  input {
    width: 100px;
    border: none;
    background: transparent;
  }
}

.add-edit-replic__main-data-unit-area {
  width: 14%;
  margin-right: 35px;

  input {
    background: #ffffff;
    width: 100%;
    border: none;
    color: $blue;
    padding-left: 29px;
    height: 60px;
  }

  select {
    background: #ffffff;
    width: 100%;
    border: 1px solid #e0dcff;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    color: $blue;
    padding-left: 29px;
    height: 60px;
    @include appearance;
  }
}

.add-edit-replic__main-data-unit-message {
  width: 75.5%;
  position: relative;

  textarea {
    background: #ffffff;
    padding: 15px 18px 20px 18px;
    border: 1px solid #e0dcff;
    width: 100%;
    resize: none;
    height: 112px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #505d6f;

    &:focus {
      border-color: #f6b5c3;
    }
  }
}

.add-edit-replic__main-data-unit-message-type-mobile {
  position: absolute;
  display: none;
  left: 0;
  top: -23px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $gray;
}

.add-edit-replic__main-data-unit-url {
  width: 27%;
  padding-left: 156px;
  margin-right: 35px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $gray;
}

.add-edit-replic__main-data-unit-url-input {
  width: 75.5%;
  position: relative;

  input {
    background: #ffffff;
    border: 1px solid #e0dcff;
    height: 60px;
    padding: 16px 20px 16px 20px;
    width: 100%;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $darkGray;

    &:focus {
      border-color: #f6b5c3;
    }
  }
}

.add-edit-replic__main-data-unit-url-type {
  display: none;
  position: absolute;
  left: 0;
  top: -23px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $gray;
}

.add-edit-replic__additional-data {
  padding: 0px 34px 33px 34px;
}

.add-edit-replic__additional-data-content {
  background: $lightGray;
  padding: 30px 0 33px;
}

.add-edit-replic__additional-data-unit {
  display: flex;
  margin-bottom: 29px;
}

.add-edit-replic__additional-data-unit-left-side {
  width: 27%;
  padding-left: 156px;
  padding-top: 25px;
  margin-right: 35px;
}

.add-edit-replic__additional-data-unit-left-side-text,
.add-edit-replic__additional-data-unit-right-side-type-mobile {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $gray;

  span {
    margin-left: 9px;
    position: absolute;
    width: 17px;
    height: 17px;
    background: url($path1 + "Replica/icon-node-client.svg") no-repeat center / contain;
    @include transition;

    &[data-icon="agency"] {
      background: url($path1 + "Replica/icon-node-agent.svg") no-repeat center / contain;
    }
  }
}

.add-edit-replic__additional-data-unit-right-side {
  width: 75.5%;
  position: relative;
  padding-right: 97px;
}

.add-edit-replic__additional-data-unit-right-side-date {
  position: absolute;
  top: -26px;
  left: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $gray;
}

.add-edit-replic__additional-data-unit-right-side-content {
  position: relative;

  textarea {
    background: #ffffff;
    padding: 15px 18px 20px 18px;
    border: 1px solid #e0dcff;
    width: 100%;
    resize: none;
    height: 112px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #505d6f;

    &:focus {
      border-color: #f6b5c3;
    }
  }

  input {
    background: #ffffff;
    border: 1px solid #e0dcff;
    height: 60px;
    padding: 16px 20px 16px 20px;
    width: 100%;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $darkGray;

    &:focus {
      border-color: #f6b5c3;
    }
  }

  select {
    border: 1px solid #e0dcff;
    width: 100%;
    background: url($path + "arrow-selcet-micro.svg") #fff no-repeat right 25px center;
    padding-left: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $darkGray;
    height: 60px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;
    @include appearance;
  }
}

.add-edit-replic__additional-data-unit-right-side-type-mobile {
  position: absolute;
  display: none;
  left: 72px;
  top: -25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $gray;
}

.add-edit-replic__additional-data-unit-right-side-content-button-plus {
  position: absolute;
  top: 50%;
  margin-top: -10.5px;
  right: -48px;
  width: 21px;
  height: 21px;
  background: url($path + "plus-icon-mini-gray.svg") no-repeat center;
  cursor: pointer;

  &:after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url($path + "plus-icon-mini-red.svg") no-repeat center;
    @include transition;
  }

  &:hover:after {
    opacity: 1;
  }
}

.add-edit-replic__additional-data-unit-right-side-content-button-minus {
  position: absolute;
  top: 50%;
  margin-top: -10.5px;
  right: 13px;
  width: 21px;
  height: 21px;
  background: url($path + "minus-icon.svg") no-repeat center;
  cursor: pointer;

  &:after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url($path + "minus-icon-red.svg") no-repeat center;
    @include transition;
  }

  &:hover:after {
    opacity: 1;
  }
}

.add-edit-replic__additional-data-button {
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  button {
    display: block;
    width: 219px;
    text-align: center;
    text-decoration: none;
    height: 63px;
    font-weight: 600;
    font-size: 18px;
    line-height: 63px;
    text-align: center;
    color: #ffffff;
    @include transition;
  }
}

.add-edit-replic__additional-data-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 97px;
}

.add-edit-replic__additional-data-unit-right-side-client-comment {
  textarea {
    background: #ffffff;
    border: 2px solid #ffb4c5;
    height: 144px;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;
  }
}

.add-edit-replic__additional-data-unit-right-side-client-comment-comment {
  position: relative;
}

.add-edit-replic__additional-data-unit-right-side-client-comment-replica {
  padding-left: 77px;
  position: relative;
  margin-top: 29px;
}

.add-edit-replic__additional-data-unit-right-side-client-comment-replica-target {
  display: flex;
  position: absolute;
  left: 77px;
  top: -25px;
}

.add-edit-replic__additional-data-unit-right-side-client-comment-replica-target-text {
  font-size: 12px;
  line-height: 20px;
  color: $gray;
  margin-right: 12px;
}

.add-edit-replic__additional-data-unit-right-side-client-comment-replica-target-date {
  font-size: 12px;
  line-height: 20px;
  color: $blue;
}

.add-edit-replic__additional-data-unit-right-side-client-comment-replica-text {
  border: 1px solid #d0ddef;
  border-radius: 0px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a2a9b2;
  padding: 29px 24px 24px 24px;
}

.replic__remove-replic {
  padding: 0 33px;
  margin: 41px 0;
}

.replic__remove-replic-content {
  height: 63px;
  background: $purple;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.replic__remove-replic-content-text {
  padding-left: 124px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.replic__remove-replic-content-button {
  button {
    border: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 63px;
    color: #ffffff;
    display: block;
    width: 180px;
    height: 63px;
    text-align: center;
    text-decoration: none;
    @include transition;
  }
}

.replic__remove-replic-content-buttons {
  display: flex;
}

.snapjs-right .left-drawer,
.snapjs-left .right-drawer {
  display: none;
}

.snapjs-right .button-block-mobile {
  display: flex;
  //position: absolute;
  //right: -120px;
}

#analise-right .flatpickr-calendar.rangeMode.animate.multiMonth.inline .dayContainer {
  margin: 29px 0 42px 0;
  padding: 0;
  width: 200px;
}

#analise-right .js-flatpickr1.flatpickr-input {
  display: none;
}

#analise-right .flatpickr-calendar.inline {
  background: unset;
}

#analise-right .flatpickr-days {
  flex-direction: column;
  display: flex;
  margin-left: 28px;
}

#analise-right .flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange,
#analise-right .flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 50%;
  z-index: 1;
}

#analise-right {
  input {
    -webkit-appearance: none;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
}

.flatpickr-day.startRange {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.flatpickr-day.endRange {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.flatpickr-day.startRange.endRange {
  border-radius: 50% !important;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  background: #ffffff;
  color: #2e2382;
  border: 1px solid #e00c3c;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: none;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  color: #2e2382;
  background: #ffffff;
}

.flatpickr-day.inRange {
  box-shadow: none;
}

#analise-right .flatpickr-month {
  margin-left: 0px;
  //margin-left: 36px;

  .flatpickr-current-month {
    justify-content: flex-end;
    display: flex;
    margin-left: 6px;
  }
}

#analise-right .flatpickr-months {
  width: 300px;

  &:before {
    //width: 69%;
    width: 63%;
    margin-left: 53px;
  }
}

#analise-right .flatpickr-month:nth-child(3) {
  margin-left: 4px;
  width: 300px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 230px;
  z-index: 1000;
}

#analise-right .flatpickr-calendar.rangeMode.animate.multiMonth.inline .dayContainer {
  margin-top: 0px;
  margin-left: 20px;
  //width: 215px;
  //min-width: 215px;
  width: 200px;
  min-width: 200px;
}

#analise-right .flatpickr-rContainer {
  width: 280px;
}

#analise-right .flatpickr-weekdays {
  display: flex;
  margin-left: 47px;
  //width: 215px
  width: 200px;
}

#analise-right .flatpickr-weekdaycontainer {
  width: 215px;
  margin-bottom: 10px;
  color: #98a5b7;
}

#analise-right .flatpickr-weekday {
  color: #98a5b7;
}

#analise-right .flatpickr-weekdaycontainer:nth-child(2) {
  margin-left: 48px;
  //width: 215px;
  width: 200px;
  height: 14px;
  border-bottom: 1px solid #98a5b7;
  position: absolute;
  left: 0px;
  top: 268px;
  z-index: 1000;
}

#analise-right .flatpickr-calendar.rangeMode.animate.multiMonth.inline .dayContainer:nth-child(2) {
  margin-top: 49px;
  box-shadow: none;
}

#analise-right .flatpickr-day {
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}

#analise-right .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 17px;
  top: 33px;
  left: auto;
  justify-content: center;
  display: flex;
  transform: rotate(90deg);
  width: 28px;
  height: 28px;
}

#analise-right .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  right: 17px;
  left: auto;
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
  width: 28px;
  height: 28px;
}

#analise-right .flatpickr-months .flatpickr-prev-month svg,
#analise-right .flatpickr-months .flatpickr-next-month svg {
  width: 6px;
  height: 6px;
  padding: 0;
}

.unload {
  color: #e00c3c;
}

.unload:hover {
  color: #ffffff;
  background: #e00c3c;
}

.flatpickr-day.inRange.unload {
  color: #e00c3c;
}

.flatpickr-day.selected.unload.startRange {
  color: #ffffff;
}

.flatpickr-day.weekday:hover {
  background: #e00c3c;
  color: #ffffff;
}

#table-flatpickr .flatpickr-calendar {
  position: absolute;
  top: 70px;
  right: -43px;
  height: 280px;
  padding-left: 25px;
}

#table-flatpickr .flatpickr-calendar {
  position: absolute;
  top: 70px;
  right: -43px;
}

#analise-right-1280 .flatpickr-input {
  opacity: 0;
  pointer-events: none;
}

#analise-right-1280 .flatpickr-calendar {
  margin-right: 72px;
  width: 100%;
  background: unset;
}

#analise-right-1280 .flatpickr-days {
  width: 100%;
}

#analise-right-1280 .flatpickr-day {
  box-shadow: none;
}

#analise-right-1280 .flatpickr-day.inRange {
  box-shadow: none;
  background-color: #ffffff;
  color: #2e2382;
}

#analise-right-1280 .dayContainer {
  width: 100%;
  max-width: 50%;
}

#analise-right-1280 .flatpickr-day {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  //margin: 0 25px;
}

#analise-right-1280 .flatpickr-weekdays {
  display: flex;
  margin-left: 30px;
  width: 95%;
}

#analise-right-1280 .flatpickr-weekdaycontainer:nth-child(2) {
  margin-top: 2px;
  margin-left: 48px;
  padding-bottom: 1px;
  border-bottom: 1px solid #becbdc;
}

#analise-right-1280 .flatpickr-months:before {
  top: 55px;
  margin-left: 37px;
  width: 45%;
}

#analise-right-1280 .flatpickr-month {
  margin-left: 0px;

  .flatpickr-current-month {
    justify-content: flex-end;
    display: flex;
    margin-left: 67px;
  }
}

#analise-right-1280 .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: -30px;
  top: 33px;
  left: auto;
  justify-content: center;
  display: flex;
  transform: rotate(90deg);
  width: 28px;
  height: 28px;
}

#analise-right-1280 .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  right: -30px;
  left: auto;
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
  width: 28px;
  height: 28px;
}

#analise-right-1280 .dayContainer + .dayContainer {
  box-shadow: none;
}

#table-flatpickr .flatpickr-calendar {
  display: none;
  background: none;
  width: 650px !important;
}

#table-flatpickr .dayContainer + .dayContainer {
  box-shadow: none;
}

#table-flatpickr .flatpickr-weekdays {
  justify-content: flex-start;
  display: flex;
}

#table-flatpickr .flatpickr-weekdays .flatpickr-weekdaycontainer {
  width: 215px;
  border-bottom: 1px solid #98a5b7;
  max-width: 215px;
  font-size: 10px;
  line-height: 20px;
  color: #98a5b7;
}

#table-flatpickr .flatpickr-months:before {
  display: none;
}

#table-flatpickr .dayContainer {
  margin-top: 0px;
  min-width: 0;
  max-width: 214px;
  padding: 0px;
}

#table-flatpickr .dayContainer:nth-child(2) {
  margin-left: 77px;
  padding-right: 0;
}

#table-flatpickr .flatpickr-days {
  width: 668px !important;
}

#table-flatpickr .flatpickr-day {
  height: 30px;
  font-size: 12px;
  line-height: 30px;
}

#table-flatpickr .flatpickr-weekdaycontainer:nth-child(2) {
  margin-left: 76px;
}

#table-flatpickr .flatpickr-current-month {
  justify-content: flex-end;
  display: flex;
  padding-right: 66px;
  margin-left: 0;
}

#table-flatpickr .flatpickr-months .flatpickr-month {
  margin-left: 0;
}

#table-flatpickr .flatpickr-months .flatpickr-month:nth-child(2) {
  margin-left: 0;
}

#table-flatpickr .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  right: 43px;
  left: auto;
}

#table-flatpickr .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
  left: auto;
}

#table-flatpickr .flatpickr-months .flatpickr-month:nth-child(2) {
  padding-right: 77px !important;
}

.flatpickr-day {
  border: 1px solid transparent;
}

.flatpickr-day.today {
  border: 1px solid #e00c3c;
}

//#table-flatpickr .flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: #e00c3c;
  color: #fff;
  box-shadow: none;
}

.flatpickr-day:hover {
  background: #e00c3c !important;
  color: #fff !important;
}

#analise-right .flatpickr-current-month {
  pointer-events: none;
}

#table-flatpickr .flatpickr-day.unload {
  color: #c50631;
}

@keyframes OpenMenuBtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 1279px) and (max-width: 1480px) {
  #table-flatpickr .flatpickr-calendar {
    display: none;
    background: none;
    width: 650px !important;
    right: -40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .add-edit-replic__additional-data-buttons {
    padding-right: 0px;
  }
  //.search-and-settings__field-button-add {right:-32px;}
  .replic__replic-unit-ticket-item-left-side {
    padding-left: 55px;
  }
  .replic__replic-unit-ticket-screen-shot-add {
    padding-left: 95px;
  }

  .replic__filter {
    // overflow-x: hidden;

    .filter-slider {
      height: 60px;
      padding-top: 12px;

      .arrows {
        top: 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .replic {
    padding-top: 0;
  }
  .replic__h1 {
    font-size: 30px;
    line-height: 37px;
    padding-top: 17px;
  }
  .replic__top {
    margin-bottom: 60px;
  }
  .replic__wrap {
    padding: 0 13px;
    overflow: hidden;
  }
  .replic__counts {
    margin-left: 64px;
  }
  .replic__count-total {
    height: 58px;
    width: 210px;
    justify-content: center;
  }
  .replic__counts__text {
    font-size: 16px;
    line-height: 19px;
    position: relative;
    top: 4px;
  }
  .replic__counts__number {
    position: relative;
    top: 4px;
  }
  .replic__count-refused {
    height: 58px;
    width: 216px;
    justify-content: center;
  }
  .replic__filter {
    //overflow: hidden;
  }
  .replic__filter-unit {
    flex-shrink: 0;
  }
  .replic__filter-buttons-mobile {
    display: flex;
  }

  .search-and-settings {
    position: relative;
  }
  .search-and-settings__upload {
    width: 60px;
    padding-left: 0;
  }
  .search-and-settings__upload-text {
    display: none;
  }
  .search-and-settings__download {
    width: 60px;
    padding-left: 0;
  }

  span.search-and-settings__download-text {
    display: none;
  }
  .search-and-settings__add-edit-replic {
    width: 60px;
    padding-left: 0;
  }
  span.search-and-settings__add-edit-replic-text {
    display: none;
  }
  .search-and-settings__settings-button {
    &:after {
      width: 29px;
      height: 29px;
      margin: -14.5px 0 0 -14.5px;
    }

    &:before {
      width: 29px;
      height: 29px;
      margin: -14.5px 0 0 -14.5px;
    }
  }

  .add-edit-replic-top-side-name {
    width: 50%;
  }

  .add-edit-replic {
    .add-edit-replic-horizont-line {
      top: 165px;
    }

    &:after {
      top: 105px;
    }
  }

  .add-edit-replic__main-data-unit-url-type {
    display: block;
  }

  .replic__replic-unit-ticket-screen-shot-add {
    padding-left: 0px;
  }
  .replic__replic-unit-ticket-screen-shot-text {
    margin-right: 24px;
  }

  .replic__replic-unit-replic-note {
    margin-top: 30px;
  }

  .search-and-settings__field-type {
    display: none;
  }
  .search-and-settings__fields-header-bar {
    display: none;
  }
  .search-and-settings__fields-header-bar-mobile {
    display: flex;
  }
  .search-and-settings__field-checkbox {
    margin-right: 27px;
  }

  .search-and-settings__field-value {
    padding-right: 56px;
  }
  .search-and-settings__field-value-type-mobile {
    display: block;
  }
  .search-and-settings__field-list-item {
    margin-top: 24px;
    padding-left: 18px;
  }
  .search-and-settings__contain-block-container {
    padding: 34px 30px 32px 30px;
  }
  .search-and-settings__top-select {
    select {
      background: url($path + "arrow-select-correct-purple.svg") #fff no-repeat right 10px center;
    }
  }
  .search-and-settings__field-button-add {
    right: -38px;
  }
  .search-and-settings__field-button-remove {
    right: 39px;
  }

  .search-and-settings__fields-manage-fields {
    width: 100%;
    flex-shrink: 0;

    select {
      width: 100%;
      background: url($path + "arrow-select-correct-purple.svg") #fff no-repeat right 10px center;
    }
  }

  .search-and-settings__fields-bottom-side_additional-top-padding {
    padding-top: 0;
  }

  .search-and-settings__fields-button {
    width: 50%;
    margin-right: 0px;

    a {
      width: 100%;
    }
  }
  .search-and-settings__fields-buttons {
    margin: 27px 0 0 0;
    width: 100%;
  }
  .search-and-settings__fields-add-button {
    flex-shrink: 0;
  }
  .search-and-settings__fields-bottom-side {
    margin-top: 0;
    padding-right: 56px;
    display: flex;
    flex-direction: column;
  }
  .replic__replic-unit {
    padding-left: 18px;
    padding-right: 0px;
    padding-top: 25px;
  }
  .replic__replic-unit-date {
    position: absolute;
    left: 19px;
    bottom: 24px;
    font-size: 14px;
    line-height: 17px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: -24px;
      width: 20px;
      height: 20px;
      background: url($path + "calendar-icon-gray.svg") no-repeat center / contain;
    }
  }

  .replic__replic-unit-area {
    display: none;
  }
  .replic__replic-unit-status {
    width: 22.6%;
    display: flex;
    flex-direction: column;
    z-index: 0;
  }
  .replic__replic-unit-replic-content {
    width: 63%;
    padding-right: 28px;
  }
  .replic__replic-unit-status-text {
    position: static;
    margin-top: -25px;
    width: 100%;
    right: 0;
    left: 0px;
    height: 100%;
    padding-left: 18px;
    display: flex;
    align-items: center;

    span {
      margin: 0;
      padding-left: 0;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      transform: translateY(7px);

      &:before {
        position: relative;
        top: 0;
        display: block;
        margin-bottom: 6px;
      }
    }
  }
  .replic__replic-unit-replic-link {
    margin-top: 27px;
  }
  .replic__replic-unit-status-botton-agreement {
    position: static;
    width: 100%;
    margin-bottom: -26px;
    top: auto;
    right: 0px; /*height:60.86%;*/
    height: 113px;
    opacity: 1;
    visibility: visible;
    padding-left: 18px;
    justify-content: flex-start;
    align-items: center;
    background: #43398f;
  }
  .replic__replic-unit-status-botton-agreement-text {
    transform: translate(0, 0);
    font-size: 14px;
    line-height: 17px;

    &:before {
      position: relative;
      top: 0px;
      display: block;
      margin-bottom: 5px;
    }
  }
  .replic__replic-unit-checkbox {
    width: 93px;

    label {
      width: 30px;
      height: 30px;

      &:before {
        width: 28px;
        height: 28px;
      }
    }
  }

  .replic__replic-unit {
    &:after {
      z-index: 1;
      display: none;
    }
  }

  .replic__replic-unit_open {
    .replic__replic-unit-button-close-mobile {
      display: block;
      top: -25px;
    }
  }

  .replic__replic-unit[data-status="created"] .replic__replic-unit-status-text {
    background: #ff7400;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "created-icon-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="waiting-agreement"] .replic__replic-unit-status-text {
    background: $blue;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "waiting-agreement-icon-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="refused"] .replic__replic-unit-status-text {
    background: $red;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "refused-icon-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="for-posting"] .replic__replic-unit-status-text {
    background: $green;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "for-posting-icon-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="under-moderation"] .replic__replic-unit-status-text {
    background: $lightBlue;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "under-moderation-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="posted"] .replic__replic-unit-status-text {
    background: $gray;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "posted-icon-white.svg");
      }
    }
  }

  .replic__replic-header-bar-add-button {
    width: 30px;
    height: 30px;
  }

  .replic__replic-unit-ticket {
    margin: 53px 30px 7px 12px;
  }
  .replic__replic-unit-ticket-item {
    padding: 43px 10px 0; /*margin-top:43px;*/
    margin-top: 0px;
  }
  .replic__replic-unit-ticket-item-left-side {
    display: none;
  }
  .replic__replic-unit-ticket-item-right-side {
    width: 100%;
    padding-right: 0;
  }
  .replic__replic-unit-ticket-client-comment {
    padding-left: 73px;
    padding-bottom: 15px;
  }
  .replic__replic-unit-ticket-client-comment-replic {
    &:before {
      left: -50px;
    }
  }
  .replic__replic-unit-ticket-client-comment-comment {
    margin-top: 62px;
  }

  .replic__remove-replic {
    padding: 0;
    margin: 16px 0px;
  }
  .replic__remove-replic-content-button {
    button {
      width: 85px;
    }
  }
  .replic__remove-replic-content-text {
    padding-left: 61px;
  }
  .replic__replic-unit-ticket-write-client-comment-button {
    width: 65%;
    margin-right: 0;

    &:last-child {
      width: 35%;
    }

    &.replic__replic-unit-ticket-write-client-comment-button_cancel button {
      width: 100%;
      font-size: 16px;
    }

    &.replic__replic-unit-ticket-write-client-comment-button_public button {
      width: 100%;
    }
  }

  .replic__replic-unit-ticket-write-client-comment {
    padding-bottom: 0;
  }
  .replic__replic-unit-ticket-write-client-comment-type-mobile {
    display: block;
  }
  .replic__replic-unit-manage-buttons {
    padding: 0;
    width: 102%;
    margin-top: 26px;
    margin-left: -18px;
  }
  .replic__replic-unit-manage-button {
    margin: 0;
    width: 100%;

    button {
      width: 100%;
      font-size: 16px;
    }
  }

  .add-edit-replic-top-side {
    flex-wrap: wrap;
  }
  .add-edit-replic-top-side-bitton-close {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .add-edit-replic-top-side-select-unit {
    width: 50%;

    &:last-child {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;

      select {
        border-bottom: none;

        &[name="STATUS"] {
          width: 100%;
        }
      }
    }
  }

  .add-edit-replic-top-side-select-unit-text {
    border-bottom: none;
  }

  .add-edit-replic__additional-data-unit {
  }
  .add-edit-replic__additional-data-unit-left-side {
    display: none;
  }
  .add-edit-replic__additional-data-unit-right-side {
    width: 100%;
    padding-right: 56px;
  }

  .add-edit-replic__main-data {
    padding: 0 40px 26px;
  }
  .add-edit-replic__main-data-header-bar {
    display: none;
  }
  .add-edit-replic__main-data-unit {
    flex-direction: column;
    margin-bottom: 38px;
  }
  .add-edit-replic__main-data-unit-area {
    display: none;
  }
  .add-edit-replic__main-data-unit-message {
    width: 100%;

    textarea {
      height: 163px;
    }
  }
  .add-edit-replic__additional-data-content {
    padding-left: 10px;
  }
  .add-edit-replic__main-data-unit-date {
    margin-bottom: 38px;
  }
  .add-edit-replic__main-data-unit-field-date {
    input {
      padding-bottom: 10px;
    }
  }
  .add-edit-replic__main-data-unit-url-input {
    width: 100%;
  }

  .add-edit-replic__main-data-unit-url {
    display: none;
  }
  .add-edit-replic__additional-data-content {
    padding-top: 36px;
  }
  .add-edit-replic__additional-data-unit-right-side-content {
    textarea {
      height: 167px;
    }
  }
  .add-edit-replic__additional-data-unit {
    margin-bottom: 45px;

    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
  .add-edit-replic__additional-data-unit-right-side-content-button-plus {
    right: -40px;
  }
  //.add-edit-replic__additional-data-unit-right-side-content-button-minus {right:-40px;}

  .add-edit-replic__main-data-unit-message-type-mobile {
    display: block;
  }

  .add-edit-replic__additional-data {
    padding: 0 30px 37px;
  }
  .add-edit-replic__additional-data-unit-right-side-type-mobile {
    display: block;
    left: 0px;
  }
  .add-edit-replic__additional-data-buttons {
    padding-right: 0px;
    width: auto;
    margin-left: -10px;
    margin-top: 26px;
  }
  .add-edit-replic__additional-data-button {
    margin: 0;

    a {
      width: 100%;
      font-size: 16px;
    }
  }
  .add-edit-replic__additional-data-content {
    padding-bottom: 0px;
  }

  .add-edit-replic__additional-data-unit-right-side-client-comment-replica {
    padding-left: 20px;
    margin-top: 41px;
  }
  .add-edit-replic__additional-data-unit-right-side-client-comment-replica-target {
    left: 20px;
  }

  .replic__replic-unit-ticket-button {
    button {
      font-size: 16px;
    }
  }

  .replic__filter-dafault {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    margin-top: -5px;
  }

  .replic__filter-unit-input-container {
    height: 47px;

    &:after {
      display: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .replic {
    padding-top: 0;
  }

  .brightless {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;

    &[data-active="Y"] {
      z-index: 100;
    }
  }

  .replic__h1 {
    font-size: 24px;
    line-height: 29px;
    position: absolute;
    bottom: -61px;
    left: 0px;
  }
  .replic__top {
    margin-bottom: 80px; /*padding-left:59px;*/
    position: relative;
    height: 60px;
    order: 0;
  }
  .replic__wrap {
    padding: 0 10px;
  }
  .replic__counts {
    left: 47px;
    right: 59px;
    width: auto;
    margin-left: 0;
    position: absolute;
  }
  .replic__count-total {
    height: 60px;
    width: 50%;
    position: relative;
    padding-left: 38px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -11px;
      left: 17px;
      width: 22px;
      height: 22px;
      background: url($path + "count-total-icon.svg") no-repeat center / contain;
    }
  }
  .replic__counts__text {
    display: none;
  }
  .replic__counts__number {
    position: relative;
    top: 2px;
  }
  .replic__count-refused {
    height: 60px;
    width: 50%;
    position: relative;
    padding-left: 38px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -11px;
      left: 17px;
      width: 22px;
      height: 22px;
      background: url($path + "count-refused-icon.svg") no-repeat center / contain;
    }
  }
  .replic__filter {
    /*overflow:auto;*/
    display: none;
    flex-direction: column;
    background: #fff;
    padding: 73px 18px 0px;
    order: 2;
  }
  .replic__filter-unit {
    flex-shrink: 0;
    margin-bottom: 18px;
    justify-content: space-between;

    &:last-child {
      margin-right: 0;
    }

    select {
      font-weight: 500;
      width: 100%;
      font-size: 16px;
      line-height: 19px;
      color: $gray;
      background-color: transparent;
      border: none;
      @include appearance;
    }

    input {
      width: auto;
    }
  }

  .add-edit-replic__main-data-unit-url-type {
    display: block;
  }

  .replic__replic-unit-ticket-screen-shot-add {
    padding-left: 0px;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
  }
  .replic__replic-unit-ticket-screen-shot-text {
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 17px;
  }
  .replic__replic-unit-ticket-screen-shot-input {
    input {
      padding-left: 20px;
    }
  }

  .replic__replic-container {
    order: 3;
  }

  .replic__filter-unit-input-container {
    width: 135px;
    padding-top: 15px;
    padding-bottom: 10px;

    &:after {
      top: 18px;
    }
  }

  .replic__filter-dafault {
    position: absolute;
    width: auto;
    top: 15px;
    right: 0;
    background: none;
    display: flex;
    align-items: center;

    span {
      display: inline-block;
    }

    &:after {
      content: "";
      position: relative;
      top: 1px;
      display: inline-block;
      width: 60px;
      height: 47px;
      background: url($path + "cross-red.svg") no-repeat center;
    }
  }

  .replic__filter_mobile-open {
    display: flex;
    position: absolute;
    top: 140px;
    width: 94%;
    padding: 73px 10px 0px;
    z-index: 100;
  }

  .replic__filter-buttons-mobile {
    display: none;
  }

  .search-and-settings {
    position: relative;
    margin-bottom: 0px;
  }
  .search-and-settings__settings {
    width: 100%;
    justify-content: center;
  }
  .search-and-settings__upload {
    width: 58px;
    padding-left: 0;
    flex-shrink: 0;
    display: none;

    &:before {
      left: 15px;
    }
  }
  .search-and-settings__upload-text {
    display: none;
  }
  .search-and-settings__download {
    width: 58px;
    padding-left: 0;
    flex-shrink: 0;

    &:before {
      left: 15px;
    }
  }
  span.search-and-settings__download-text {
    display: none;
  }
  .search-and-settings__add-edit-replic {
    width: 58px;
    padding-left: 0;
    flex-shrink: 0;

    &:before {
      left: 15px;
    }
  }
  span.search-and-settings__add-edit-replic-text {
    display: none;
  }
  .search-and-settings__settings-button {
    flex-shrink: 0;
    width: 58px;
    padding-left: 58px;

    &:after {
      width: 29px;
      height: 29px;
      margin: -14.5px 0 0 -14.5px;
    }

    &:before {
      width: 29px;
      height: 29px;
      margin: -14.5px 0 0 -14.5px;
    }
  }
  .search-and-settings__settings-button_back {
    display: block;
  }

  .search-and-settings__search-mobile:before {
    left: 14px;
  }

  .search-and-settings__top-side {
    flex-direction: column-reverse;
  }

  .search-and-settings__tune-mobile {
    display: block;
    width: 58px;
  }
  .search-and-settings__search-mobile {
    display: block;
  }

  .search-and-settings__contain-block {
  }

  .search-and-settings__search {
    display: none;
    margin-top: 17px;

    input {
      &[type="text"] {
        &::-webkit-input-placeholder {
          opacity: 0;
        }

        &:-ms-input-placeholder {
          opacity: 0;
        }

        &::-moz-placeholder {
          opacity: 0;
        }

        &:-moz-placeholder {
          opacity: 0;
        }
      }
    }
  }

  .search-and-settings__search_open-mobile {
    display: block;
    position: absolute;
  }

  .search-and-settings__field-type {
    display: none;
  }

  .search-and-settings__fields-header-bar {
    display: none;
  }

  .search-and-settings__fields-header-bar-mobile {
    display: flex;
  }

  .search-and-settings__field-checkbox {
    margin-right: 0px;
    padding-left: 18px;
    padding-top: 0px;
    position: relative;
    left: 0px;
    top: 0px;

    label {
      width: 60px;
      height: 60px;
      background: url($path + "tick-white.svg") #d0ddef no-repeat center;
      border: none;

      &:before {
        width: 60px;
        height: 60px;
        background: url($path + "tick-white.svg") $green no-repeat center;
      }
    }
  }

  .search-and-settings__field-input-text {
    input {
      border-radius: 0px 10px 10px 0px;
    }
  }

  .search-and-settings__field-value {
    padding-right: 45px;

    .search-and-settings__field-value_wrapper-btn {
      margin: 30px 0px 30px 0px;

      button {
        right: -32px;
      }
    }
  }

  .search-and-settings__field-value-type-mobile {
    display: block;
    left: 6px;
  }

  .search-and-settings__field-list-item {
    margin-top: 24px;
    padding-left: 0px;
  }

  .search-and-settings__contain-block-container {
    padding: 34px 10px 32px 10px;
    background: #fff;
    margin: 0 -10px;
  }

  .search-and-settings__contain-block-container {
    padding: 34px 10px 32px 10px;
    background: #fff;
    margin: 0 0px;
  }

  .search-and-settings__top-select {
    select {
      background: url($path + "arrow-select-correct-purple.svg") #fff no-repeat right 10px center;
    }
  }

  .search-and-settings__field-button-add {
    right: -32px;
  }

  .search-and-settings__field-button-remove {
    right: 13px;
  }

  .search-and-settings__fields-manage-fields {
    display: none;
    left: 18px;
    top: 0;
    right: 18px;
    margin-top: 0px;
    flex-direction: row-reverse;

    select {
      width: 100%;
      background: url($path + "arrow-selcet-pico.svg") #fff no-repeat right 10px center;
      border-left: 0px;
    }
  }

  .search-and-settings__fields-bottom-side.search-and-settings__fields-bottom-side_additional-top-padding
    > .search-and-settings__fields-manage-fields_show-mobile {
    margin-top: 38px;
  }

  .search-and-settings__fields-unit {
    margin-bottom: 25px;
  }

  .search-and-settings__fields-button {
    width: 50%;
    margin-right: 0px;

    button {
      width: 100%;
      font-size: 16px;
    }
  }

  .search-and-settings__fields-buttons {
    margin-left: 0px;
    width: 100%;
  }

  .search-and-settings__fields-add-button {
    border: none;
    flex-shrink: 0;
    background: url($path + "plus-icon-white.svg") $blue no-repeat center;
  }

  .search-and-settings__fields-bottom-side {
    flex-direction: column-reverse;
    padding-right: 18px;
    margin-top: 28px;
    padding-left: 18px;
    position: relative;
  }

  .search-and-settings__fields-trigger {
    display: flex;
  }

  .search-and-settings__fields-select-container {
    position: relative;
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    margin-right: 36px;
  }

  .search-and-settings__fields-select-container_close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 62px;
    background-color: #d0ddef;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background: url($path + "cross-red.svg") no-repeat center center / cover;
    }
  }

  .search-and-settings__fields-manage-fields_show-mobile {
    display: flex;
    position: absolute;
  }

  .replic__replic-unit {
    padding: 78px 18px 27px 18px;

    &[data-active="Y"] {
      order: 3;
    }

    &:after {
      display: none;
    }
  }

  .replic__replic-unit-wrap {
    position: static;
    flex-direction: column;
  }

  .replic__replic-header-bar {
    padding-left: 0px;
    padding-bottom: 23px;
  }

  .replic__replic-header-bar-cell {
    display: none;

    &:first-child {
      display: block;
    }
  }

  .replic__replic-header-bar-add-button {
    width: 60px;
    height: 60px;
  }

  .replic__replic-unit-date {
    position: relative;
    left: 0px;
    bottom: 0px;
    top: 4px;
    font-size: 14px;
    line-height: 17px;
    padding-left: 33px;
    margin-bottom: 18px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 20px;
      height: 20px;
      background: url($path + "calendar-icon-gray.svg") no-repeat center / contain;
    }
  }

  .replic__replic-unit-area {
    display: none;
  }

  .replic__replic-unit-status {
    position: absolute;
    left: 60px;
    right: 0px;
    top: 0px;
    height: 60px;
    width: auto;
    display: flex;
    flex-direction: row;
    z-index: 0;
  }

  .replic__replic-unit-replic-content {
    width: 100%;
    padding-right: 10px;
    margin-right: 0px;
  }

  .replic__replic-unit-status-text {
    position: static;
    margin-top: 0px;
    height: 60px;
    width: 100%;
    right: 0;
    left: 0px;
    height: 100%;
    padding-left: 15px;
    display: flex;
    align-items: center;

    span {
      padding-left: 0;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      padding-left: 31px;

      &:before {
        position: absolute; /*top:-4px;*/
        top: 50%;
        left: 0;
        display: block; /*margin-bottom:6px;*/
        margin-bottom: 0;
        margin-top: -12px;
      }

      br {
        display: none;
      }
    }
  }

  .replic__replic-unit-replic-note-title {
    left: auto;
    top: auto;
    position: relative;
    transform: none;
    margin-bottom: 10px;
  }

  .replic__replic-unit-replic-note-text {
    margin-top: 0;
    width: 100%;
  }

  .replic__replic-unit_open .replic__replic-unit-button-close-mobile {
    background: url($path + "arrow-button-close-replic.svg") #43398f no-repeat center;
  }

  .replic__replic-unit-replic-link {
    margin-top: 27px;
  }

  .replic__replic-unit-status-botton-agreement {
    position: static;
    width: 60px;
    flex-shrink: 0;
    margin-bottom: 0px;
    top: auto;
    right: 0px;
    height: 60px;
    opacity: 1;
    visibility: visible;
    padding-left: 18px;
    justify-content: flex-start;
    align-items: center;
    background: url($path + "arrow-plain-down.svg") #43398f no-repeat center;
  }

  .replic__replic-unit-status-botton-agreement-text {
    display: none;
  }

  .replic__replic-unit-checkbox {
    margin-right: 0px;
    padding-left: 0px;
    width: 60px;
    padding-top: 0px;
    position: absolute;
    left: 0px;
    top: 0px;

    label {
      width: 60px;
      height: 60px;
      background: url($path + "tick-white.svg") #d0ddef no-repeat center;
      border: none;

      &:before {
        width: 60px;
        height: 60px;
        background: url($path + "tick-white.svg") $green no-repeat center;
      }
    }
  }

  .replic__replic-unit {
    &:after {
      z-index: 1;
    }
  }

  .replic__replic-unit_open {
    .replic__replic-unit-button-close-mobile {
      display: block;
    }
  }

  .replic__replic-unit-status-text {
    span {
      margin: 0;
    }
  }

  .replic__replic-unit[data-status="created"] .replic__replic-unit-status-text {
    background: #ff7400;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "created-icon-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="waiting-agreement"] .replic__replic-unit-status-text {
    background: $blue;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "waiting-agreement-icon-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="refused"] .replic__replic-unit-status-text {
    background: $red;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "refused-icon-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="for-posting"] .replic__replic-unit-status-text {
    background: $green;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "for-posting-icon-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="under-moderation"] .replic__replic-unit-status-text {
    background: $lightBlue;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "under-moderation-white.svg");
      }
    }
  }

  .replic__replic-unit[data-status="posted"] .replic__replic-unit-status-text {
    background: $gray;

    span {
      color: #fff;

      &:before {
        background-image: url($path + "posted-icon-white.svg");
      }
    }
  }

  .replic__replic-unit-ticket {
    margin: 53px 0px 7px;
  }

  .replic__replic-unit-ticket-item {
    padding: 43px 10px 0; /*margin-top:43px;*/
    margin-top: 0px;
  }

  .replic__replic-unit-ticket-item-left-side {
    display: none;
  }

  .replic__replic-unit-ticket-item-right-side {
    width: 100%;
    padding-right: 0;
  }

  .replic__replic-unit-ticket-client-comment {
    padding-left: 14px;
    padding-bottom: 15px;
    padding-top: 67px;
    padding-right: 15px;
  }

  .replic__replic-unit-ticket-client-comment-replic {
    &:before {
      left: 1px;
      top: -34px;
    }
  }

  .replic__replic-unit-ticket-buttons {
    flex-direction: column;
    margin: 0 -18px;
    width: auto;
    background: #fff;
    padding-bottom: 17px;
  }

  .replic__replic-unit-ticket-button {
    width: 100%;
  }

  .replic__replic-unit-ticket-client-comment-comment {
    margin-top: 67px;
  }

  .replic__remove-replic {
    padding: 0;
    margin: 24px 18px;
  }

  .replic__remove-replic-content-button {
    button {
      width: 85px;
    }
  }

  .replic__remove-replic-content {
    flex-wrap: wrap;
    height: auto;
    background: #fff;
  }

  .replic__remove-replic-content-text {
    padding-left: 61px;
    width: 100%;
    background: $purple;
    padding: 27px 25px 27px;
    font-size: 16px;
    line-height: 20px;
  }

  .replic__remove-replic-content-button {
    width: 50%;

    button {
      width: 100%;
    }
  }

  .replic__remove-replic-content-buttons {
    width: 100%;
  }

  .replic__replic-unit-ticket-write-client-comment-button {
    width: 60%;
    margin-right: 0;

    &:last-child {
      width: 40%;
    }

    &.replic__replic-unit-ticket-write-client-comment-button_cancel button {
      width: 100%;
      font-size: 16px;
    }

    &.replic__replic-unit-ticket-write-client-comment-button_public button {
      width: 100%;
      font-size: 16px;
    }
  }

  .replic__replic-unit-ticket-write-client-comment {
    padding-bottom: 0;
  }

  .replic__replic-unit-ticket-write-client-comment-type-mobile {
    display: block;
    left: 0px;
  }

  .replic__replic-unit-manage-buttons {
    padding: 0;
    width: calc(100% + 36px);
    margin-top: 26px;
    margin-left: -18px;
    margin-right: -18px;
    flex-direction: column;
  }

  .replic__replic-unit-manage-button {
    margin: 0;
    width: 100%;

    button {
      width: 100%;
      font-size: 16px;
    }
  }

  .add-edit-replic {
    position: absolute;
    top: 200px;
    z-index: 10;
    width: 95%;
    z-index: 1000;

    &[data-container="children"] {
      position: relative;
      top: 130px;
      margin: -180px 0 140px 0;
      width: 100%;
    }

    &:after {
      width: 3px;
    }
  }

  .add-edit-replic-top-side {
    flex-wrap: wrap;
    position: relative;
  }

  .add-edit-replic-top-side-bitton-close {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .add-edit-replic-top-side-select-unit {
    width: 33.333333%;

    &:last-child {
      position: absolute;
      top: 0;
      left: 309px;

      select {
        border-bottom: none;

        &[name="STATUS"] {
          width: 286px;
        }
      }
    }
  }

  .add-edit-replic-top-side-select-unit-text {
    border-left: 1px solid #e0dcff; /* border-top: none;*/
  }

  .add-edit-replic-top-side-name {
    padding-left: 54px;
    width: 100%;
  }

  .add-edit-replic-top-side-bitton-close {
    width: 60px;
  }

  .add-edit-replic-top-side-select-container {
    flex-direction: column;
  }

  .add-edit-replic-top-side-select-unit {
    width: 100%;

    select {
      border: 1px solid #e0dcff;
      border-top: none;
      padding-left: 25px;
    }

    &:last-child {
      position: static;
      width: 100%;

      select {
        border-bottom: none;
        border: 1px solid #e0dcff;

        &[name="STATUS"] {
          width: 100%;
        }
      }
    }
  }

  .add-edit-replic__additional-data-unit {
  }

  .add-edit-replic__additional-data-unit-left-side {
    display: none;
  }

  .add-edit-replic__additional-data-unit-right-side {
    width: 100%;
    padding-right: 10px;
  }

  .add-edit-replic__additional-data-unit-right-side-content-button-minus {
    right: 35px;
    top: -18px;
  }

  .add-edit-replic__main-data {
    padding: 26px 18px 26px;
  }

  .add-edit-replic__main-data-header-bar {
    display: none;
  }

  .add-edit-replic__main-data-unit {
    flex-direction: column;
    margin-bottom: 38px;
  }

  .add-edit-replic__main-data-unit-area {
    display: none;
  }

  .add-edit-replic__main-data-unit-message {
    width: 100%;

    textarea {
      height: 163px;
    }
  }

  .add-edit-replic__additional-data-content {
    padding-left: 10px;
  }

  .add-edit-replic__main-data-unit-date {
    margin-bottom: 38px;
  }

  .add-edit-replic__main-data-unit-field-date {
    input {
      padding-bottom: 10px;
    }
  }

  .add-edit-replic__main-data-unit-url-input {
    width: 100%;
  }

  .add-edit-replic__main-data-unit-url {
    display: none;
  }

  .add-edit-replic__additional-data-content {
    padding-top: 36px;
  }

  .add-edit-replic__additional-data-unit-right-side-content {
    textarea {
      height: 167px;
    }
  }

  .add-edit-replic__additional-data-unit {
    margin-bottom: 45px;

    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  .add-edit-replic__additional-data-unit-right-side-content-button-plus {
    right: 0px;
    top: -18px;
  }

  //.add-edit-replic__additional-data-unit-right-side-content-button-minus {right:0px; top:-18px;}

  .add-edit-replic__main-data-unit-message-type-mobile {
    display: block;
  }

  .add-edit-replic__additional-data {
    padding: 0 18px 21px;
  }

  .add-edit-replic__additional-data-unit-right-side-type-mobile {
    display: block;
    left: 0;
  }

  .add-edit-replic__additional-data-buttons {
    padding-right: 0;
    width: auto;
    margin-top: 26px;
    flex-direction: column;
  }

  .add-edit-replic__additional-data-button {
    margin: 0;

    button {
      width: 100%;
      font-size: 16px;
    }
  }

  .add-edit-replic__additional-data-content {
    padding: 0;
  }

  .add-edit-replic__additional-data-unit-right-side-client-comment-replica {
    padding-left: 14px;
    margin-top: 41px;
  }

  .add-edit-replic__additional-data-unit-right-side-client-comment-replica-target {
    left: 14px;
  }

  .replic__replic-unit-ticket-button {
    button {
      font-size: 16px;
    }
  }
}

/*===============================================================
  Компонент "Реплики" Модификаторы
  ===============================================================*/

.replic__remove-replic-content-button_red {
  button {
    background: $red;

    &:hover {
      background: $redHover;
    }
  }
}

.search-and-settings__title-bar_upload {
  background: $darkGray;

  &:before {
    background-image: url($path + "upload-icon.svg");
    background-size: contain;
  }
}

.replic__replic-unit-replic-note_last-comment {
  .replic__replic-unit-replic-note-title {
    color: $red;
  }

  .replic__replic-unit-replic-note-text {
    padding-left: 20px;
    position: relative;
    color: $red;

    &:before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      background: url($path + "refused-icon.svg") no-repeat center;
      left: 16px;
      top: 20px;
      display: none;
    }
  }
}

.add-edit-replic__additional-data-unit-right-side-type-mobile_red {
  color: $red;
}

//.replic__replic-unit_open {
//  .replic__replic-unit-date {
//  }
//}

.replic__remove-replic-content-button_gray {
  button {
    background: $gray;

    &:hover {
      background: $grayHover;
    }
  }
}

.search-and-settings_bottom-mode {
  margin-top: 11px;
  margin-bottom: 7px;

  .search-and-settings__search {
    border: 1px solid #e0dcff;
  }
}

.search-and-settings__settings_company-mode {
  height: 60px;

  .search-and-settings__upload {
    font-size: 24px;
    text-align: center;
    padding-left: 0;
    width: 219px;
    background: $red;

    &:before {
      display: none;
    }
  }

  .search-and-settings__download {
    font-size: 24px;
    text-align: center;
    padding-left: 0;
    width: 219px;
    background: $red;

    &:before {
      display: none;
    }
  }

  .search-and-settings__add-edit-replic {
    font-size: 24px;
    text-align: center;
    padding-left: 0;
    width: 305px;
    background: $gray;

    &:before {
      display: none;
    }
  }

  .search-and-settings__settings-button {
    width: 72px;
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.search-and-settings__upload_open {
  width: 60px;
  position: absolute;
  z-index: 300;
  right: 0;

  .search-and-settings__upload-text {
    display: none;
  }

  &:before {
    width: 30px;
    height: 30px;
    left: 15px;
    margin-top: -15px;
    background-image: url($path + "close-icon.svg");
  }
}

.replic__replic-unit-ticket-client-comment-type-mobile_red {
  color: $red;
}

.search-and-settings_margin-bottom-0px {
  margin-bottom: 0px;
}

.search-and-settings__fields-cell_margin-right-161px {
  margin-right: 161px;
}

.replic__replic-header-bar-cell_width-126px {
  width: 82px;
  margin-right: 45px;
}

.replic__replic-header-bar-cell_width-201px {
  width: 82px;
  margin-right: 120px;
}

.replic__replic-header-bar-cell_width-229px {
  width: 229px;
}

.replic__replic-header-bar-cell_width-782px {
  width: 52%;
}

.search-and-settings__fields-button_blue {
  button {
    width: 100%;
    display: inline-block;
    height: 63px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    line-height: 63px;
    text-align: center;
    color: #ffffff;
    background: $blue;
    border: none;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

    &:hover {
      background: $blueHover;
    }
  }
}

.search-and-settings__field-checkbox_padding-top-0px {
  padding-top: 0px;
}

.search-and-settings__field-type_padding-top-0px {
  padding-top: 0px;
}

.search-and-settings__settings-button_add-mode {
  &:before {
    background-image: url($path + "plus-icon-white.svg");
    width: 33px;
    height: 33px;
    margin: -16.5px 0 0 -16.5px;
  }
}

.replic__replic-unit-ticket-button_red {
  button {
    background: $red;

    &:hover {
      background: $redHover;
    }
  }
}

.replic__replic-unit-ticket-button_green {
  button {
    background: $green;

    &:hover {
      background: $greenHover;
    }
  }
}

.replic__replic-unit-ticket-button_yellow {
  button {
    background: $yellow;

    &:hover {
      background: $yellowHover;
    }
  }
}

.replic__replic-unit-ticket-button_purple {
  button {
    background: $purple;

    &:hover {
      background: $purpleHover;
    }
  }
}

.replic__replic-unit-ticket-item-right-side_center {
  padding-top: 0;
  display: flex;
  align-items: center;
}

.replic__replic-unit-ticket-item-left-side-text_red {
  color: $red;
}

.replic__replic-unit-ticket-item-left-side-text_green {
  color: $green;
}

.replic__replic-unit-manage-button_gray {
  button {
    width: 100%;
    background: $gray;

    &:hover {
      background: $grayHover;
    }
  }
}

.replic__replic-unit-manage-button_light-blue {
  button {
    width: 100%;
    background: $lightBlue;

    &:hover {
      background: $lightBlueHover;
    }
  }
}

.replic__replic-unit-manage-button_blue {
  button {
    width: 100%;
    background: $blue;

    &:hover {
      background: $blueHover;
    }
  }
}

.add-edit-replic__main-data-header-bar-cell_width_201px {
  width: 15%;
}

.add-edit-replic__main-data-header-bar-cell_width_155px {
  width: 15%;
}

.add-edit-replic__additional-data-unit-right-side-content-button-minus_external {
  right: -48px;
}

//.add-edit-replic__additional-data-unit-right-side-content-button-minus_red {background-image:url($path + "minus-icon-red.svg");}
.add-edit-replic__additional-data-button_blue {
  button {
    display: block;
    width: 219px;
    border: none;
    text-align: center;
    text-decoration: none;
    height: 63px;
    font-weight: 600;
    font-size: 18px;
    line-height: 63px;
    background: $blue;
    text-align: center;
    color: #ffffff;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

    &:hover {
      background: $blueHover;
    }
  }
}

.add-edit-replic__additional-data-button_red {
  button {
    border: none;
    background: $red;
    width: 333px;
    display: block;
    text-align: center;
    text-decoration: none;
    height: 63px;
    font-weight: 600;
    font-size: 18px;
    line-height: 63px;
    text-align: center;
    color: #ffffff;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

    &:hover {
      background: $redHover;
    }
  }
}

.add-edit-replic__additional-data-button_gray {
  button {
    border: none;
    display: block;
    width: 219px;
    text-align: center;
    text-decoration: none;
    height: 63px;
    font-weight: 600;
    font-size: 18px;
    line-height: 63px;
    background: $gray;
    text-align: center;
    color: #ffffff;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

    &:hover {
      background: $grayHover;
    }
  }
}

.add-edit-replic__additional-data-unit-left-side-text_red {
  color: $red;
}

.add-edit-replic__additional-data-unit-left-side-text_blue {
  color: $blue;
}

.add-edit-replic-top-side-name_edit {
  &:before {
    background-image: url($path + "edit-icon.svg");
  }
}

.add-edit-replic_edit {
  &:after {
    background: $green;
  }
}

.search-and-settings__field-value_mobile-flex {
  display: flex;
  align-items: center;
}

.search-and-settings__field-value-type-mobile_pos-relative {
  position: relative;
  margin-right: 12px;
  display: inline-block;
  top: -11px;
  left: 0;
}

.search-and-settings_company-mode {
  .search-and-settings__top-side {
    padding-right: 61px;
  }
}

.search-and-settings__field-button-remove_alone {
  right: -48px;
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .add-edit-replic__additional-data-button_blue {
    width: 24.88%;

    button {
      width: 100%;
    }
  }

  .add-edit-replic__additional-data-button_red {
    width: 37.84%;

    button {
      width: 100%;
    }
  }

  .add-edit-replic__additional-data-button_gray {
    width: 24.88%;

    button {
      width: 100%;
    }
  }

  .search-and-settings__field-button-remove_alone {
    right: -38px;
  }
  .replic__replic-unit-replic-note-title {
    /*left:-113px;*/
    display: flex;
    align-items: flex-end;
    min-height: 30px;
    left: 0;
    transform: translateY(0);
    top: -35px;
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .replic__replic-unit.replic__replic-unit_open {
    .replic__replic-unit-status-botton-agreement {
      padding-left: 18px;
    }
  }

  .replic__replic-header-bar-cell_width-201px {
    display: none;
  }
  .replic__replic-header-bar-cell_width-126px {
    display: none;
  }
  .replic__replic-header-bar-cell_width-229px {
    /*width:237px;*/
    top: 8px;
    width: auto;
    position: absolute;
    right: 90px;
  }
  .replic__replic-unit-ticket-item-right-side_center {
    display: none;
  }

  .replic__replic-unit-date {
    left: 0px;
    bottom: -12px;
    padding-left: 30px;

    &:before {
      top: 4px;
    }
  }
  .replic__replic-unit_open {
    .replic__replic-unit-date {
      bottom: 0;
      top: 0px;
      color: $blue;
      position: absolute;
      padding-left: 0;
      padding-top: 0;

      &:before {
        position: relative;
        margin-bottom: 15px;
      }
    }

    .replic__replic-unit-checkbox {
      label {
        display: none;
      }
    }

    .replic__replic-unit-status-botton-agreement {
      // display: none;
    }

    .replic__replic-unit-status-botton-agreement-text {
      &.up {
        transform: translate(0, 0);
      }
    }

    .replic__replic-unit-status-text {
      // position: absolute;
      // bottom: -26px;
      // height: auto;
      // top: -25px;
      // margin: 0;
    }
  }

  .replic__replic-unit-ticket-item_bg-white {
    background: #fff;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 43px;
    margin-bottom: 10px;

    form {
      display: flex;
      flex-direction: column;
    }

    textarea {
      height: 181px;
      margin: 0 10px;
      width: auto;
    }

    .replic__replic-unit-ticket-write-client-comment-buttons {
      margin-top: 12px;
    }
  }

  .replic__replic-unit-ticket-write-client-comment-button {
    &.replic__replic-unit-ticket-write-client-comment-button_cancel button {
      font-size: 16px;
    }

    &.replic__replic-unit-ticket-write-client-comment-button_public button {
      font-size: 16px;
    }
  }

  .replic__replic-unit-manage-button_blue {
    width: 100%;

    button {
      width: 100%;
    }
  }
  .replic__replic-unit-manage-button_light-blue {
    width: 100%;

    button {
      width: 100%;
    }
  }
  .replic__replic-unit-manage-button_gray {
    width: 100%;

    button {
      width: 100%;
    }
  }

  .add-edit-replic__additional-data-button_blue {
    width: 28.454%;

    button {
      width: 100%;
      background: $blue;

      &:hover {
        background: $blueHover;
      }
    }
  }

  .add-edit-replic__additional-data-button_red {
    width: 60%;

    button {
      width: 100%;
      background: $red;

      &:hover {
        background: $redHover;
      }
    }
  }

  .add-edit-replic__additional-data-button_gray {
    width: 40%;

    button {
      width: 100%;
      background: $gray;

      &:hover {
        background: $grayHover;
      }
    }
  }

  .add-edit-replic__additional-data-unit-right-side-content-button-minus_external {
    right: -40px;
  }

  .search-and-settings__settings_company-mode {
    position: absolute;
    width: 100%;

    .search-and-settings__upload {
      font-size: 18px;
      text-align: center;
      padding-left: 0;
      width: 38%;
      background: $red;
      position: absolute;
      top: -60px;

      &:before {
        display: none;
      }
    }

    .search-and-settings__upload-text {
      display: block;
    }

    .search-and-settings__download {
      font-size: 18px;
      text-align: center;
      padding-left: 0;
      width: 38%;
      background: $red;
      position: absolute;
      top: -60px;

      &:before {
        display: none;
      }
    }

    .search-and-settings__download-text {
      display: block;
    }

    .search-and-settings__add-edit-replic {
      font-size: 18px;
      text-align: center;
      padding-left: 0;
      width: auto;
      left: 38%;
      right: 61px;
      background: $gray;
      position: absolute;
      top: -60px;

      &:before {
        display: none;
      }
    }

    .search-and-settings__add-edit-replic-text {
      display: block;
    }

    .search-and-settings__settings-button {
      width: 60px;
    }
  }

  .swiper-slide.swiper-slide-active.swiperFilter {
    width: 257px !important;
  }
}

@media only screen and (max-width: 767px) {
  .analise .replic__filter-unit-separator {
    top: 30px;
  }

  .replic__replic-unit-ticket-client-comment-type-mobile {
    display: block;
  }
  .search-and-settings__field-value_mobile-flex {
    padding-left: 25px;
  }
  .search-and-settings__field-value-type-mobile_pos-relative {
    top: -9px;
  }
  .replic__replic-unit-ticket-item-right-side_center {
    display: none;
  }
  .replic__replic-unit_open {
    &:after {
      display: block;
      width: 3px;
    }
  }
  .replic__replic-unit-ticket-item_bg-white {
    background: #fff;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 43px;
    margin-bottom: 0px;
    padding-bottom: 20px;

    form {
      display: flex;
      flex-direction: column;
    }

    textarea {
      height: 181px;
      margin: 0px;
      width: auto;
    }

    .replic__replic-unit-ticket-write-client-comment-buttons {
      margin-top: 12px;
    }
  }

  .replic__replic-unit.replic__replic-unit_open {
    .replic__replic-unit-status-botton-agreement {
      background-color: #e00c3c;
      background-image: url($path + "arrow-plain-up.svg");
    }
  }

  .add-edit-replic {
    .add-edit-replic-horizont-line {
      top: 231px;
    }
  }

  .add-edit-replic-horizont-line {
    top: 30px;
    margin-left: 30px;
    width: 10px;
    background-color: #e00c3c;
  }

  .add-edit-replic-vertical-line {
    margin-left: 30px;
    height: calc(100% + 20px);
    z-index: 10;

    &[data-line="Y"] {
      display: block;
      top: calc(0% + 31px);
    }
  }

  .add-edit-replic__additional-data-button_blue {
    width: 100%;

    button {
      background: $blue;
      width: 100%;

      &:hover {
        background: $blueHover;
      }
    }
  }

  .add-edit-replic__additional-data-button_red {
    width: 100%;

    button {
      background: $red;
      width: 100%;

      &:hover {
        background: $redHover;
      }
    }
  }

  .add-edit-replic__additional-data-button_gray {
    width: 100%;

    button {
      background: $gray;
      width: 100%;

      &:hover {
        background: $grayHover;
      }
    }
  }

  .add-edit-replic__additional-data-unit-right-side-content-button-minus_external {
    right: 35px;
  }
  .add-edit-replic__additional-data-unit-right-side-content-button-minus_alone {
    right: 0px;
  }

  .replic__replic-unit-manage-button_blue {
    width: 100%;

    button {
      width: 100%;
    }
  }
  .replic__replic-unit-manage-button_light-blue {
    width: 100%;

    button {
      width: 100%;
    }
  }
  .replic__replic-unit-manage-button_gray {
    width: 100%;
    flex-shrink: 0;

    button {
      width: 100%;
    }
  }

  .search-and-settings__settings_company-mode {
    position: absolute;
    width: 100%;
    top: 0;
    height: auto;

    .search-and-settings__upload {
      font-size: 18px;
      text-align: center;
      padding-left: 0;
      width: 60px;
      right: 61px;
      background: $red;
      position: absolute;
      top: -60px;

      &:before {
        display: block;
        background-image: url($path + "portfolio-plain.svg");
        width: 24px;
        height: 26px;
        left: 50%;
        margin-left: -12px;
      }
    }

    .search-and-settings__upload-text {
      display: none;
    }

    .search-and-settings__download {
      font-size: 18px;
      text-align: center;
      padding-left: 0;
      width: 60px;
      right: 120px;
      background: $red;
      position: absolute;
      top: -60px;
    }

    .search-and-settings__download-text {
      height: 100%;
      height: 100%;
      position: relative;
      background: $gray;

      span {
        display: none;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        background-image: url($path + "users-icon.svg");
        width: 31px;
        height: 26px;
        left: 50%;
        margin-left: -15.5px;
      }
    }

    .search-and-settings__add-edit-replic {
      font-size: 18px;
      text-align: center;
      padding-left: 0;
      width: 60px;
      left: auto;
      right: 61px;
      background: $gray;
      position: absolute;
      top: -60px;
    }

    .search-and-settings__add-edit-replic-text {
      height: 100%;
      height: 100%;
      position: relative;
      background: $gray;

      span {
        display: none;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        background-image: url($path + "portfolio-plain.svg");
        width: 24px;
        height: 26px;
        left: 50%;
        margin-left: -12px;
      }
    }

    .search-and-settings__settings-button {
      width: 60px;
      z-index: 2;
    }

    .search-and-settings__search-mobile {
      position: absolute;
      top: -60px;
      left: 50px;
      width: auto;
      right: 180px;
      background: #fff;

      &:before {
        background-image: url($path + "magnifier-icon.svg");
      }
    }
  }

  .search-and-settings_company-mode {
    position: relative;

    &.search-and-settings {
      order: 0;
    }

    .search-and-settings__top-side {
      margin-bottom: 59px;
      flex-direction: row;
    }

    .search-and-settings__add-edit-replic {
    }

    .search-and-settings__search {
      position: absolute;
      top: 0;
      left: -10px;
      right: 60px;
      margin-top: 0;
      width: auto;
      box-shadow: 0px 10px 24px #f2f0ff;

      input[type="submit"] {
        display: none;
      }

      input[type="text"] {
        padding-left: 13px;
      }
    }

    .search-and-settings__search-mobile_active {
      &:before {
        background-image: url($path + "magnifier-icon-red.svg");
      }
    }
  }

  .search-and-settings_users-mode {
    &.search-and-settings {
      order: 0;
    }

    .search-and-settings__search {
      margin-top: 0px;
      position: absolute;
      top: 0px;
      left: -10px;
      right: -10px;
      width: auto;
      box-shadow: 0px 10px 24px #f2f0ff;
      z-index: 1;

      input[type="submit"] {
        display: none;
      }

      input[type="text"] {
        padding-left: 13px;
      }
    }

    .search-and-settings__tune-mobile {
      position: absolute;
      right: 180px;
      top: -60px;
      border-bottom: 1px solid #f2f5f9;

      &:before {
        background-image: url($path + "menu-replic-tune-red.svg");
      }
    }

    .search-and-settings__search-mobile {
      right: 240px;
      border: 1px solid #f2f5f9;
      border-top: none;
    }

    .search-and-settings__top-side {
      margin-bottom: 59px;
    }

    .search-and-settings__search-mobile_active {
      &:before {
        background-image: url($path + "magnifier-icon-red.svg");
      }
    }
  }

  .search-and-settings__field-button-remove_alone {
    right: -32px;
  }
}

.select__control--menu-is-open {
  z-index: 5;
  position: relative;
}

.add-edit-replic-top-side-select-unit .select__control {
  //border: 1px solid #e0dcff;
  border-left: none;
  padding: 0 0 0 20px;

  &:hover {
    border: 1px solid #e0dcff;
    border-left: none;
  }
}

.search-and-settings__top-select {
  .select-style {
    border: 1px solid #e0dcff;
    height: 60px;
    border-radius: 0 10px 10px 10px;
    box-shadow: inset 0 1px 11px #f2f0ff;

    .select__control {
      padding-left: 32px;
    }
  }
}

.search-and-settings__fields-bottom-side {
  .select-style {
    background-color: #fff;
    height: 63px;
    border: 1px solid #e0dcff;

    .select__control {
      padding-left: 20px;

      .select__single-value {
        color: #2e2382;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .replic__filter-unit {
    z-index: 15;

    & + .replic__filter-unit {
      z-index: 14;

      & + .replic__filter-unit {
        z-index: 13;

        & + .replic__filter-unit {
          z-index: 12;

          & + .replic__filter-unit {
            z-index: 11;

            & + .replic__filter-unit {
              z-index: 10;

              & + .replic__filter-unit {
                z-index: 9;

                & + .replic__filter-unit {
                  z-index: 8;
                }
              }
            }
          }
        }
      }
    }
  }

  .search-and-settings {
    //z-index: 1000;
    &[data-active="Y"] {
      z-index: 1000;
    }
  }
}

.replica-status-filter {
  .select__menu {
    position: absolute;
    right: 0;
  }
}
