.Wrap {
  border-bottom: 1px solid #d0ddef;
}

.Date {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.Separator {
  margin: 0 14px;
}
