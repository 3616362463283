.Item {
  position: relative;
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e9eff5;
  justify-content: space-between;
  padding-left: 70px;
  padding-right: 54px;

  &.Active {
    & + .ItemInner {
      display: block;
    }
  }
}

.DateField {
  width: 100%;
  max-width: 168px;

  &:not(:last-child) {
    margin-right: 25px;
  }
}

.ItemNumber {
  position: absolute;
  left: 38px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #505d6f;
}

.Columns {
  width: 100%;
  display: flex;
  align-items: center;
}

.ItemCol {
  width: 100%;
  max-width: 120px;
  text-align: right;
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  line-height: 16px;
  color: #505d6f;
  text-align: center;

  &:not(:last-child) {
    margin-right: 80px;
  }

  &.RemainsOld,
  &.Needed {
    font-family: "Gilroy";
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2e2382;
  }

  &.Date {
    width: 100%;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin-right: 260px;
  }
}

.DateItem {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 16px;

  span {
    padding-top: 3px;
    margin-right: 8px;
  }
}

.DateSeparator {
  color: #94a2b6;
  width: 26px;
  text-align: center;
  margin: 0 2px;
}

.ItemControl {
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;

  span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #8f95b2;
    transition: all 0.2s ease;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }

  &:hover {
    span {
      background-color: #e00c3c;
    }
  }
}

.ItemInner {
  display: none;
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Item {
    position: relative;
    width: fit-content;
    border-top: none;
    padding-right: 100px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      background-color: #e9eff5;
    }
  }

  .ItemCol {
    min-width: 150px;
    max-width: 150px;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.Date {
      min-width: 360px;
      max-width: 360px;
      margin-right: 30px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Item {
    position: relative;
    width: fit-content;
    border-top: none;
    padding-right: 100px;
    padding-left: 28px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      background-color: #e9eff5;
    }
  }

  .ItemNumber {
    left: 8px;
  }

  .ItemCol {
    min-width: 150px;
    max-width: 150px;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.Date {
      min-width: 360px;
      max-width: 360px;
      margin-right: 30px;
    }
  }
}
