.InsideConfirmationAction {
  width: 100%;

  .Text {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;

    &[data-color="lightred"] {
      color: #e00c3c;
    }

    &[data-color="lightgreen"] {
      color: #119e00;
    }
  }

  .Subtext {
    color: #2e2382;
  }

  .BottomWrapper {
    margin-top: 30px;
    width: 100%;
    display: flex;

    .InputWrapper {
      margin-top: -8px;
      width: 900px;
      height: 60px;
    }

    .BtnWrapper {
      margin-left: 25px;
      width: 302px;
    }
  }
}

@media (min-width: 767px) and (max-width: 1280px) {
  .InsideConfirmationAction {
    .BottomWrapper {
      .InputWrapper {
        width: 375px;
      }
    }

    .BtnWrapper {
      width: 302px;
    }
  }
}

@media (max-width: 767px) {
  .InsideConfirmationAction {
    .Subtext {
      font-size: 16px;
      line-height: 18px;
      margin-top: 25px;
    }

    .BottomWrapper {
      flex-direction: column;

      .InputWrapper {
        width: 100%;
        height: 151px;

        textarea {
          height: 151px;
        }
      }

      .BtnWrapper {
        width: 100%;
        left: 0;
        top: 341px;
        margin-left: 0;
        position: absolute;
      }
    }
  }
}
