@import "../../style/constants.scss";

$path: "../../images/";

.PaginationWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.PaginationSettings {
  max-width: 300px;
}

@media only screen and (min-width: $desktopWide) {
}

@media only screen and (min-width: $tabletLandscapeMin) and (max-width: $tabletLandscapeMax) {
}

@media only screen and (min-width: $tabletPortraitMin) and (max-width: $tabletPortraitMax) {
}

@media only screen and (max-width: $mobile) {
  .PaginationWrap {
    flex-direction: column;
    width: 100%;
  }

  .PaginationSettings {
    width: 100%;
    max-width: 100%;
    margin-top: 28px;
  }
}
