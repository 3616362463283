@import "./src/style/constants.scss";

$path: "../../../../images/";

.Info {
  margin-bottom: 15px;
}

.InfoRow {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2e2382;
}

.InfoTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #94a2b6;
  margin-right: 6px;
}

.ReplicaItem {
  margin: 10px 0;
  position: relative;

  .ReplicWrapper {
    display: flex;
    min-height: 98px;
  }

  .Checkbox {
    width: 19px;
    flex-shrink: 0;
    padding-top: 4px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Icon {
      width: 23px;
      height: 18px;
      background: no-repeat center;
      display: none;

      &[data-icon="1"] {
        display: block;
        width: 28px;
        height: 24px;

        &[data-status="refused"] {
          background-image: url($path + "Replica/icon-reagir-red.svg");
        }

        &[data-status="created"] {
          background-image: url($path + "Replica/icon-reagir-orange.svg");
        }

        &[data-status="waiting-agreement"] {
          background-image: url($path + "Replica/icon-reagir-blue.svg");
        }

        &[data-status="for-posting"] {
          background-image: url($path + "Replica/icon-reagir-green.svg");
        }

        &[data-status="under-moderation"] {
          background-image: url($path + "Replica/icon-reagir-lightblue.svg");
        }

        &[data-status="posted"] {
          background-image: url($path + "Replica/icon-reagir-gray.svg");
        }
      }

      &[data-icon="2"] {
        display: block;
        width: 29px;
        height: 24px;

        &[data-status="refused"] {
          background-image: url($path + "Replica/icon-reaction-red.svg");
        }

        &[data-status="created"] {
          background-image: url($path + "Replica/icon-reaction-orange.svg");
        }

        &[data-status="waiting-agreement"] {
          background-image: url($path + "Replica/icon-reaction-blue.svg");
        }

        &[data-status="for-posting"] {
          background-image: url($path + "Replica/icon-reaction-green.svg");
        }

        &[data-status="under-moderation"] {
          background-image: url($path + "Replica/icon-reaction-lightblue.svg");
        }

        &[data-status="posted"] {
          background-image: url($path + "Replica/icon-reaction-gray.svg");
        }
      }

      &[data-icon="3"] {
        display: block;
        width: 29px;
        height: 24px;
        background-image: url($path + "Replica/icon-reaction-reagir.svg");
      }
    }

    label {
      width: 19px;
      height: 19px;
      border: 1px solid $gray;
      display: inline-block;
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        opacity: 0;
        position: absolute;
        width: 17px;
        height: 17px;
        background: url($path + "icons/tick.svg") no-repeat center;
        @include transition;
      }
    }

    input {
      display: none;

      &:checked + label:before {
        opacity: 1;
      }
    }
  }

  .Date {
    padding-top: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray;
    width: 82px;
    margin-right: 55px;
    flex-shrink: 0;
  }

  .Site {
    margin-right: 10px;
    width: 190px;
    flex-shrink: 0;

    font-weight: 500;
    text-decoration: none;
    font-size: 16px;
    line-height: 19px;
    color: $blue;
    cursor: pointer;
    padding-top: 4px;
  }

  .ContentWrapper {
    position: relative;
    display: flex;
    width: 100%;

    .Content {
      width: 100%;
      padding-right: 10px;
      margin-right: 0px;
      position: relative;

      .Text {
        height: 24px;
        width: 92%;
        font-weight: 500;
        flex-shrink: 0;
        font-size: 14px;
        line-height: 24px;
        color: $darkGray;
        overflow: hidden;

        .IconDropDownTextWrapper {
          position: absolute;
          top: 3px;
          right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &:before {
            width: 20px;
            height: 20px;
            content: "...";
            position: absolute;
            top: 0;
            left: -20px;
          }

          &[data-ellipsys="true"] {
            &:before {
              display: none;
            }
          }

          .IconDropDownText {
            width: 19px;
            height: 19px;
            background: url($path + "Replica/closed_txt_box.svg") no-repeat center center / contain;
            cursor: pointer;
          }

          .LineDrop {
            width: 1px;
            height: 5px;
            background: #94a2b6;
          }

          &[data-show="Y"] {
            display: none;
          }
        }
      }
    }
  }

  .ArrowBtn {
    background: #d0ddef;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 7px;
    cursor: pointer;

    &:before {
      background: url($path + "Replica/icon-arrow-replic.svg");
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "";
      width: 11px;
      height: 7px;
    }

    &:hover {
      background-color: #e00c3c;
    }

    &[data-active="Y"] {
      background-color: #e00c3c;

      &:before {
        transform: rotate(180deg) translate(50%, 50%);
      }
    }
  }
}

.StatusUnit {
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: center;
  padding-left: 30px;

  .StatusText {
    margin-bottom: 18px;

    span {
      margin-left: 0 !important;
    }
  }
}

.InfoLastEdit {
  p {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #94a2b6;
  }

  &.Mobail {
    display: none;
  }
}

.InfoLastEditDate {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #94a2b6;

  svg {
    margin-right: 12px;
  }
}

.NoteSystem {
  display: flex;
  align-items: center;

  span {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid #e00c3c;
    color: #e00c3c;
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .ReplicaItem {
    .Checkbox {
      width: 93px;
      display: block;
      margin-right: 0;

      .Icon {
        position: absolute;
        bottom: 50%;
      }

      label {
        width: 30px;
        height: 30px;

        &:before {
          width: 28px;
          height: 28px;
        }
      }
    }

    .Date {
      padding-left: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: 14px;
      line-height: 17px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: -24px;
        width: 20px;
        height: 20px;
        background: url($path + "icons/calendar-icon-gray.svg") no-repeat center / contain;
      }
    }

    .ContentWrapper {
      flex-direction: column-reverse;

      .Site {
        margin-right: 0;
        padding-top: 22px;

        span {
          position: relative;
          line-height: 24px;
          padding-left: 25px;

          &:before {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 0;
            left: 0;
            background: url($path + "/Replica/icon-clip.svg") no-repeat center / contain;
            content: "";
          }

          &::after {
            content: "";
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #d0ddef;
          }
        }
      }

      .Content {
        //display: flex;
        //flex-direction: column-reverse;
      }
    }
  }

  .StatusUnit {
    padding-left: 0;
  }

  .StatusText {
    margin-bottom: 0 !important;
  }

  .InfoLastEdit {
    padding: 8px 0 8px 18px;

    p {
      line-height: 14px;
      margin-bottom: 4px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .InfoRow {
    padding-right: 50px;
  }

  .ReplicaItem {
    margin: 10px 0;

    &[data-active="Y"] {
      z-index: 1000;
    }

    .ReplicWrapper {
      position: static;
      flex-direction: column;
    }

    .Checkbox {
      margin-right: 0px;
      padding-left: 0px;
      width: 60px;
      padding-top: 0px;
      position: absolute;
      left: 0px;
      top: 0px;

      label {
        width: 60px;
        height: 60px;
        background: url($path + "icons/tick-white.svg") #d0ddef no-repeat center;
        border: none;

        &:before {
          width: 60px;
          height: 60px;
          background: url($path + "icons/tick-white.svg") $green no-repeat center;
        }
      }
    }

    .Date {
      position: relative;
      left: 0px;
      bottom: 0px;
      top: 4px;
      font-size: 14px;
      line-height: 17px;
      padding-left: 33px;
      margin-bottom: 18px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 4px;
        width: 20px;
        height: 20px;
        background: url($path + "icons/calendar-icon-gray.svg") no-repeat center / contain;
      }
    }

    .ContentWrapper {
      flex-direction: column-reverse;

      .Site {
        margin-right: 0;
        padding-top: 26px;

        span {
          padding-left: 25px;
          position: relative;

          &:before {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 0;
            left: 0;
            background: url($path + "/Replica/icon-clip.svg") no-repeat center / contain;
            content: "";
          }

          &::after {
            content: "";
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #d0ddef;
          }
        }
      }

      .Content {
        //display: flex;
        //flex-direction: column-reverse;
      }
    }
  }

  .StatusUnit {
    padding-left: 0;
  }

  .InfoLastEdit {
    display: none;

    &.Mobail {
      display: flex;
    }
  }

  .InfoLastEditDate {
    margin-left: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  //.Date {
  //  left: 0px;
  //  bottom: -12px;
  //  padding-left: 30px;
  //
  //  &:before {
  //    top: 4px;
  //  }
  //}
  .ReplicaItem {
    .ArrowBtn {
      z-index: 0;

      margin-right: 0;
      right: 0;
    }
  }

  .replic__replic-unit_open {
    .Date {
      bottom: 0;
      top: 0px;
      color: $blue;
      position: absolute;
      padding-left: 0;
      padding-top: 0;

      &:before {
        position: relative;
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ReplicaItem {
    display: flex;
    flex-direction: column;

    .Checkbox {
      .Icon {
        &[data-icon="1"] {
          display: none;
        }

        &[data-icon="2"] {
          display: none;
        }

        &[data-icon="3"] {
          display: none;
        }
      }
    }

    .MobileDropDown {
      width: 100%;
      height: 60px;
      padding: 0 0 0 17px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &[data-active="Y"] {
        order: 1;
      }

      .Icon {
        width: 23px;
        height: 18px;
        background: no-repeat center;
        display: none;

        &[data-icon="1"] {
          display: block;
          width: 28px;
          height: 24px;

          &[data-status="refused"] {
            background-image: url($path + "Replica/icon-reagir-red.svg");
          }

          &[data-status="created"] {
            background-image: url($path + "Replica/icon-reagir-orange.svg");
          }

          &[data-status="waiting-agreement"] {
            background-image: url($path + "Replica/icon-reagir-blue.svg");
          }

          &[data-status="for-posting"] {
            background-image: url($path + "Replica/icon-reagir-green.svg");
          }

          &[data-status="under-moderation"] {
            background-image: url($path + "Replica/icon-reagir-lightblue.svg");
          }

          &[data-status="posted"] {
            background-image: url($path + "Replica/icon-reagir-gray.svg");
          }
        }

        &[data-icon="2"] {
          display: block;
          width: 29px;
          height: 24px;

          &[data-status="refused"] {
            background-image: url($path + "Replica/icon-reaction-red.svg");
          }

          &[data-status="created"] {
            background-image: url($path + "Replica/icon-reaction-orange.svg");
          }

          &[data-status="waiting-agreement"] {
            background-image: url($path + "Replica/icon-reaction-blue.svg");
          }

          &[data-status="for-posting"] {
            background-image: url($path + "Replica/icon-reaction-green.svg");
          }

          &[data-status="under-moderation"] {
            background-image: url($path + "Replica/icon-reaction-lightblue.svg");
          }

          &[data-status="posted"] {
            background-image: url($path + "Replica/icon-reaction-gray.svg");
          }
        }

        &[data-icon="3"] {
          display: block;
          width: 29px;
          height: 24px;
          background-image: url($path + "Replica/icon-reaction-reagir.svg");
        }
      }

      .ArrowBtn {
        margin-right: -5px;
        background: #ffffff;
        width: 60px;
        height: 60px;
        position: relative;
        top: 0;
        right: 5px;
        cursor: pointer;

        &:before {
          background: no-repeat center center / contain;
          background-image: url($path + "Replica/icon-mobile-red.svg");
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: "";
          width: 11px;
          height: 7px;
        }

        &[data-active="Y"] {
          background-color: #e00c3c;
          transform: rotate(180deg);

          &:before {
            background-image: url($path + "Replica/icon-arrow-replic.svg");
          }
        }
      }
    }

    .ContentWrapper {
      .Content {
        .Text {
          width: 86%;
        }
      }
    }
  }
}
