.Wrap {
}

.Btn {
  position: relative;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #505d6f;
  border-bottom: 1px solid #d2dcea;
  padding: 0 20px;
  transition: all 0.2s ease;

  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    opacity: 0;

    path {
      fill: #fff;
    }
  }

  &.Active {
    background-color: #e00c3c;
    color: #fff;

    svg {
      opacity: 1;
    }
  }

  &:hover {
    background-color: #e00c3c;
    color: #fff;
  }
}

.Empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
