@import "../../style/constants";

/*===============================================================
  Компонент "Авторизация" Основные стили
  ===============================================================*/

.authorization {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authorization__wrap {
  max-width: 1480px;
  padding: 0 20px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.authorization__container {
  width: 453px;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.authorization__top-side {
  display: flex;
  width: 100%;
}
.authorization__logo {
  width: 50%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authorization__logo-img {
  background: no-repeat center / contain;
  width: 100%;
  height: 24px;
  margin: 0 37px;
}
.authorization__form {
  input[type="submit"] {
    display: block;
    border: none;
    width: 100%;
    height: 101px;
    background: $blue;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    @include transition;
    &:hover {
      background: $red;
    }
  }
}

.authorization__inputs-container {
  padding: 34px 37px 53px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.authorization__inputs-container {
  input {
    width: 100%;
    height: 60px;
    margin-bottom: 16px;
    padding: 0 30px 0 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $gray;
    color: $blue;
    background: #ffffff;
    border: 1px solid #e0dcff;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0 0 0 10px;
    &:last-child {
      margin-bottom: 0px;
    }
    &::-webkit-input-placeholder {
      color: $gray;
    }
    &:-ms-input-placeholder {
      color: $gray;
    }
    &::-moz-placeholder {
      color: $gray;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: $gray;
      opacity: 1;
    }
    &.alert {
      border: 1px solid #f6b5c3;
      box-shadow: inset 0px 1px 11px #f2f0ff;
      &::-webkit-input-placeholder {
        color: $blue;
      }
      &:-ms-input-placeholder {
        color: $blue;
      }
      &::-moz-placeholder {
        color: $blue;
        opacity: 1;
      }
      &:-moz-placeholder {
        color: $blue;
        opacity: 1;
      }
    }

    &:focus {
      border: 1px solid $red;
      &::placeholder {
        color: $blue;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (max-width: 767px) {
  .authorization__logo-img {
    background: no-repeat center / contain;
    width: 100%;
    height: 24px;
    margin: 0 16px;
  }

  .authorization__wrap {
    padding: 0 36px;
    width: 100%;
    .rights-reserved {
    }
    .rights-reserved_margin_top-70px {
      margin-top: 35px;
    }
    .rights-reserved_width-453px {
      width: 100%;
    }
  }
  .authorization__container {
    width: 100%;
  }
  .authorization__inputs-container {
    padding: 34px 16px 23px;
  }
  .authorization__form {
    input[type="submit"] {
      height: 71px;
    }
  }
}

/*===============================================================
  Компонент "Авторизация" Модификаторы
  ===============================================================*/

.authorization__logo_red {
  background: $red;
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (max-width: 767px) {
}
