@import "constants.scss";

.filter-customOption {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  cursor: pointer;
  height: 60px;
  color: $darkGray;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  padding: 0 30px 0 30px;
  transition: all 0.2s ease;

  &__label {
    margin-right: 50px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:first-of-type {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    background-color: transparent;
    //color: white;
    //pointer-events: none;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 30px;
      right: 30px;
      height: 1px;
      width: auto;
      transition: all 0.2s ease;
      background-color: transparent;
    }
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 30px;
    right: 30px;
    height: 1px;
    width: auto;
    transition: all 0.2s ease;
    background-color: #f1f1f1;
  }

  &:hover {
    background-color: #df0d3c !important;
    color: white;

    &::after {
      background-color: transparent;
    }
  }

  //, &.focus
  &.active {
    background-color: #e00c3c;
    //color: white;

    &::after {
      background-color: #e00c3c;
    }
  }

  &.disabled {
    background-color: #fff;
    color: $grayHover;
    pointer-events: none;

    &::after {
      background-color: #f1f1f1 !important;
    }

    //&.focus {
    //  background-color: #fff;
    //  font-weight: 500;
    //  font-size: 12px;
    //  line-height: 14px;
    //  color: $grayHover;
    //}
  }
}
.filter-customOption.focused {
  background-color: transparent !important;

  &::after {
    background-color: transparent;
  }
  //color: white;
}

.filter-customOption.selected {
  background-color: #df0d3c !important;
  color: white;

  &::after {
    background-color: transparent;
  }
}

.filter-customOption.hovered {
  background-color: #df0d3c !important;
  color: white;
}

@media only screen and (min-width: $tabletLandscapeMin) and (max-width: $tabletLandscapeMax) {
  .filter-customOption {
    height: auto;
    min-height: 60px;
    background-color: #fff;

    &__label {
      white-space: pre-wrap !important;
      margin-right: 30px;
    }
  }
}

@media only screen and (min-width: $tabletPortraitMin) and (max-width: $tabletPortraitMax) {
  .filter-customOption {
    height: auto;
    min-height: 60px;
    background-color: #fff;

    &__label {
      white-space: pre-wrap !important;
      margin-right: 30px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .filter-customOption {
    height: auto;
    min-height: 60px;
    background-color: #fff;

    &__label {
      white-space: pre-wrap !important;
      margin-right: 30px;
    }
  }
}
