@import "./src/style/constants.scss";

$path: "../../../../images/";

.ReplicaListChildren {
  position: relative;
  background-color: #ffffff;
  padding: 0 0 40px 34px;
  margin-top: -15px;

  &[data-open="Y"] {
    .ItemWrapper {
      .HeaderBar {
        display: none;
      }
    }
  }

  .ItemWrapper {
    background-color: #f7f9fe;
    padding: 90px 0 40px 40px;

    &[data-line="Y"] {
      border-left: 1px solid #94a2b6;
    }

    .HeaderBar {
      display: flex;
      padding-left: 19px;
      padding-bottom: 9px;
      position: absolute;
      top: 65px;

      .BarCell {
        display: flex;
        align-items: center;
        width: 19px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $gray;

        &:first-child {
          margin-right: 20px;
        }

        &[data-width="126"] {
          margin-right: 55px;
          width: 82px;
        }

        &[data-width="201"] {
          margin-right: 120px;
          width: 82px;
        }

        &[data-width="229"] {
          margin-left: 113px;
          width: 100px;
        }

        &[data-width="782"] {
          width: 580px;
        }
      }
    }
  }

  .Add {
    background-color: #2e2382;
    width: 72px;
    height: 60px;
    position: absolute;
    top: 40px;
    right: 0;
    cursor: pointer;

    &:before {
      position: absolute;
      width: 34px;
      height: 32px;
      background: url($path + "/Replica/icon-add-replic-white.svg") no-repeat center / contain;
      content: "";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &[data-open="Y"] {
      background-color: #e00c3c;

      &:before {
        width: 30px;
        height: 30px;
        background: url($path + "/Replica/icon-arrow-back-replic.svg") no-repeat center / contain;
      }
    }
  }
}

@media (min-width: 767px) and (max-width: 1280px) {
  .ReplicaListChildren {
    .HeaderBar {
      .BarCell {
        &[data-width="126"] {
          display: none;
        }
        &[data-width="201"] {
          display: none;
        }
        &[data-width="229"] {
          margin-left: 113px;
          width: 100px;
        }

        &[data-width="782"] {
          width: 300px !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .ReplicaListChildren {
    order: 2;
    margin-top: 0;
    padding: 0 0 40px 15px;

    .ItemWrapper {
      padding-left: 10px;

      &[data-line="Y"] {
        border-left: 1px solid #e00c3c;
      }

      .HeaderBar {
        .BarCell {
          display: none;
        }
      }
    }

    .Add {
      background-color: #f7f9fe;

      &[data-open="Y"] {
        background-color: #e00c3c;
        position: absolute;
        //top: 100px;
        z-index: 100;
      }

      &:before {
        background: url($path + "/Replica/icon-reagir-blue.svg") no-repeat center / contain;
      }
    }
  }
}
