.Section {
  position: relative;
  background-color: #fff;
  margin-top: 10px;
}

.List {
}

.Item {
  background-color: #fff;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.ItemHeader {
  position: relative;
  display: flex;
}

.ItemNumber {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  background: #94a2b6;
}

.ItemHeaderContent {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  border-bottom: 1px solid rgba(#d0ddef, 0.6);
  margin-left: 24px;
  margin-right: 20px;
}

.ItemChange {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-right: 20px;

  svg {
    margin-right: 8px;
  }
}

.ItemUrl {
  position: relative;
}

.ItemUrlInner {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 6px;
  }
}

.Row {
  position: relative;
  display: flex;
  border-bottom: 1px solid #d0ddef;
}

.BtnChangeType {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: #d0ddef;
}

.IconDots {
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);

  circle {
    fill: #fff;
  }
}

.ItemCategory {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin-left: 24px;

  overflow: hidden;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ItemCategoryValue {
}

.BtnOpenCategories {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
}

.DropdownChangeType {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f2f5f9;
  box-shadow: 0px 10px 24px rgba(139, 134, 174, 0.3);
  padding: 32px 42px;

  opacity: 0;
  pointer-events: none;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #94a2b6;
    margin-bottom: 46px;
  }

  &.Active {
    opacity: 1;
    pointer-events: visible;
  }
}

.VariantsChangeType {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DropdownCategories {
  height: 250px;
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f2f5f9;
  box-shadow: 0px 10px 24px rgba(139, 134, 174, 0.3);
  padding: 20px 10px;
  padding-bottom: 0;

  opacity: 0;
  pointer-events: none;

  overflow-y: auto;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #94a2b6;
    margin-bottom: 14px;
  }

  &.Active {
    opacity: 1;
    pointer-events: visible;
  }
}

.VariantsCategories {
  display: flex;
  flex-wrap: wrap;

  button {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #505d6f;
    border: 1px solid #becbdc;
    background-color: transparent;
    padding: 8px 14px;

    margin-right: 10px;
    margin-bottom: 10px;
    transition: all 0.2s ease;
  }
}

.ItemContent {
  padding: 28px 18px 20px 18px;
}

.ItemTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #505d6f;
  margin-bottom: 10px;
}

.ItemText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #505d6f;
  margin-bottom: 28px;
}

.ItemRating {
  width: fit-content;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #505d6f;
  background: #f7f9fe;
  border-radius: 0px 10px 10px 10px;
  padding: 2px 12px;

  span {
    display: block;
    color: #119e00;
    margin-left: 8px;
  }
}

.Empty {
  padding: 24px;
}
