.ButtonSelector {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(110%);
  }

  &[data-color="red"] {
    background-color: #e00c3c;
  }

  &[data-color="green"] {
    background-color: #119e00;
  }

  &[data-color="blue"] {
    background-color: #2e2382;
  }

  &[data-color="violet"] {
    background-color: #822381;
  }

  &[data-color="gray"] {
    background-color: #94a2b6;
  }

  &[data-disabled="true"] {
    opacity: 0.2;
    pointer-events: none;
  }
}
