@import "../../../../style/constants.scss";

.Wrapper {
  position: absolute;
  z-index: 20;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 560px;
  background: #fff;
  box-shadow: 0px 10px 24px rgba(139, 134, 174, 0.3);
  padding: 20px 46px 26px 20px;
  opacity: 0;
  visibility: hidden;

  &.Open {
    opacity: 1;
    visibility: visible;
  }
}

.Inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.Field {
  width: 100%;
  min-height: 70px;
  background: #e9eff5;
  border-radius: 0px 10px 10px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $blue;
  border: 1px solid transparent;
  padding: 14px;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  .Href {
    color: inherit;
    text-decoration: none;
    max-width: 490px;
    word-break: break-all;
  }

  &.IsEdit {
    background-color: transparent;
    border-color: #f6b5c3;
  }
}

.Control {
  display: flex;
  align-items: center;
}

.EditControl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.EditBtn {
  border: none;
  box-shadow: none;
  outline: 0;
  padding: 0;

  width: 136px;
  height: 60px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  transition: all 0.3s ease;

  &:first-child {
    background-color: #e00c3c;
  }

  &:last-child {
    background-color: #2e2382;
  }

  &:hover {
    &:first-child {
      background-color: #ff4763;
    }

    &:last-child {
      background-color: #3b2da9;
    }
  }
}

.BtnCopy,
.BtnGoToLink,
.BtnEdit {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  &.Disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  path {
    transition: all 0.2s ease;
  }

  svg {
    margin-right: 10px;
  }

  &:hover {
    color: #e00c3c;

    path {
      fill: #e00c3c;
    }
  }
}

.BtnCopy {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $darkGray;
  margin-right: 36px;
  transition: all 0.2s ease;
}

.BtnGoToLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $darkGray;
  margin-right: 36px;
  transition: all 0.2s ease;
}

.BtnEdit {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $darkGray;
  cursor: pointer;
  transition: all 0.2s ease;
}

.Close {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  position: absolute;
  top: -4px;
  right: -25px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Control {
    flex-wrap: wrap;
  }

  .BtnCopy,
  .BtnGoToLink {
    margin-right: 30px;
  }

  .BtnEdit {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .Wrapper {
    left: 0;
  }

  .Control {
    flex-wrap: wrap;
  }

  .BtnCopy,
  .BtnGoToLink {
    width: 100%;
    max-width: 50%;
    margin-right: 0;
  }

  .BtnGoToLink {
    display: flex;
    justify-content: flex-end;
  }

  .BtnEdit {
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }
}
