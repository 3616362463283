.Wrap {
  min-height: 486px;
  background-color: #fff;
}

.MainBlock {
  position: relative;
  min-height: 414px;
  width: 100%;
}

.MainBlockText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  font-size: 16px;
  line-height: 19px;
  color: #505d6f;
  font-weight: 500;
}

.TopBlock {
  border-bottom: 1px solid #e9ebf1;
  box-sizing: border-box;
  padding: 15px 45px 27px 26px;
}

.RowTwo {
  margin-top: 16px;
  width: 397px;
  display: flex;
  justify-content: space-between;
}

.Infotmation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ColorDot {
  margin: 0 8px 4px 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &[data-color="Orange"] {
    background: #ff6400;
  }

  &[data-color="Black"] {
    background: #505d6f;
  }

  &[data-color="Green"] {
    background: #119e00;
  }

  &[data-color="Red"] {
    background: #e00c3c;
  }
}

.Text {
  font-size: 12px;
  line-height: 20px;
  color: #505d6f;
}
