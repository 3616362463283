.add-edit-replic__main-data-unit__row {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2e2382;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 20px;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #94a2b6;
    margin-right: 8px;
  }
}

.add-edit-replic__main-data-unit-url-input.is-edit {
  position: relative;
  width: 100%;
  margin-top: 16px;
}

.add-edit-replic__main-data-unit-url-input.is-edit .add-edit-replic__main-data-unit-url-type {
  display: flex;
  align-items: center;
  left: auto;
  top: 50%;
  right: calc(100% + 110px);
  transform: translateY(-50%);

  span {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid #e00c3c;
    color: #e00c3c;
    margin-left: 10px;
  }
}

.add-edit-replic__main-data-unit.noteSystem .add-edit-replic__main-data-unit-url {
  display: flex;
  align-items: center;
}

.add-edit-replic__main-data-unit.noteSystem .add-edit-replic__main-data-unit-url,
.add-edit-replic__main-data-unit.noteSystem .add-edit-replic__main-data-unit-url-type {
  display: flex;
  align-items: center;

  span {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid #e00c3c;
    color: #e00c3c;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .add-edit-replic__main-data-unit.noteSystem .add-edit-replic__main-data-unit-url {
    left: 0;
    padding-left: 0;
    margin-bottom: 6px;

    span {
      width: 20px;
      height: 20px;
    }
  }

  .add-edit-replic__main-data-unit.noteSystem .add-edit-replic__main-data-unit-url-type {
    span {
      width: 20px;
      height: 20px;
    }
  }

  .add-edit-replic__main-data-unit-url-input.is-edit {
    width: 100% !important;

    input {
      width: 100% !important;
    }
  }
}
