.ControlRollbackStatus {
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
}

.ControlRollbackStatusText {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  background-color: #ffba00;
  padding: 0 16px;
}

.ControlRollbackStatusBtns {
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  button {
    outline: 0;
    box-shadow: none;
    padding: 0;
    border: none;

    width: 110px;
    height: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #fff;

    &:first-child {
      background-color: #e00c3c;
    }

    &:last-child {
      background-color: #94a2b6;
    }
  }
}

.NoteSystem {
  display: flex;
  align-items: center;

  span {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid #e00c3c;
    color: #e00c3c;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .ControlRollbackStatus {
    height: auto;
    flex-wrap: wrap;
  }

  .ControlRollbackStatusText {
    padding: 16px;
  }

  .ControlRollbackStatusBtns {
    width: 100%;

    button {
      height: 50px;
      width: 100%;
      max-width: 50%;
    }
  }

  .NoteSystem + div {
    width: 100% !important;
  }
}
