@import "../../../style/constants";

.Tile {
  height: auto;
  background-color: #fff;
  box-shadow: 0 10px 24px #f2f0ff;
  padding: 40px 30px 33px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    margin: 0;
  }

  .Icon {
    margin-bottom: 55px;
    width: 60px;
    height: 56px;
    background-image: url("../../../images/Desktop/SmallTileFolder.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.DocumentsTileEdit {
  height: min-content;
}

.DefaultTile {
  padding: 35px 30px 20px 30px;

  .Icon {
    margin-bottom: 36px;
  }

  &:nth-child(1) {
    .Icon {
      width: 60px;
      height: 72px;
      background-image: url("../../../images/Desktop/BigTilePage.svg");
    }
  }

  &:nth-child(2) {
    .Icon {
      width: 72px;
      height: 72px;
      background-image: url("../../../images/Desktop/MiddleTileKPI.svg");
    }
  }

  &:nth-child(3) {
    .Icon {
      width: 73px;
      height: 72px;
      background-image: url("../../../images/Desktop/MiddleTileBudget.svg");
    }
  }
}

.TileTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2e2382;
  margin-bottom: 30px;
}

.ChangeButton {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #e00c3c;

    .ChangeIcon {
      path {
        fill: #fff;
      }
    }
  }

  .ChangeIcon {
    width: 17px;
    height: 17px;

    path {
      fill: #e00c3c;
      transition: all 0.2s ease;
    }
  }

  &.Active {
    background: #e00c3c;

    .ChangeIcon {
      path {
        fill: #fff;
      }
    }
  }
}

.ButtonPlus {
  margin-left: auto;
  background: transparent;
  margin-right: 10px;
  display: block;
  border: none;
  margin-top: 20px;
  opacity: 0.3;
  cursor: default;

  &.Active {
    opacity: 1;
    cursor: pointer;
  }

  &.MarginRight {
    margin-right: 12px;
  }
}

.IconPlus {
  display: block;
  width: 41px;
  height: 41px;
}

.FakeButtons {
  height: 62px;
  width: 100%;

  &.Column {
    height: 186px;
  }
}

@media only screen and (min-width: $tabletPortraitMin) and (max-width: $tabletLandscapeMax) {
  .TileTitle {
    margin-bottom: 15px;
  }

  .Tile {
    .Icon {
      margin-bottom: 55px;
    }

    ul {
      padding-top: 15px;
    }
  }

  .DefaultTile {
    .Icon {
      margin-bottom: 37px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Tile {
    .Icon {
      margin-bottom: 55px;
    }
  }

  .DefaultTile {
    .Icon {
      margin-bottom: 36px;
    }
  }
}

.iconUploadImage {
  &:before {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    right: 60px;
    background: url("../../../images/icons/icon-share.svg") no-repeat center center / contain;
  }
}

.iconRemoveFolder {
  cursor: pointer;
  &:before {
    content: "";
    width: 21px;
    height: 21px;
    position: absolute;
    right: 24px;
    background: url("../../../images/icons/close-icon-s.svg") no-repeat center center / contain;
  }
}

.iconRemoveFile {
  cursor: pointer;
  &:before {
    content: "";
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-right: 28px;
    position: absolute;
    background: url("../../../images/icons/close-icon-s.svg") no-repeat center center / contain;
  }
}

.inputDefaultTitle {
  font-weight: 600;
  font-size: 24px;
  color: #2e2382;
  width: 100%;
}

.fileInput {
  display: none;
}
