.Wrapper {
  position: fixed;
  z-index: 500;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(46, 35, 130, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
}

.Inner {
  width: 100%;
  max-width: 810px;
  box-shadow: 5px 8px 10px rgba(46, 35, 130, 0.13);
  background-color: #fff;
}

.Header {
  height: 58px;
  display: flex;
  align-items: center;
  background-color: #2e2382;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  padding-left: 20px;

  svg {
    margin-right: 28px;
  }
}

.Content {
  padding: 44px 38px;
}

.ContentRow {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2e2382;
  margin-bottom: 4px;

  &:first-child {
    margin-bottom: 16px;
  }
}

.ContentTitle {
  display: inline-block;
  min-width: 72px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #94a2b6;
  margin-right: 30px;

  &.Date {
    font-size: 14px;
    line-height: 16px;
  }
}

.Control {
  display: flex;
  align-items: center;

  button {
    border: none;
    box-shadow: none;
    background-color: transparent;
    outline: none;
    padding: 0;

    height: 62px;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #fff;

    &:first-child {
      max-width: 332px;
      background-color: #119e00;
      margin-left: auto;
    }

    &:last-child {
      max-width: 274px;
      background-color: #94a2b6;
    }
  }
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

@media only screen and (max-width: 767px) {
  .Content {
    padding: 24px;
  }

  .ContentRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    &:first-child {
      margin-bottom: 24px;
    }
  }

  .Control {
    flex-direction: column;

    button {
      &:first-child {
        max-width: 100%;
        margin-left: auto;
      }

      &:last-child {
        max-width: 100%;
      }
    }
  }
}
