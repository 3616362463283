.search-history {
  margin-left: 72px;

  .white-space {
    white-space: nowrap;
  }

  &-wrapper {
    margin-left: 30px;
    margin-right: 30px;
  }

  &-title {
    margin-top: 60px;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #2e2382;
    margin-bottom: 30px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f7f9fe;
    font-weight: 600;
    font-size: 17px;
  }

  tr {
    border-bottom: 1px solid #ccc;
    background-color: #ffffff;
  }

  th,
  td {
    text-align: left;
    padding: 12px;
  }

  tr:nth-child(even) {
    background-color: #f7f9fe;
  }

  .cell-header {
    display: none;
  }

  tbody tr:hover {
    background: #fa0c4c;
    color: #fff;
    cursor: pointer;
  }

  &-pagination {
    display: flex;
    justify-content: center;
  }

  &-selectors-wrapper {
    display: flex;
    gap: 10px;
  }

  &-all-filter-wrapper {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 990px) {
  .search-history {
    th {
      display: none;
    }

    .cell-header {
      display: block;
      margin-right: 30px;
      font-weight: bold;
    }

    td {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: 767px) {
  .search-history {
    margin-left: 0;

    &-selectors-wrapper {
      flex-direction: column;
    }
  }
}
