Section {
  button {
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  p {
    margin: 0;
  }
}

.Title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2e2382;
  margin-bottom: 26px;
}
