$red: #fa0c4c;
$redHover: #ff4763;
$blue: #2e2382;
$blueHover: #3b2da9;
$lightBlue: #00afff;
$lightBlueHover: #48ccff;
$gray: #94a2b6;
$grayHover: #aebdd1;
$darkGray: #505d6f;
$darkGrayHover: #667487;
$lightGray: #f7f9fe;
$lightGrayHover: #feffff;
$green: #119e00;
$greenHover: #15d100;
$yellow: #ffba00;
$yellowHover: #ffc933;
$purple: #822381;
$purpleHover: #a92da7;

$path: "../../images/icons/";

/* Брейки */
$mobile: 767px;
$tabletPortraitMin: 768px;
$tabletPortraitMax: 1023px;
$tabletLandscapeMin: 1024px;
$tabletLandscapeMax: 1280px;
$desktopWide: 1770px;

@mixin transition {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

@mixin appearance {
  appearance: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
