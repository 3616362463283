.ConfirmationAction {
  position: fixed;
  top: 60px;
  right: 0;
  width: calc(100% - 72px);
  padding: 28px 187px 44px;
  display: flex;
  background-color: #ffffff;
  z-index: 100;
}

@media (min-width: 767px) and (max-width: 1280px) {
  .ConfirmationAction {
    padding: 44px 33px 33px 33px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .ConfirmationAction {
    padding: 44px 33px 104px 33px;
    width: 100%;
    margin-top: -60px;

    &[data-margin="Y"] {
      margin-top: 60px;
    }
  }
}
