.dropdown {
  position: relative;
  margin: 10px;
  width: 200px;

  &.open {
    z-index: 100;
  }

  .dropdown-header {
    position: relative;

    z-index: 0;
    padding: 10px;
    background: #00000000;
    border-radius: 6px;
    border: 1px solid #ccc;
    align-items: center;
    justify-content: space-between;
    display: flex;
    .icon-down {
      margin-left: auto;

      path {
        fill: #e00c3c;
      }
    }
  }

  .dropdown-list {
    position: absolute;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;

    &.open {
      z-index: 10;
    }
    .dropdown-item {
      padding: 10px;
      cursor: pointer;
      position: relative;

      &.selected {
        background-color: #e00c3c;
        //color: #aebdd1;
      }
      &:hover {
        background: rgba(224, 12, 60, 0.08);
        z-index: 30;
        cursor: pointer;
      }
    }
  }
}
