.search-history-input {
  border: none;
  padding: 15px 0;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #2e2382;

  &-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }

  &-icon-search {
    margin: 3px 5px 0 15px;
  }

  &-icon-clear {
    margin: 3px 15px 0 5px;
    cursor: pointer;

    &-wrapper {
      width: 20px;
      height: 20px;
    }
  }
}
