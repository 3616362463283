@import "../../style/constants.scss";
$path: "../../images/icons/";

.popup-notice {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11000;
  transition: all 0.2s ease;
  visibility: hidden;
  opacity: 0;
}
.popup-notice.enter-active {
  visibility: visible;
  opacity: 1;
}
.popup-notice.enter-done {
  visibility: visible;
  opacity: 1;
}
.popup-notice__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.popup-notice__plate {
  width: 635px;
  padding: 118px 82px 109px;
  box-shadow: 5px 8px 10px rgba(46, 35, 130, 0.13);
  background: #fff;
  position: relative;
}
.popup-notice__close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0px;
  background: $gray no-repeat center;
  width: 180px;
  height: 57px;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  @include transition;
  &:hover {
    background: $grayHover;
  }
}
.popup-notice__title {
  font-size: 28px;
  line-height: 48px;
  color: $blue;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.popup-notice__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: $blue;
}
.popup-notice__list {
  margin: 26px 0 0;
}
.popup-notice__list-item {
  list-style-type: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding-left: 17px;
  position: relative;
  color: $blue;
  margin-bottom: 3px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 11px;
    display: block;
    width: 10px;
    height: 2px;
    background: $red;
  }
}
.popup-notice__top-decor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 57px;
  background: $blue;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -15px;
    left: 20px;
    display: block;
    width: 30px;
    height: 30px;
    background: url($path + "notice-icon-white.svg") no-repeat center / contain;
  }
}

@media only screen and (max-width: 767px) {
  .popup-notice {
    overflow: auto;
    padding: 20px 0px;
  }
  .popup-notice__wrap {
    padding: 0 20px;
    height: auto;
    min-height: 100%;
  }
  .popup-notice__plate {
    padding: 91px 37px 99px;
    border-radius: 0;
    width: 100%;
    height: auto;
  }
  .popup-notice__close {
    bottom: 0;
    right: 0px;
    width: 100%;
  }
  .popup-notice__title {
    font-size: 24px;
    line-height: 40px;
  }
  .popup-notice__text {
    line-height: 25px;
  }
  .popup-notice__list {
    margin-top: 25px;
  }
}
