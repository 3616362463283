@import "../../style/constants.scss";

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Обеспечивает, что приложение занимает как минимум высоту окна просмотра
}

.main-wrap {
  @include transition;
}
.main-container {
  position: relative;
  background: #f2f5f9;
  flex: 1;
  @include transition;
}
.main-container__wrap {
  max-width: 1480px;
  padding: 0 20px;
  margin: auto;
}
.main-wrap_open-menu {
  //transform: translateX(204px);
}
.main-container_open-menu {
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  main {
    min-width: 100%;
  }
  .main-container {
    padding-left: 75px;
  }
}

@media only screen and (max-width: 767px) {
  main {
    min-width: 100%;
  }
}
