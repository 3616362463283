.Table {
  margin-top: 16px;
}

.TableHeader {
  display: flex;
  border-bottom: 1px solid #d2dcea;
  padding-bottom: 14px;

  .TableCol {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #94a2b6;
    text-align: left;

    &[data-type="request"] {
      padding-left: 36px;
    }
  }
}

.TableCol {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2e2382;
  overflow: hidden;

  &[data-type="request"] {
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    margin-right: 16px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
    }

    input {
      border: none;
      background-color: transparent;
      padding: 0;

      width: 100%;
      max-width: 44px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #505d6f;
      border-bottom: 1px solid #505d6f;
      padding-bottom: 4px;
      margin-left: 14px;
    }
  }

  &[data-type="system"] {
    min-width: 20px;
    max-width: 20px;
    text-align: center;
    margin-right: 8px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &[data-type="size"] {
    min-width: 28px;
    max-width: 28px;
    text-align: center;
    margin-right: 8px;
  }

  &[data-type="region"] {
    min-width: 56px;
    max-width: 56px;
    margin-right: 8px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[data-type="frequency"] {
    min-width: 44px;
    max-width: 44px;
    text-align: center;
  }
}

.TableList {
  width: calc(100% + 40px);
  border-bottom: 1px solid #d2dcea;
  margin-left: -20px;
  margin-right: -20px;
}

.TableRow {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  padding-right: 20px;

  &:nth-child(even) {
    background-color: #f2f5f9;
  }

  &.Active {
    .TableRowControl {
      transform: translateX(0);
    }

    .TableCol {
      &[data-type="request"] {
        color: #e00c3c;
      }
    }

    .BtnOpenRow {
      padding-left: 6px;
      padding-right: 14px;
    }
  }
}

.TableRowControl {
  position: absolute;
  right: 20px;
  top: 0;
  transform: translateX(115%);
  height: 100%;
  display: flex;
  transition: all 0.5s ease;

  button {
    width: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;

    &[data-type="save"] {
      background-color: #2e2382;

      &:hover {
        background-color: lighten(#2e2382, 5);
      }
    }

    &[data-type="close"] {
      background-color: #94a2b6;

      &:hover {
        background-color: lighten(#94a2b6, 5);
      }
    }

    &[data-type="delete"] {
      background-color: #e00c3c;

      &:hover {
        background-color: lighten(#e00c3c, 5);
      }
    }
  }
}

.BtnOpenRow {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  transition: all 0.5s ease;

  span {
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: grey;

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
}
