.Wrap {
}

.Btn {
  position: relative;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #505d6f;
  border-bottom: 1px solid #d2dcea;
  padding: 0 20px;
  transition: all 0.2s ease;

  &.Active {
    background-color: #e00c3c;
    color: #fff;

    .IconCheckedBig {
      opacity: 1;
    }
  }

  &:hover {
    background-color: #e00c3c;
    color: #fff;
  }
}

.Icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.IconCheckedBig {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  opacity: 0;

  path {
    fill: #fff;
  }
}
