.TextArea {
  width: 100%;
  margin-bottom: 30px;

  .Title {
    font-size: 12px;
    line-height: 14px;
    color: #94a2b6;
    width: 142px;
  }

  .Area {
    margin-top: 8px;
    padding: 15px 10px 0 15px;
    width: 100%;
    height: 173px;
    background: #ffffff;
    border: 2px solid #a295ff;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 10px 10px 10px;
    font-size: 16px;
    line-height: 21px;
    color: #505d6f;
    mix-blend-mode: normal;
    color: #505d6f;

    &[readonly] {
      border: 1px solid #e0dcff;
      color: rgba(80, 93, 111, 0.32);
    }

    &[data-small="S"] {
      height: 100%;
    }

    &[data-color="lightred"] {
      border: 2px solid #ffb4c5;
    }

    &[data-color="lightgreen"] {
      border: 2px solid #b4ffc5;
    }
  }

  textarea {
    resize: none;
  }
}
