.Section {
  background-color: #fff;
  margin-top: 10px;
}

.Header {
  border-bottom: 1px solid #e9ebf1;
  padding: 14px 0 20px 22px;
}

.Colors {
  max-width: 190px;
  display: flex;
  flex-wrap: wrap;

  p {
    position: relative;
    min-width: 78px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #505d6f;
    padding-left: 18px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    &[data-type="pending"] {
      &::before {
        background-color: #ff6400;
      }
    }

    &[data-type="positive"] {
      &::before {
        background-color: #119e00;
      }
    }

    &[data-type="neutral"] {
      &::before {
        background-color: #505d6f;
      }
    }

    &[data-type="negative"] {
      &::before {
        background-color: #e00c3c;
      }
    }

    &:nth-child(even) {
      margin-left: auto;
    }
  }
}

.Charts {
  min-height: 440px;
  padding: 20px 0;
}

.Empty {
  text-align: center;
}
