.Section {
}

.Checkboks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}

.CheckboksField {
  height: 28px;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  padding-right: 20px;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid #becbdc;
  }

  input {
    display: none;
    appearance: none;

    &:checked {
      & + .CheckboksCheck {
        opacity: 1;
      }
    }
  }
}

.CheckboksIcon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.CheckboksCheck {
  position: absolute;
  right: 3px;
  top: 9px;
  opacity: 0;
  transition: all 0.2s ease;
}

.Inner {
  background-color: #fff;
}

.Header {
  border-bottom: 1px solid #e9ebf1;
  padding: 14px 0 20px 22px;
}

.Colors {
  max-width: 190px;
  display: flex;
  flex-wrap: wrap;

  p {
    position: relative;
    min-width: 78px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #505d6f;
    padding-left: 18px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    &[data-type="pending"] {
      &::before {
        background-color: #ff6400;
      }
    }

    &[data-type="positive"] {
      &::before {
        background-color: #119e00;
      }
    }

    &[data-type="neutral"] {
      &::before {
        background-color: #505d6f;
      }
    }

    &[data-type="negative"] {
      &::before {
        background-color: #e00c3c;
      }
    }

    &:nth-child(even) {
      margin-left: auto;
    }
  }
}

.MainBlock {
  position: relative;
  min-height: 150px;
  width: 100%;
  background-color: #fff;
}

.MainBlockText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  font-size: 16px;
  line-height: 19px;
  color: #505d6f;
  font-weight: 500;
}
