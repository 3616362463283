.Wrap {
  position: relative;
  background-color: #fff;
  padding-left: 30px;
  padding-right: 20px;
  padding-bottom: 24px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% + 2px);
    width: 8px;
    background-color: tomato;
  }

  &[data-tonality="positive"] {
    &::before {
      background-color: #119e00;
    }
  }

  &[data-tonality="negative"] {
    &::before {
      background-color: #e00c3c;
    }
  }

  &[data-tonality="neutral"] {
    &::before {
      background-color: #505d6f;
    }
  }

  &[data-tonality="pending"] {
    &::before {
      background-color: #ff6400;
    }
  }
}

.Row {
  border: 1px solid #d0ddef;
  margin-bottom: 16px;
}

.Col {
  min-height: 100px;
  width: 100%;
  padding: 10px 12px;

  &:not(:last-child) {
    border-bottom: 1px solid #d0ddef;
  }
}

.ColTitle {
  max-width: 280px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #94a2b6;
  margin-bottom: 24px;
}

.ColValue {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #505d6f;
  text-align: center;

  svg {
    margin-right: 16px;
  }
}

.Comments {
}

.Comment {
  margin-bottom: 22px;
}

.CommentHeader {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #94a2b6;
  margin-bottom: 4px;

  span {
    margin-right: 16px;
  }
}

.CommentText {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #505d6f;
  background: #fff;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid #d0ddef;
  padding: 12px 20px;
  padding-right: 40px;
}

.CommentDelete {
  border: none;
  background-color: transparent;
  padding: 0;
  outline: 0;
  box-shadow: none;

  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 10px;
}

.TextField {
  position: relative;

  textarea {
    border: none;
    outline: none;
    padding: 0;

    height: 190px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #505d6f;
    background: #f7f9fe;
    box-shadow: inset 0px 2px 8px #e0e5ec;
    resize: none;
    border-radius: 30px;
    // padding-left: 20px;
    // padding-right: 20px;
    padding: 20px;

    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #becbdc;
      text-align: center;
      // padding-top: 16px;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      // top: -20px;
      // font-size: 12px;
      // padding: 0;

      opacity: 0;
      pointer-events: none;
    }
  }

  label {
    position: absolute;
    left: 50%;
    top: 16px;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #becbdc;
    white-space: nowrap;
    transition: all 0.3s ease;
  }
}

.IconComment {
  margin-right: 14px;
}

.BtnSend {
  border: none;
  outline: none;
  padding: 0;
  box-shadow: none;
  background-color: transparent;

  position: absolute;
  right: 14px;
  bottom: 14px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d0ddef;
  border-radius: 50%;
}

.IconSend {
}
