/* ==========================================================================
   Файл для подключения шрифтов
   Генератор шрифтов - https://www.web-font-generator.com
   Генератор шрифтов - http://www.fontsquirrel.com/tools/webfont-generator
   ========================================================================== */

@font-face {
  font-family: "Gilroy";
  src:
    url("../fonts/Gilroy-Light/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gilroy-Light/Gilroy-Light.otf") format("opentype"),
    url("../fonts/Gilroy-Light/Gilroy-Light.woff") format("woff"),
    url("../fonts/Gilroy-Light/Gilroy-Light.ttf") format("truetype"),
    url("../fonts/Gilroy-Light/Gilroy-Light.svg#Gilroy-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src:
    url("../fonts/Gilroy-Regular/Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gilroy-Regular/Gilroy-Regular.otf") format("opentype"),
    url("../fonts/Gilroy-Regular/Gilroy-Regular.woff") format("woff"),
    url("../fonts/Gilroy-Regular/Gilroy-Regular.ttf") format("truetype"),
    url("../fonts/Gilroy-Regular/Gilroy-Regular.svg#Gilroy-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src:
    url("../fonts/Gilroy-Medium/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gilroy-Medium/Gilroy-Medium.otf") format("opentype"),
    url("../fonts/Gilroy-Medium/Gilroy-Medium.woff") format("woff"),
    url("../fonts/Gilroy-Medium/Gilroy-Medium.ttf") format("truetype"),
    url("../fonts/Gilroy-Medium/Gilroy-Medium.svg#Gilroy-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src:
    url("../fonts/Gilroy-Semibold/Gilroy-Semibold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gilroy-Semibold/Gilroy-Semibold.otf") format("opentype"),
    url("../fonts/Gilroy-Semibold/Gilroy-Semibold.woff") format("woff"),
    url("../fonts/Gilroy-Semibold/Gilroy-Semibold.ttf") format("truetype"),
    url("../fonts/Gilroy-Semibold/Gilroy-Semibold.svg#Gilroy-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src:
    url("../fonts/Gilroy-Bold/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Gilroy-Bold/Gilroy-Bold.otf") format("opentype"),
    url("../fonts/Gilroy-Bold/Gilroy-Bold.woff") format("woff"),
    url("../fonts/Gilroy-Bold/Gilroy-Bold.ttf") format("truetype"),
    url("../fonts/Gilroy-Bold/Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
