.Wrap {
}

.Btn {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: #2e2382;
  border-bottom: 1px solid #d2dcea;
  padding: 0 20px;

  p {
    margin: 0;
  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #94a2b6;
  }
}

.IconChevronDown {
  transform: rotate(-90deg);
  margin-left: auto;

  path {
    fill: #e00c3c;
  }
}

.Icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.BtnCancel {
  width: 100%;
  max-width: 320px;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  background-color: #505d6f;
  margin-top: 26px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease;

  &:hover {
    background-color: lighten(#505d6f, 5);
  }
}
