@import "../../style/constants.scss";
$path: "../../images/icons/";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: all 0.2s ease;
  overflow: hidden;
}
.modalWrap {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 10px;
    height: 100%;
  }
}

.modalPlate {
  width: 635px;
  padding: 118px 82px 109px;
  box-shadow: 5px 8px 10px rgba(46, 35, 130, 0.13);
  background: #fff;
  position: relative;

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 40px 20px 20px 20px;
  }
}
.modalClose {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0px;
  background: $gray no-repeat center;
  width: 180px;
  height: 57px;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  @include transition;
  &:hover {
    background: $grayHover;
  }
}
.modalTitle {
  font-size: 28px;
  line-height: 48px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 57px;
  background: $blue;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -15px;
    left: 20px;
    display: block;
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .modal {
    overflow: auto;
    padding: 20px 0px;
  }
  .modal__wrap {
    padding: 0 20px;
    height: auto;
    min-height: 100%;
  }
  .modal__plate {
    padding: 91px 37px 99px;
    border-radius: 0;
    width: 100%;
    height: auto;
  }
  .modal__close {
    bottom: 0;
    right: 0px;
    width: 100%;
  }
  .modal__title {
    font-size: 24px;
    line-height: 40px;
  }
  .modal__text {
    line-height: 25px;
  }
}
