.Field {
  width: 198px;

  &.FullWidth {
    width: 100%;
    margin-bottom: 30px;
  }
}

.FieldTitle {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #94a2b6;
  display: block;
}

.FieldInput {
  width: 100%;
  background: #ffffff;
  border: 1px solid #a295ff;
  box-shadow: inset 0 1px 11px #f2f0ff;
  padding: 20px 15px 20px 21px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #505d6f;
  mix-blend-mode: normal;

  &:hover {
    border: 1.5px solid #6f58ff;
  }

  &BorderAll {
    border-radius: 10px 10px 10px 10px;
  }

  &BorderRight {
    border-radius: 0 10px 10px 0;
  }

  &BorderLeft {
    border-radius: 0 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .Field {
    width: 100%;
    z-index: 2;
  }

  .FieldInput {
    &BorderLeft {
      border-radius: 0 10px 0 0;
    }
  }
}
