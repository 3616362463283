.Wrap {
  background-color: #fff;
  padding: 0 32px;
}

.Title {
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #2e2382;
  margin-bottom: 28px;
  margin-left: 38px;
}

.Header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 54px;
  padding-left: 70px;
  padding-bottom: 4px;
}

.HeaderItem {
  width: 100%;
  max-width: 120px;
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #94a2b6;
  text-align: center;

  &:not(:last-child) {
    margin-right: 80px;
  }

  &:first-child {
    text-align: left;
    min-width: 360px;
    margin-right: 260px;
  }
}

.Items {
}

.AddPlanWrap {
  width: 100%;
  display: flex;
  padding-right: 30px;
}

.AddPlan {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;

  height: 60px;
  min-width: 162px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  background-color: #2e2382;
  transition: all 0.2s ease;
  margin-left: auto;

  &:hover {
    background-color: #3b2da9;
  }
}

.Accordion {
}

.AccordionItem {
  &:not(:last-child) {
    border-bottom: 1px solid #e9eff5;
  }

  &.Open {
    .AccordionDropdown {
      display: block;
    }
  }
}

.AccordionItemHeader {
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #94a2b6;
  cursor: pointer;
}

.AccordionDropdown {
  display: none;
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Wrap {
    overflow: hidden;
  }

  .Inner {
    overflow: auto;
  }

  .HeaderItem {
    min-width: 150px;
    max-width: 150px;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &:first-child {
      min-width: 360px;
      max-width: 360px;
      margin-right: 30px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Wrap {
    overflow: hidden;
  }

  .Inner {
    overflow: auto;
  }

  .Title {
    margin-left: 28px;
  }

  .Header {
    padding-left: 28px;
  }

  .HeaderItem {
    min-width: 150px;
    max-width: 150px;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &:first-child {
      min-width: 360px;
      max-width: 360px;
      margin-right: 30px;
    }
  }

  .AddPlanWrap {
    padding-right: 0;
  }

  .AddPlan {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}
