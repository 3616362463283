.Wrap {
  position: relative;
  box-sizing: border-box;

  input {
    box-sizing: border-box;
    border: none;
    margin: 0;
    padding: 0;

    width: 100px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    color: #505d6f;
    background: transparent;
    text-align: center;
  }

  svg {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .Wrap {
    display: flex;
    align-items: center;

    input {
      width: 100;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #94a2b6;
      text-align: left;
      padding-right: 20px;
      padding-top: 4px;
    }

    svg {
      display: block;

      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
}
