.Analise {
  width: 100%;
  height: 100%;
  padding: 60px 100px 0 102px;
}

.Title {
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: #2e2382;
  margin-bottom: 16px;
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .Analise {
    padding: 20px 14px 20px 88px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Analise {
    padding: 20px 14px 20px 14px;
  }

  .Title {
    font-size: 30px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .Analise {
    padding: 60px 0 0;
    background-color: #fff;
  }

  .Inner {
    background-color: #f2f5f9;
    padding: 0 10px;
    padding-top: 34px;
  }

  .Title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 26px;

    display: none;
  }
}
