.Item {
  &:not(:last-child) {
    margin-bottom: 25px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.Header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.Date {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #e00c3c;
  margin-right: 10px;
}

.Author {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2e2382;
}

.Label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #94a2b6;
  margin-left: 8px;
}

.Status {
  width: auto;
  height: 46px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  background-color: transparent;
  border-radius: 0px 10px 10px 10px;
  padding: 0 14px;
  margin-left: 15px;

  &[data-type="for-posting"] {
    background-color: #119e00;

    .StatusIcon {
      background-image: url("../../../../../../images/icons/for-posting-icon-white.svg");
    }
  }

  &[data-type="refused"] {
    background-color: #fa0c4c;

    .StatusIcon {
      background-image: url("../../../../../../images/icons/refused-icon-white.svg");
    }
  }

  &[data-type="posted"] {
    background-color: #94a2b6;

    .StatusIcon {
      background-image: url("../../../../../../images/icons/refused-icon-white.svg");
    }
  }

  &[data-type="under-moderation"] {
    background-color: #00afff;

    .StatusIcon {
      background-image: url("../../../../../../images/icons/under-moderation-white.svg");
    }
  }

  &[data-type="waiting-agreement"] {
    background-color: #2e2382;

    .StatusIcon {
      background-image: url("../../../../../../images/icons/waiting-agreement-icon-white.svg");
    }
  }
}

.StatusIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-right: 8px;
}

.IconArrowBottom {
  transform: rotate(-90deg);
}

.TypeCreated {
  min-height: 60px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #505d6f;
  background: #f7f9fe;
  border-radius: 0px 10px 10px 10px;
  padding: 0 20px;
}

.TypeStatus {
  min-height: 60px;
  display: flex;
  align-items: center;
  background: #f7f9fe;
  border-radius: 0px 10px 10px 10px;
  padding: 0 20px;
}

.TypeReplica {
  position: relative;
  border: 1px solid #e0dcff;
  box-shadow: inset 0px 1px 11px #f2f0ff;
  border-radius: 0px 10px 10px 10px;
  overflow: hidden;
  padding: 20px 32px 32px 45px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20px;
    background-color: #ffba00;
  }
}

.NewReplica {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #505d6f;
  margin-bottom: 16px;
}

.SubTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2e2382;
  margin-bottom: 10px;
}

.OldReplica {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #505d6f;
  background: #f7f9fe;
  border-radius: 0px 10px 10px 10px;
  padding: 20px;
  margin-bottom: 16px;
}

.BtnCancelReplica {
  border: none;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  padding: 0;

  width: 100%;
  max-width: 460px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  background-color: #e00c3c;
  border-radius: 10px 0px 10px 10px;
  margin-left: auto;
  transition: all 0.2s ease;

  &:hover {
    background-color: #ff4763;
  }
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

@media only screen and (max-width: 767px) {
  .Status {
    font-size: 12px;
    line-height: 12px;
  }

  .StatusIcon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  .BtnCancelReplica {
    height: auto;
    padding: 10px 6px;
  }

  .TypeReplica {
    padding: 10px;
    padding-left: 32px;
  }
}
