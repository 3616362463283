.Wrapper {
  position: fixed;
  z-index: 500;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(46, 35, 130, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
}

.Inner {
  width: 100%;
  max-width: 810px;
  box-shadow: 5px 8px 10px rgba(46, 35, 130, 0.13);
  background-color: #fff;
}

.Header {
  height: 58px;
  display: flex;
  align-items: center;
  background-color: #2e2382;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  padding-left: 20px;

  svg {
    margin-right: 28px;
  }
}

.Content {
  padding: 44px 38px;
  padding-bottom: 0;
}

.Text {
  margin: 0;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2e2382;
  margin-bottom: 22px;

  a {
    color: inherit;
    font-weight: 700;
  }
}

.Input {
  border: none;

  width: 100%;
  min-height: 60px;
  font-weight: 500;
  font-size: 36px;
  line-height: 24px;
  color: #505d6f;
  background: #fff;
  border: 1px solid #e0dcff;
  box-shadow: inset 0px 1px 11px #f2f0ff;
  border-radius: 0px 10px 10px 10px;
  padding-left: 20px;
  margin-bottom: 35px;
}

.Control {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    border: none;
    box-shadow: none;
    background-color: transparent;
    outline: none;
    padding: 0;

    height: 62px;
    width: 100%;
    max-width: 332px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;

    background-color: #119e00;
    color: #fff;
    transition: all 0.2s ease;

    &:hover {
      background-color: lighten($color: #119e00, $amount: 3%);
    }

    &:disabled {
      background-color: #94a2b6;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

@media only screen and (max-width: 767px) {
}
