@import "../../style/constants";

/*===============================================================
  Компонент "Футер"
  ===============================================================*/

.footer {
  background: #f2f5f9;
  padding-top: 63px;
  padding-bottom: 53px;
}
.footer__wrap {
  max-width: 1480px;
  padding: 0 20px 0px 92px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .footer {
    padding: 39px 0 39px;
  }
  .footer__wrap {
    max-width: 100%;
    padding: 0 13px 0 88px;
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    padding: 34px 0 34px;
  }
  .footer__wrap {
    max-width: 100%;
    padding: 0 40px 0 40px;
  }
}
