.TonalBlock {
  // margin-top: 50px;
  width: inherit;
  min-height: 486px;
  background: #fff;

  .ReplicFilter {
    justify-content: flex-end;
    display: flex;
    border-bottom: none !important;
    width: 100%;

    .ReplicUnit {
      width: 60px;

      input {
        font-size: 12px;
      }

      &:after {
        left: -27px !important;
        width: 18px;
        height: 18px;
        background: url("../../../../images/Analise/calendar.svg") no-repeat center center / contain;
      }

      &:before {
        content: "";
        right: 0 !important;
        display: none;
        width: 18px;
        height: 18px;
      }

      &[data-noticon="Y"] {
        width: 103px;

        &:after {
          display: none;
        }
      }

      &[data-mobile="Y"] {
        input {
          &:after {
            content: "__.__.__";
            position: absolute;
            left: 0 !important;
            font-size: 18px;
            background: none;
            display: block;
          }

          &:focus,
          &:active {
            &:after {
              display: none;
            }
          }
        }

        &[data-not-placeholder="true"] {
          input {
            &:after {
              display: none;
            }
          }
        }

        &:before {
          position: absolute;
          right: 0 !important;
          top: calc(50% - 8px);
          display: block;
          background: url("../../../../images/icons/calendar-icon.svg") no-repeat center center /
            contain;
        }
      }
    }
  }

  .TopBlock {
    padding: 15px 45px 27px 26px;
    border-bottom: 1px solid #e9ebf1;
    box-sizing: border-box;

    .RowOne {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Title {
        font-size: 20px;
        line-height: 30px;
        color: #505d6f;
        font-weight: 500;
      }
    }

    .RowTwo {
      margin-top: 16px;
      width: 397px;
      display: flex;
      justify-content: space-between;

      .Infotmation {
        display: flex;
        justify-content: center;
        align-items: center;

        .ColorDot {
          margin: 0 8px 4px 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;

          &[data-color="Orange"] {
            background: #ff6400;
          }

          &[data-color="Black"] {
            background: #505d6f;
          }

          &[data-color="Green"] {
            background: #119e00;
          }

          &[data-color="Red"] {
            background: #e00c3c;
          }
        }

        .Text {
          font-size: 12px;
          line-height: 20px;
          color: #505d6f;
        }
      }
    }
  }

  .MainBlock {
    width: 100%;
    // height: 370px;
    min-height: 486px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .MainBlockText {
      font-size: 16px;
      line-height: 19px;
      color: #505d6f;
      font-weight: 500;
    }
  }
}

// @media (min-width: 767px) and (max-width: 1199px) {
//     .TonalBlock {
//         .ReplicFilter {
//             width: auto;

//             &:after {
//                 content: '';
//                 position: absolute;
//                 left: -25px !important;
//                 top: 14px;
//                 width: 18px;
//                 height: 18px;
//                 background: url('../../../../images/Analise/calendar.svg') no-repeat center center / contain;
//             }

//             &[data-tablet-icon-hide='Y']:after {
//                 display: none;
//             }

//             .ReplicUnit {
//                 width: 80px;
//             }
//         }

//         .TopBlock {
//             padding: 24px 19px 0;

//             .RowOne {
//                 .Title {
//                     font-size: 18px;
//                 }

//                 .ReplicFilter {
//                     width: auto;

//                     &:after {
//                         content: '';
//                         position: absolute;
//                         left: -25px !important;
//                         top: 14px;
//                         width: 18px;
//                         height: 18px;
//                         background: url('../../../../images/Analise/calendar.svg') no-repeat center center / contain;
//                     }

//                     &[data-tablet-icon-hide='Y']:after {
//                         display: none;
//                     }

//                     .ReplicUnit {
//                         width: 80px;
//                     }
//                 }
//             }

//             .RowTwo {
//                 margin-bottom: 23px;
//             }
//         }
//     }
// }

@media only screen and (max-width: 767px) {
  .TonalBlock {
    height: auto;

    .ReplicFilter {
      margin-bottom: 0;
      justify-content: flex-start;
      width: 260px;
      margin-right: 0;
      margin-left: 19px;

      .ReplicUnit {
        width: 100px;

        input {
          width: 100px;
          -webkit-appearance: none;

          &::-webkit-inner-spin-button,
          &::-webkit-calendar-picker-indicator {
            display: none;
          }
        }
      }
    }

    .ReplicFilter {
      margin-left: 27px;
      margin-bottom: 0;
      justify-content: flex-start;

      .ReplicUnit {
        width: 60px;

        input {
          width: 85px;
          font-size: 12px;
          -webkit-appearance: none;

          &::-webkit-inner-spin-button,
          &::-webkit-calendar-picker-indicator {
            display: none;
          }
        }
      }
    }

    .TopBlock {
      padding: 15px 13px 21px;

      .RowOne {
        .Title {
          font-size: 20px;
          line-height: 30px;
        }

        .ReplicFilter {
          width: auto;
          margin: 0;
          margin-left: 30px;

          .ReplicUnit {
            width: 60px;

            &:after {
              left: -20px !important;
            }

            input {
              width: 85px;
              font-size: 12px;
              -webkit-appearance: none;

              &::-webkit-inner-spin-button,
              &::-webkit-calendar-picker-indicator {
                display: none;
              }

              &:after {
                content: "__.__.__";
                position: absolute;
                left: 0 !important;
                font-size: 18px;
                background: none;
                display: block;
              }

              &:focus,
              &:active {
                &:after {
                  display: none;
                }
              }
            }

            &[data-not-placeholder="true"] {
              input {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }

      .RowTwo {
        margin-top: 0;
        width: 209px;
        display: flex;
        flex-wrap: wrap;

        .Infotmation {
          width: 97px;
          justify-content: flex-start;

          .ColorDot {
          }

          .Text {
          }
        }
      }
    }

    .MainBlock {
      height: 355px;

      .MainBlockText {
      }
    }
  }
}
