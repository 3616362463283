.recharts-wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#tonalBlockChartLine .recharts-layer .recharts-cartesian-axis .recharts-yAxis .yAxis {
  margin-right: 10px !important;
  background-color: aqua;
}

.recharts-wrapper .recharts-layer.recharts-pie {
  text-align: center;
  margin: 0 auto;
}

.recharts-pie-labels .recharts-layer path {
  display: none;
}
