.tileName {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2e2382;
  margin-bottom: 24px;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #94a2b6;
  text-decoration: none;
  display: block;
}

.activeInput {
  font-weight: 600;
  font-size: 24px;
  color: #2e2382;
  width: 100%;
}

.editDescription {
  font-weight: 400;
  font-size: 16px;
  color: #94a2b6;
}

.descriptionInput {
  width: 100%;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.27;
}
