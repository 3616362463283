.admin-company__user-item-bar {
  .admin-company__user-item-name {
    padding-left: 0;
  }

  .admin-company__user-item-position {
    min-width: 200px;
    padding-left: 0;
  }

  .admin-company__user-item-email {
    min-width: 210px;
    padding-left: 0;
    text-decoration: none;
  }

  .admin-company__user-item-checkbox {
    min-width: 110px;
    padding-left: 0;

    button {
      border: none;
      box-shadow: none;
      outline: none;
      background-color: transparent;
      padding: 0;

      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .admin-company__user-item-status__mobail {
    display: none;
  }
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .admin-company__user-item-bar {
    height: auto !important;
    min-height: 60px !important;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0 !important;
    flex-direction: column !important;
    align-items: flex-start !important;

    .admin-company__user-item-name {
      max-width: 150px;
      white-space: nowrap;
    }

    .admin-company__user-item-position {
      padding-left: 28px;
    }

    .admin-company__user-item-email {
      min-width: auto;
      font-size: 12px;
      line-height: 18px;
      text-decoration: underline;
      padding-left: 28px;
    }

    .admin-company__user-item-checkbox {
      position: absolute;
      right: 58px;
      top: 50%;
      transform: translateY(-50%);
    }

    .admin-company__user-item-status {
      display: none !important;
    }

    .admin-company__user-item-status__mobail {
      width: 21px;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #94a2b6;
      border-radius: 50%;
      margin-bottom: 4px;
      transition: all 0.2s ease;

      display: none;

      span {
        color: #94a2b6;
      }

      &[data-active="Y"] {
        border: 1px solid #119e00;

        span {
          color: #119e00;
        }
      }
    }

    .admin-company__user-item-link {
      right: 150px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .admin-company__user-item-bar {
    height: auto !important;
    min-height: 60px !important;
    padding-top: 8px;
    padding-bottom: 8px;

    .admin-company__user-item-name {
      max-width: 150px;
    }

    .admin-company__user-item-email {
      min-width: auto;
      font-size: 12px;
      line-height: 18px;
      text-decoration: underline;
    }

    .admin-company__user-item-checkbox {
      position: absolute;
      right: 38px;
      top: 50%;
      transform: translateY(-50%);
    }

    .admin-company__user-item-status {
      display: none !important;
    }

    .admin-company__user-item-status__mobail {
      width: 21px;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #94a2b6;
      border-radius: 50%;
      margin-bottom: 4px;
      transition: all 0.2s ease;

      span {
        color: #94a2b6;
      }

      &[data-active="Y"] {
        border: 1px solid #119e00;

        span {
          color: #119e00;
        }
      }
    }

    .admin-company__user-item-link {
      right: 130px !important;
    }
  }
}
