.QueryForm {
  position: absolute;
  z-index: 150;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 200px);
  height: auto;
  max-height: 618px;
  display: block;
  background: #fff;
  padding-left: 34px;
  padding-bottom: 36px;

  overflow: hidden;
}

.Top {
  width: 100%;
  height: 60px;
  background: #94a2b6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.Title {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  margin-left: 63px;

  &:before {
    content: "";
    width: 31px;
    height: 30px;
    position: absolute;
    left: -45px;
    top: -4px;
    background: url("../../../images/Analise/add-Inquiry-icon.svg") no-repeat center center /
      contain;
  }
}

.ButtonBack {
  width: 60px;
  height: 60px;
  background-color: #e00c3c;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-out;

  &:before {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url("../../../images/Replica/icon-arrow-back-replic.svg") no-repeat center center /
      contain;
  }

  &:hover {
    background-color: #ff4763;
  }
}

.MainSection {
  padding-right: 40px;
  margin-bottom: 40px;
}

.BtnFrequency {
  border: none;
  outline: 0;
  padding: 0;
  background-color: transparent;
  box-shadow: none;

  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #2e2382;

  svg {
    margin-right: 10px;
  }
}

.AddButton {
  position: relative;
  height: 60px;
  min-width: 340px;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  text-align: center;
  background-color: #e00c3c;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: auto;
  transition: all 0.2s ease-out;

  svg {
    margin-right: 14px;
  }

  &:hover {
    background-color: #ff4763;
  }
}

.AddInput {
  border: none;
  outline: none;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;

  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 26px;
  color: #021913;
  border-radius: 8px;
  padding-left: 24px;
  transition: all 0.2s ease;
}

.AddNewInput {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 20px;
}

.AddLabel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.CancelBtn {
  border: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  background-color: #e00c3c;
  color: #fff;
  padding: 20px 24px;
  margin-right: auto;
  transition: all 0.2s ease;

  &:hover {
    background-color: #ff4763;
  }
}

.ButtonBlock {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
}

.TableTitle {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #505d6f;
  margin-bottom: 16px;
}

.TableHeader {
  display: flex;
  border-bottom: 1px solid #d0ddef;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 8px;
}

.TableList {
  max-height: 294px;
  overflow-y: auto;
}

.TableRow {
  min-height: 42px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  &:nth-child(even) {
    background-color: #f2f5f9;
  }

  .TableCol {
    font-size: 16px;
    line-height: 19px;
    color: #2e2382;
  }
}

.TableCol {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #94a2b6;

  input {
    border: none;

    height: 30px;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #505d6f;
    background: #fff;
    border: 1px solid #e0dcff;
    box-shadow: inset 0px 1px 11px #f2f0ff;
    border-radius: 0px 0px 0px 10px;
    padding: 0 10px;
  }

  &[data-type="date"] {
    min-width: 76px;
    margin-right: 56px;
  }

  &[data-type="request"] {
    width: 100%;
    max-width: 422px;
    margin-right: 62px;
  }

  &[data-type="system"] {
    width: 100%;
    max-width: 114px;
    margin-right: 6px;
  }

  &[data-type="size"] {
    width: 100%;
    max-width: 102px;
    margin-right: 18px;
  }

  &[data-type="region"] {
    width: 100%;
    max-width: 200px;
    margin-right: 62px;
  }

  &[data-type="frequency"] {
    min-width: 82px;
    max-width: 82px;
    margin-right: 62px;
  }

  &[data-type="delete"] {
    min-width: 20px;
  }
}

.IconDelete {
  cursor: pointer;
}

.BtnSave,
.BtnCancel {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  box-shadow: none;

  width: 160px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  transition: all 0.2s ease;
}

.BtnSave {
  background: #2e2382;
  margin-right: 16px;

  &:hover {
    background: #3b2da9;
  }
}

.BtnCancel {
  background: #94a2b6;

  &:hover {
    background: #aebdd1;
  }
}

@media only screen and (min-width: 1770px) {
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .QueryForm {
    top: 60px;
    width: 100%;
  }

  .BtnSave {
    margin-right: 0;
    margin-left: 16px;
  }

  .MainSection {
    padding-right: 16px;
  }

  .ButtonBlock {
    padding-right: 16px;
  }

  .TableHeader {
    padding-left: 0;
    padding-right: 0;
  }

  .TableRow {
    padding-left: 0;
    padding-right: 0;
  }

  .TableCol {
    input {
      height: 30px;
      border-radius: 0px 0px 0px 10px;
      padding: 0 4px;
    }

    &[data-type="date"] {
      min-width: 76px;
      margin-right: 10px;
    }

    &[data-type="request"] {
      width: 100%;
      max-width: 422px;
      margin-right: 10px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &[data-type="system"] {
      min-width: 36px;
      max-width: 36px;
      margin-right: 10px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &[data-type="size"] {
      min-width: 30px;
      max-width: 30px;
      margin-right: 10px;
    }

    &[data-type="region"] {
      min-width: 100px;
      max-width: 100px;
      margin-right: 10px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &[data-type="frequency"] {
      min-width: 62px;
      max-width: 62px;
      margin-right: 10px;
    }

    &[data-type="delete"] {
      min-width: 20px;
      margin-left: auto;
    }
  }
}

@media only screen and (max-width: 767px) {
}
