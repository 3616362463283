@import "../../../../style/constants.scss";

.Wrapper {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0px;
  width: calc(100% + 284px);
  background-color: $gray;
  padding: 20px;
  padding-bottom: 42px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;

  &.Open {
    opacity: 1;
    visibility: visible;
  }
}

.Inner {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.SelectWrap {
  width: 100%;
}

.Btn {
  min-width: 150px;
  height: 60px;
  background: transparent;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  background-color: $blue;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  padding: 0 14px;
  transition: all 0.2s ease;

  &:hover {
    background-color: $blueHover;
  }
}

.BtnUpload {
  min-width: 150px;
  height: 60px;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  display: flex;
  background-color: $blue;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  transition: all 0.2s ease;

  &:hover {
    background-color: $blueHover;
  }
}

.BtnIcon {
  margin-right: 9px;
}

.CustomOption {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  cursor: pointer;
  height: 60px;
  color: $darkGray;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  padding: 0 30px 0 30px;
  transition: all 0.2s ease;

  &.Active,
  &.Focus {
    background-color: #e00c3c;
    color: white;

    &::after {
      background-color: #e00c3c;
    }
  }

  &:hover {
    background-color: #e00c3c;
    color: white;

    &::after {
      background-color: #e00c3c;
    }
  }

  &.Disabled {
    background-color: #fff;
    color: $darkGray;

    &::after {
      background-color: #f1f1f1;
    }

    &.Focus {
      background-color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $grayHover;
    }
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 30px;
    right: 30px;
    height: 1px;
    width: auto;
    transition: all 0.2s ease;
    background-color: #f1f1f1;
  }

  &:first-child {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $grayHover;

    pointer-events: none;
  }
}

.Header {
  display: none;
}

.BtnClose {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;

  position: absolute;
  z-index: 20;
  right: -8px;
  bottom: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

@media only screen and (min-width: $desktopWide) {
}

@media only screen and (min-width: $tabletLandscapeMin) and (max-width: $tabletLandscapeMax) {
}

@media only screen and (min-width: $tabletPortraitMin) and (max-width: $tabletPortraitMax) {
  .Wrapper {
    width: 100%;
    background-color: white;
    box-shadow: 0px 10px 24px rgba(139, 134, 174, 0.3);
    padding: 34px 30px;
    padding: 0;
    // padding-bottom: 43px;
  }

  .Inner {
    flex-direction: column;
  }

  .SelectWrap {
    background-color: white;
    padding: 30px;
    padding-bottom: 0;
    margin-bottom: 28px;
  }

  .Btn {
    min-height: 63px;
    width: 100%;
    max-width: 255px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: 30px;
    margin-bottom: 34px;

    svg {
      display: none;
    }
  }

  .Header {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $gray;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 20px;

    svg {
      margin-right: 16px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .Wrapper {
    width: 100%;
    padding: 0;
  }

  .Inner {
    flex-direction: column;
  }

  .SelectWrap {
    padding: 18px;
    background-color: #fff;
  }

  .Btn {
    min-height: 63px;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;

    svg {
      display: none;
    }
  }

  .Header {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $gray;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    padding: 15px 20px;

    svg {
      margin-right: 16px;
    }
  }
}
