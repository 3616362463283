.search-history-inputs {
  display: flex;
  gap: 10px;
}

@media (max-width: 767px) {
  .search-history-inputs {
    display: flex;
    flex-direction: column;
  }
}
