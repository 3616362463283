@import "./src/style/constants.scss";

$path: "../../../../images/";

.ReplicaTabs {
  width: 100%;
  background: #ffffff;
  display: flex;

  .Tab {
    background: none;
    color: #2e2382;
    transition: all 0.2s ease;
    border: 1px solid #f2f5f9;
    border-left: none;
    height: 60px;
    width: 247px;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #e00c3c;
    }

    &[data-active="Y"] {
      background: #f2f5f9;
      color: #e00c3c;

      &:hover {
        cursor: default;
      }
    }
  }

  .CenterTab {
    border: 1px solid #f2f5f9;
    border-left: none;
  }
}

.SortBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.SortBtnUp,
.SortBtnDown {
  width: 32px;
  height: 32px;
  border: 1px solid $gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 12px;
    height: 18px;
  }

  &[data-active="Y"] {
    border-color: #e00c3c;

    path {
      fill: #e00c3c;
    }
  }
}

.SortBtnUp {
  margin-right: 12px;

  svg {
    transform: rotate(-180deg);
  }
}

@media (min-width: 767px) and (max-width: 1280px) {
  .ReplicaTabs {
    .Tab {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .ReplicaTabs {
    margin-top: 25px;
    justify-content: space-between;
    align-items: center;
    background-color: unset;

    .Tab {
      width: 60px;
      height: 60px;
      border-radius: 50%;

      &[data-active="Y"] {
        background-color: #ffffff;
      }
    }

    .AddButton {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e00c3c;

      svg {
        opacity: 0;
        transition: all 0.2s ease;
      }

      &[data-active="Y"] {
        svg {
          opacity: 1;
        }
      }
    }

    .All {
      .Icon {
        width: 23px;
        height: 21px;
        background: url($path + "/Replica/icon-mobile-tabs-main-blue.svg") no-repeat center center /
          contain;
      }

      &[data-active="Y"] {
        .Icon {
          background: url($path + "/Replica/icon-mobile-tabs-main-red.svg") no-repeat center
            center / contain;
        }
      }
    }

    .Inition {
      .Icon {
        width: 27px;
        height: 21px;
        background: url($path + "/Replica/icon-reagir-blue.svg") no-repeat center center / contain;
      }

      &[data-active="Y"] {
        .Icon {
          background: url($path + "/Replica/icon-reagir-red.svg") no-repeat center center / contain;
        }
      }
    }

    .Reaction {
      .Icon {
        width: 34px;
        height: 29px;
        background: url($path + "/Replica/icon-reaction-blue.svg") no-repeat center center / contain;
      }

      &[data-active="Y"] {
        .Icon {
          background: url($path + "/Replica/icon-reaction-red.svg") no-repeat center center /
            contain;
        }
      }
    }
  }
}
