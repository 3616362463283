@import "../../../../style/constants.scss";

$path: "../../../../images/";

.FilterByDate {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
  width: auto;
  min-width: 300px;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #94a2b6;

  &.tablet {
    position: relative;
    bottom: auto;
    margin-top: 3px;
  }

  &[data-disabled="Y"] {
    pointer-events: none;
    opacity: 0.3;
  }

  .Icon {
    width: 23px;
    height: 18px;
    background: no-repeat center;
    display: none;

    &[data-icon="1"] {
      display: block;
      width: 28px;
      height: 24px;

      &[data-status="refused"] {
        background-image: url($path + "Replica/icon-reagir-red.svg");
      }

      &[data-status="created"] {
        background-image: url($path + "Replica/icon-reagir-orange.svg");
      }

      &[data-status="waiting-agreement"] {
        background-image: url($path + "Replica/icon-reagir-blue.svg");
      }

      &[data-status="for-posting"] {
        background-image: url($path + "Replica/icon-reagir-green.svg");
      }

      &[data-status="under-moderation"] {
        background-image: url($path + "Replica/icon-reagir-lightblue.svg");
      }

      &[data-status="posted"] {
        background-image: url($path + "Replica/icon-reagir-gray.svg");
      }
    }

    &[data-icon="2"] {
      display: block;
      width: 29px;
      height: 24px;

      &[data-status="refused"] {
        background-image: url($path + "Replica/icon-reaction-red.svg");
      }

      &[data-status="created"] {
        background-image: url($path + "Replica/icon-reaction-orange.svg");
      }

      &[data-status="waiting-agreement"] {
        background-image: url($path + "Replica/icon-reaction-blue.svg");
      }

      &[data-status="for-posting"] {
        background-image: url($path + "Replica/icon-reaction-green.svg");
      }

      &[data-status="under-moderation"] {
        background-image: url($path + "Replica/icon-reaction-lightblue.svg");
      }

      &[data-status="posted"] {
        background-image: url($path + "Replica/icon-reaction-gray.svg");
      }
    }

    &[data-icon="3"] {
      display: block;
      width: 29px;
      height: 24px;
      background-image: url($path + "Replica/icon-reaction-reagir.svg");
    }
  }

  label {
    width: 19px;
    height: 19px;
    border: 1px solid $gray;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 10px;

    &:before {
      content: "";
      opacity: 0;
      position: absolute;
      width: 17px;
      height: 17px;
      background: url($path + "icons/tick.svg") no-repeat center;
      @include transition;
    }
  }

  input {
    display: none;

    &:checked + label:before {
      opacity: 1;
    }
  }
}

.replic__filter-unit {
  &.date {
    position: relative;
  }
}

.filter-slider {
  width: 100%;
  position: relative;
  padding-right: 193px;

  .arrows {
    position: absolute;
    width: 193px;
    display: flex;
    height: 60px;
    right: -13px;
    top: -12px;
    background: #f2f5f9;
    z-index: 3;

    .swiper-button-next {
      position: relative;
      width: 60px;
      height: 60px;
      top: 0;
      left: 0;
      right: 0;
      margin-top: 0;
      background: url(../../../../images/icons/arrow-slider-filter-right.svg) #d0ddef no-repeat
        center;

      &:after {
        display: none;
      }
    }

    .swiper-button-prev {
      position: relative;
      width: 60px;
      height: 60px;
      top: 0;
      left: 0;
      right: 0;
      margin-top: 0;
      background: url(../../../../images/icons/arrow-slider-filter-left.svg) #becbdc no-repeat
        center;

      &:after {
        display: none;
      }
    }
  }

  .swiper-container {
    overflow: visible;
    z-index: 2;
  }
}

@media only screen and (max-width: $mobile) {
  .filter-slider {
    .arrows {
      width: fit-content;
      margin-right: 13px;
    }
  }
}
