@import "../../../style/constants";

.CustomTiles {
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 15px));
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

@media only screen and (min-width: $tabletPortraitMin) and (max-width: $tabletLandscapeMax) {
  .CustomTiles {
    grid-template-columns: repeat(2, calc(50% - 10px));
    gap: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .CustomTiles {
    grid-template-columns: 100%;
    gap: 20px;
  }
}
