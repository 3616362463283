@import "./src/style/constants.scss";

$path: "../../../../../images/";

.ProjectSettingsFieldNote {
  display: flex;
  margin-bottom: 29px;
  position: relative;

  .FieldCheckbox {
    padding-left: 37px;
    margin-right: 69px;
    padding-top: 20px;

    label {
      width: 19px;
      height: 19px;
      border: 1px solid $gray;
      display: inline-block;
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        opacity: 0;
        position: absolute;
        width: 17px;
        height: 17px;
        background: url($path + "icons/tick.svg") no-repeat center;
        @include transition;
      }
    }

    input {
      display: none;

      &:checked + label:before {
        opacity: 1;
      }
    }
  }

  .FieldType {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $blue;
    width: 212px;
    flex-shrink: 0;
    padding: 23px 0 0 3px;
    position: relative;

    &:before {
      width: 22px;
      height: 22px;
      background: url($path + "Replica/icon-node-client.svg") no-repeat center;
      position: absolute;
      right: 82px;
      top: 20px;
      content: "";
    }

    &[data-agent="agency"] {
      &:before {
        background: url($path + "Replica/icon-node-agent.svg") no-repeat center;
      }
    }
  }

  .FieldValue {
    padding-right: 95px;
    width: 100%;
    position: relative;

    .FieldMobile {
      display: none;
      position: absolute;
      left: 0;
      top: -21px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $blue;
    }

    .FieldText {
      position: relative;
      width: fit-content;

      //input {
      //  background: #FFFFFF;
      //  border: 1px solid #E0DCFF;
      //  width: 100%;
      //  font-size: 16px;
      //  font-weight: 500;
      //  height: 60px;
      //  padding-left: 20px;
      //  box-shadow: inset 0px 1px 11px #F2F0FF;
      //  border-radius: 0px 10px 10px 10px;
      //
      //  &::-webkit-input-placeholder {
      //    color: $gray;
      //  }
      //
      //  &:-ms-input-placeholder {
      //    color: $gray;
      //  }
      //
      //  &::-moz-placeholder {
      //    color: $gray;
      //    opacity: 1;
      //  }
      //
      //  &:-moz-placeholder {
      //    color: $gray;
      //    opacity: 1;
      //  }
      //}
      input {
        display: none;
      }

      label {
        margin-right: 62px;
        padding: 20px;
        width: 344px;
        height: 60px;
        background: #ffffff;
        border-radius: 0px 10px 10px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .Client {
          font-size: 16px;
          line-height: 24px;
          color: #119e00;
        }

        .Icon {
          width: 48px;
          height: 23px;
          background: url($path + "Replica/icon-selector-green.svg") no-repeat center;
          margin: 0px 23px;
        }

        .Agent {
          font-size: 16px;
          line-height: 24px;
          color: #505d6f;
        }

        &[data-agent="agency"] {
          .Client {
            color: #505d6f;
          }

          .Icon {
            background: url($path + "Replica/icon-selector-red.svg") no-repeat center;
          }

          .Agent {
            color: #e00c3c;
          }
        }
      }

      .FieldButtonRemove {
        position: absolute;
        top: 50%;
        margin-top: -10.5px;
        right: 14px;
        width: 21px;
        height: 21px;
        background: url($path + "icons/minus-icon.svg") no-repeat center;
        cursor: pointer;

        &:after {
          content: "";
          opacity: 0;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url($path + "icons/minus-icon-red.svg") no-repeat center;
          @include transition;
        }

        &:hover:after {
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .ProjectSettingsFieldNote {
    display: flex;
    .FieldCheckbox {
      padding-top: 21px;
      margin-right: 27px;
    }

    .FieldType {
      margin-right: 48px;
      display: block;
      width: fit-content;
      font-size: 14px;
      line-height: 17px;

      &:before {
        right: -31px;
      }
    }

    .FieldValue {
      padding-right: 56px;
      display: flex;

      .FieldMobile {
        display: none !important;
        margin-right: 16px;
        display: block;
        position: relative;
        top: 20px;
      }

      .FieldText {
        .FieldButtonRemove {
          right: 22px;
        }

        label {
          width: 335px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ProjectSettingsFieldNote {
    margin-bottom: 25px;

    .FieldCheckbox {
      margin-right: 0px;
      padding-left: 18px;
      padding-top: 0px;
      position: relative;
      left: 0px;
      top: 0px;

      label {
        width: 60px;
        height: 60px;
        background: url($path + "icons/tick-white.svg") #d0ddef no-repeat center;
        border: none;

        &:before {
          width: 60px;
          height: 60px;
          background: url($path + "icons/tick-white.svg") $green no-repeat center;
        }
      }
    }

    .FieldType {
      display: none;
    }

    .FieldValue {
      padding-right: 0px;

      .FieldMobile {
        display: block;
        left: 6px;
      }

      .FieldText {
        input {
          border-radius: 0px 10px 10px 0px;
        }

        label {
          width: 93%;
          padding: 10px;
          justify-content: start;

          .Client,
          .Agent {
            font-size: 10px;
          }

          .Icon {
            margin: 0 5px;
          }
        }

        .FieldButtonRemove {
          right: 30px;
        }
      }
    }
  }
}
