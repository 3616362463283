@import "../../style/constants";

$pathMain: "../../images/";

.left-side-bar {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 72px;
  padding-top: 60px;
  height: 100%;
  background: #fbfbfc;
  z-index: 10;
  @include transition;
}
.left-side-bar__logo {
  width: 100%;
  margin-top: 29px;
  a {
    display: block;
    text-decoration: none;
    height: 200px;
    background: url($path + "logo-amdg-vertical.svg") no-repeat center / contain;
    font-size: 0;
    line-height: 0;
  }
}

.left-side-bar__menu {
  margin-top: 56px;
  li {
    position: relative;
    a {
      display: block;
      width: 100%;
      height: 51px;
      position: relative;
      font-size: 0;
      line-height: 0;
      span {
        @include transition;
      }
      &:after {
        content: "";
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:before {
        content: "";
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover:after {
        opacity: 0;
      }
      &:hover:before {
        opacity: 1;
      }
    }

    a.active:after {
      opacity: 0;
    }
    a.active:before {
      opacity: 1;
    }
  }
}

.left-side-bar__burger {
  width: 73px;
  height: 60px;
  background: $red;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  z-index: 5000;
  @include transition;
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 30px;
    height: 3px;
    background: #fff;
    top: 23px;
    left: 50%;
    margin-left: -15px;
    @include transition;
  }
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 15px;
    height: 3px;
    background: #fff;
    top: 34px;
    right: 21px;
    @include transition;
  }
  &:hover {
    background: $blue;
  }
}

.left-side-bar__desktop {
  &:after {
    background-image: url($pathMain + "/Menu/icon-desktop.svg");
  }
  &:before {
    background-image: url($pathMain + "/Menu/icon-desktop-red.svg");
  }
}
.left-side-bar__desktop span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.left-side-bar__desktop span:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: block;
  background: $red;
  width: 4px;
  @include transition;
}
.left-side-bar__desktop:hover span {
  color: red;
}
.left-side-bar__desktop:hover span:before {
  opacity: 1;
}
.left-side-bar__desktop.active span:before {
  opacity: 1;
}

.left-side-bar__replics {
  &:after {
    background-image: url($path + "menu-icon-replic.svg");
  }
  &:before {
    background-image: url($path + "menu-icon-replic-blue.svg");
  }
}

.left-side-bar__replics span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.left-side-bar__replics span:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: block;
  background: $blue;
  width: 4px;
  @include transition;
}
.left-side-bar__replics:hover span {
  color: $blue;
}
.left-side-bar__replics:hover span:before {
  opacity: 1;
}
.left-side-bar__replics.active span:before {
  opacity: 1;
}

.left-side-bar__analise {
  &:after {
    background-image: url($path + "menu-icon-analise.svg");
  }
  &:before {
    background-image: url($path + "menu-icon-analise-red.svg");
  }
}

.left-side-bar__analise span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.left-side-bar__analise span:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: block;
  background: $blue;
  width: 4px;
  @include transition;
}
.left-side-bar__analise:hover span {
  color: $blue;
}
.left-side-bar__analise:hover span:before {
  opacity: 1;
}
.left-side-bar__analise.active span:before {
  opacity: 1;
}

.left-side-bar__search {
  &:after {
    background-image: url($path + "menu-icon-analises.svg");
  }
  &:before {
    background-image: url($path + "menu-icon-analises-red.svg");
  }
}

.left-side-bar__search span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.left-side-bar__search span:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: block;
  background: $blue;
  width: 4px;
  @include transition;
}
.left-side-bar__search:hover span {
  color: $blue;
}
.left-side-bar__search:hover span:before {
  opacity: 1;
}
.left-side-bar__search.active span:before {
  opacity: 1;
}

.left-side-bar__info {
  &:after {
    background-image: url($path + "menu-icon-chart.svg");
  }
  &:before {
    background-image: url($path + "menu-icon-chart-blue.svg");
  }
}
.left-side-bar__info span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.left-side-bar__info span:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: block;
  background: $blue;
  width: 4px;
  @include transition;
}
.left-side-bar__info:hover span {
  color: $blue;
}
.left-side-bar__info:hover span:before {
  opacity: 1;
}
.left-side-bar__info.active span:before {
  opacity: 1;
}

.left-side-bar__settings {
  &:after {
    background-image: url($path + "menu-replic-tune.svg");
  }
  &:before {
    background-image: url($path + "menu-replic-tune-red.svg");
  }
}
.left-side-bar__settings span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}
.left-side-bar__settings span:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: block;
  background: $red;
  width: 4px;
  @include transition;
}
.left-side-bar__settings:hover span {
  color: red;
}
.left-side-bar__settings:hover span:before {
  opacity: 1;
}
.left-side-bar__settings.active span:before {
  opacity: 1;
}

.left-side-bar__settings span:before {
  background: $red;
}

.left-side-bar__menu-text {
  @include transition;
  display: none;
}

@media only screen and (max-width: 767px) {
  .left-side-bar {
    left: -100%;
    width: 100%;
  }
  .left-side-bar__burger {
    right: -60px;
    width: 60px;
    &:after {
      right: 15px;
    }
  }

  .left-side-bar__menu li {
    &:before {
      width: 18px;
    }
  }

  .left-side-bar {
    .left-side-bar__logo {
      a {
        background-image: url($path + "logo-amdg-horizontal.png");
        width: 200px;
        height: 24px;
      }
    }
  }
}

.left-side-bar__active {
  width: 276px;
  .left-side-bar__logo {
    padding-left: 49px;
    a {
      background-image: url($path + "logo-amdg-horizontal.png");
      width: 200px;
      height: 24px;
      font-size: 0;
      line-height: 0;
    }
  }

  .left-side-bar__menu {
    margin-top: 131px;
    li {
      padding-left: 49px;
      padding-right: 50px;
      a {
        display: flex;
        align-items: center;
        padding-left: 47px;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $gray;
        border-bottom: 1px solid #dbe3ed;
        &:before {
          width: 31px;
          height: 31px;
          left: 0;
          background-position: left center;
          top: 50%;
          transform: translateY(-50%);
        }
        &:after {
          width: 31px;
          height: 31px;
          left: 0;
          background-position: left center;
          top: 50%;
          transform: translateY(-50%);
        }
        span {
          &:before {
            left: -96px;
          }
        }
      }
      &:last-child {
        a {
          border-bottom: none;
        }
      }
    }
  }

  .left-side-bar__burger {
    background: $blue;
    &:before {
      background: $red;
    }
    &:after {
      background: $red;
      transform: rotate(30deg);
      right: 36px;
      top: 27px;
    }
    &:hover {
      background: $red;
      &:before {
        background: #fff;
      }
      &:after {
        background: #fff;
      }
    }
  }

  .left-side-bar__menu-text {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .left-side-bar__active {
    width: 100%;
    left: 0;
    .left-side-bar__burger {
      right: 0;
      width: 72px;
    }
  }

  .left-side-bar {
    .left-side-bar__logo {
      padding-left: 49px;
    }

    .left-side-bar__menu {
      margin-top: 131px;

      li {
        padding-left: 49px;
        padding-right: 50px;

        a {
          display: flex;
          align-items: center;
          padding-left: 47px;
          text-decoration: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #94a2b6;
          border-bottom: 1px solid #dbe3ed;

          &:before {
            width: 31px;
            height: 31px;
            left: 0;
            background-position: left center;
            top: 50%;
            transform: translateY(-50%);
          }

          &:after {
            width: 31px;
            height: 31px;
            left: 0;
            background-position: left center;
            top: 50%;
            transform: translateY(-50%);
          }

          span {
            &:before {
              left: -96px;
            }
          }
        }
      }
    }
  }
}
