@import "../../../../style/constants.scss";

$path: "../../../../images/icons/";

.search-and-settings__upload-file-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -4px;
}

.search-and-settings__upload-file-blocks {
  max-width: 100%;
}

.search-and-settings__upload-file-select {
}

.search-and-settings__upload-file-select-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: $blue;
  padding-left: 57px;
  position: relative;
  height: 41px;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  &:before {
    content: "";
    width: 41px;
    height: 41px;
    position: absolute;
    left: 0px;
    top: 0px;
    background: url($path + "plus-icon-red.svg") no-repeat center / contain;
  }
}

.search-and-settings__upload-file-select-input {
  display: none;
}

.search-and-settings__upload-file-loading-progress {
}

.search-and-settings__upload-file-buttons-test {
  display: -webkit-inline-box;
}

.search-and-settings__upload-file-loading-process-icon {
}

.search-and-settings__upload-file-loading-process-bar {
}

.search-and-settings__upload-file-loading-complete {
  display: none;
  align-items: flex-end;
  position: relative;
  padding-left: 37px;
  position: relative;
}

.search-and-settings__upload-file-loading-complete-icon {
  width: 50px;
  flex-shrink: 0;
  height: 58px;
  margin-right: 26px;
  background: url($path + "file-type-icon-xls.svg") no-repeat center / contain;
  position: relative;
}

.search-and-settings__upload-file-loading-complete-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: $blue;
  margin-bottom: 12px;
}

.search-and-settings__upload-file-loading-complete-delete {
  position: absolute;
  right: -39px;
  top: -3px;
  width: 26px;
  height: 26px;
  background: url($path + "plus-icon-red.svg") no-repeat center / contain;
  transform: rotate(45deg);
  cursor: pointer;
}

.search-and-settings__upload-file-button-upload {
  display: none;
  width: 219px;
  height: 63px;
  text-align: center;
  background: $blue;
  border: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  @include transition;

  &:hover {
    background: $red;
  }
}

.search-and-settings__upload-file-button-cancel {
  width: 219px;
  height: 63px;
  text-align: center;
  background: $gray;
  border: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  @include transition;
  margin-left: 18px;

  &:hover {
    background: $blue;
  }
}

.search-and-settings__upload-file-buttons {
  display: flex;
}

.search-and-settings__upload-file-container_selected {
  .search-and-settings__upload-file-button-upload {
    display: block;
  }

  .search-and-settings__upload-file-select {
    display: none;
  }

  .search-and-settings__upload-file-loading-complete {
    display: flex;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .search-and-settings__upload-file-container {
    flex-wrap: wrap;
    margin-top: -2px;
  }
  .search-and-settings__upload-file-buttons {
    width: 100%;
    justify-content: flex-end;
    margin-top: 13px;
  }
  .search-and-settings__upload-file-button-cancel {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (min-device-height: 1024px) {
  .search-and-settings__upload-file-container {
    flex-wrap: wrap;
    margin-top: -2px;
  }
  .search-and-settings__upload-file-buttons {
    width: 100%;
    justify-content: flex-end;
    margin-top: 13px;
  }
  .search-and-settings__upload-file-button-cancel {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .search-and-settings__upload-file-select {
    padding-left: 22px;
  }
  .search-and-settings__upload-file-container {
    flex-wrap: wrap;
    margin-top: -4px;
  }
  .search-and-settings__upload-file-buttons {
    width: 100%;
    justify-content: flex-end;
    margin: 28px -10px 0;
    flex: 1 1 auto;
  }
  .search-and-settings__upload-file-button-cancel {
    margin-left: 0px;
  }
  .search-and-settings__upload-file-button-cancel {
    width: 100%;
  }
  .search-and-settings__upload-file-button-upload {
    width: 100%;
  }
  .search-and-settings__upload-file-loading-complete {
    padding-left: 16px;
  }
  .search-and-settings__upload-file-blocks {
    padding: 5px 41px 0 0;
    overflow: hidden;
  }
  .search-and-settings__upload-file-loading-complete-text {
    text-align: right;
  }
}
