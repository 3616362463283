.EditButton {
  position: absolute;
  top: 0;
  right: 72px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 0 36px 0 18px;
  display: flex;
  align-items: center;
  background-color: #94a2b6;
  border: none;
  outline: none;
  height: 60px;
  transition: all 0.2s ease;

  &:hover {
    background-color: #e00c3c;
  }
}

.EditIcon {
  width: 23px;
  height: 23px;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .EditButton {
    width: calc(100% - 60px - 72px);
    padding-left: 25px;
    padding-right: 0;
  }
}
