.admin-company__user-manipulation_editing {
  padding-left: 0 !important;
  background-color: #fff;

  .admin-company__user-manipulation-selects-container {
    padding-left: 0;
  }

  .admin-company__user-manipulation__checkbox {
    margin-top: 0;
    margin-bottom: 30px;
    padding-left: 28px;
  }

  .admin-company__user-manipulation-content {
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .admin-company__user-manipulation_editing {
    position: relative;

    .admin-company__user-manipulation-selects-container {
      padding-left: 18px;
    }

    .admin-company__user-manipulation-buttons {
      padding: 0 18px;
      margin-top: 80px !important;
    }

    .admin-company__user-manipulation-top-side {
      order: 0;
    }

    .admin-company__user-manipulation__checkbox {
      position: absolute;
      left: 0;
      bottom: 80px;
    }

    .admin-company__user-manipulation-content {
      flex-direction: column;
    }
  }
}
